'use strict';
import angular from 'angular';
const _ = require('lodash');
import uiRouter from 'angular-ui-router';

import routes from './kevaCandidate.routes';
/**
  * @class angular_module.kevaCandidateComponent
  */
export class kevaCandidateComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$uibModal = $uibModal;
          this.pageSize = 50;
          this.showAll = false;
          this.scores = [];
          this.candidates = [];
          this.hideToggleButtonOption = false;
          this.deletecandidate = Modal.confirm.delete(candidate => {
               $http.delete(`/keva_api/candidate/${candidate._id}`).then((response) => {
                    _.remove(this.candidates , {_id:candidate._id})
                    this.getcandidates(1);
               })
          });
     }
     respondWithResult(res, statusCode) {
          statusCode = statusCode || 200;
          return function (entity) {
               if (entity) {
                    return res.status(statusCode).json(entity);
               }
               return null;
          };
     }

     /**
	* @function getcandidates
     * @memberOf angular_module.kevaCandidateComponent
     * @param {Number}itemCount - candidate count
     * @description this function calls keva candidates api to get keva candidates.
	*/
     getcandidates(itemCount) {
          this.$http.get('/keva_api/candidate/' + itemCount)
               .then(response => {
                    this.refresh = false;
                    this.candidates = _.concat(this.candidates, response.data);
                    if (response.data.length >= 50) {
                         this.hideToggleButtonOption = true;
                    } else {
                         this.hideToggleButtonOption = false;
                    }
               });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.kevaCandidateComponent
     * @fires getcandidates
     * @description this function intiates the kevaCandidate component.
	*/
     $onInit() {
          this.getcandidates(50);
     }

     /**
	* @function showToggle
     * @memberOf angular_module.kevaCandidateComponent
     * @fires getcandidates
     * @description this function is used to showToggle.
	*/
     showToggle() {
          this.refresh = true;
          this.pageSize += 50;
          this.getcandidates(this.pageSize);
     }
}

export default angular.module('leafApp.kevaCandidate', [uiRouter])
     .config(routes)
     .component('kctab', {
          template: require('./kevaCandidate.html').default,
          controller: kevaCandidateComponent,
          controllerAs: 'kevaCandidateCtrl'
     })
     .name;