'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './psycho.routes';
 /**
  * @class angular_module.PsychoComponent
  */
export class PsychoComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteAttr = Modal.confirm.delete(attr => {
      $http.delete(`/api/psychos/${attr._id}`).then(() => this.$onInit());
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.PsychoComponent
  * @description this function intiates the Psycho component
	*/
  $onInit() {
    this.$http.get('/api/psychos')
      .then(response => {
        this.attrs = response.data;
      });
  }

  /**
	* @function saveAttr
  * @memberOf angular_module.PsychoComponent
  * @param {Object} attr - attribute data
  * @description this function will save attribute
	*/
  saveAttr(attr) {
    return this.$http.post('/api/psychos', attr).then(() => this.$onInit());
  }

  /**
	* @function updateAttr
  * @memberOf angular_module.PsychoComponent
  * @param {Object} attr - attribute data
  * @description this function will update attribute
	*/
  updateAttr(attr) {
    return this.$http.put(`/api/psychos/${attr._id}`, attr);
  }

  /**
	* @function openModal
  * @memberOf angular_module.PsychoComponent
  * @param {Object} attr - attribute data
  * @description this function will open modal for adding or editing attribute.
	*/
  openModal(attr) {
    var psychoCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_attr.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.attrs = angular.copy(psychoCtrl.attrs);
        this.attr = attr;

        this.commitAttr = function(l) {
          
          var promise;
          if (l._id) {
            promise = psychoCtrl.updateAttr(l);
          } else {
            promise = psychoCtrl.saveAttr(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addAttr
  * @memberOf angular_module.PsychoComponent
  * @fires openModal
  * @description this function will open modal for add attribute
	*/
  addAttr() {
    this.openModal({});
  }

  /**
	* @function editAttr
  * @memberOf angular_module.PsychoComponent
  * @fires openModal
  * @param {Object} attr - attribute data
  * @description this function will open modal for edit attribute
	*/
  editAttr(attr) {
    this.openModal(attr);
  }
}

export default angular.module('leafApp.psycho', [uiRouter])
  .config(routes)
  .component('psycho', {
    template: require('./psycho.html').default,
    controller: PsychoComponent,
    controllerAs: 'psychoCtrl'
  })
  .name;
