'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('tvs_motors_private_benchmark', {
      url: '/tvs_motors_private_benchmark',
      template: '<tvsmotorsprivatebenchmarktab></tvsmotorsprivatebenchmarktab>',
      authenticate: 'admin'
    });
}
