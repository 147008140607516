'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './mparam.routes';
 /**
  * @class angular_module.MparamComponent
  */
export class MparamComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteMparam = Modal.confirm.delete(mparam => {
      $http.delete(`/api/mparams/${mparam._id}`).then(() => this.getMparams());
    });
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function getMparams
  * @memberOf angular_module.MparamComponent
  * @description this function calls get mparams api to get mparams data.
	*/
  getMparams() {
    this.$http.get('/api/mparams')
      .then(response => {
        this.mparams = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.MparamComponent
  * @fires getMparams
  * @description this function intiates the Mparams component
	*/
  $onInit() {
    this.$http.get('/api/psychos')
      .then(response => {
        this.attrs = this.arrayToMap(response.data);
      });
    this.getMparams();
  }

  /**
	* @function saveMparam
  * @memberOf angular_module.MparamComponent
  * @param {Object} mparam - mparam data
  * @description this function calls add mparams api to add mparam.
	*/
  saveMparam(mparam) {
   if(mparam.interview_questions==undefined){
      mparam.interview_questions='';
    }
    return this.$http.post('/api/mparams', mparam).then(() => this.getMparams());
  }

  /**
	* @function updateMparam
  * @memberOf angular_module.MparamComponent
  * @param {Object} mparam - mparam data
  * @description this function calls update mparams api to update mparam.
	*/
  updateMparam(mparam) {
    return this.$http.put(`/api/mparams/${mparam._id}`, mparam);
  }

  /**
	* @function openModal
  * @memberOf angular_module.MparamComponent
  * @param {Object} mparam - mparam data
  * @description this function will open modal for adding or editing mparam.
	*/
  openModal(mparam) {
    var mparamCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_mparam.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.mparam = mparam;

        if (!mparam._id) {
          this.mparam.priorities = mparamCtrl.mapToArray(mparamCtrl.attrs);
        } else {
          let keys = Object.keys(mparamCtrl.attrs);
          this.mparam.priorities.forEach(priority => {
            let index = keys.indexOf(priority.name);
            if (index > -1) {
              keys.splice(index, 1);
            }
          });
          keys.forEach(k => {
            this.mparam.priorities.push(mparamCtrl.attrs[k]);
          });
        }

        this.commitMparam = function(l) {
          var promise;
          if (l._id) {
            promise = mparamCtrl.updateMparam(l);
          } else {
            promise = mparamCtrl.saveMparam(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addMparam
  * @memberOf angular_module.MparamComponent
  * @fires openModal
  * @description this function will open modal for adding mparam
	*/
  addMparam() {
    this.openModal({});
  }

  /**
	* @function editMparam
  * @memberOf angular_module.MparamComponent
  * @fires openModal
  * @param {Object} mparam - mparam data
  * @description this function will open modal for adding mparam
	*/
  editMparam(mparam) {
   if(mparam.interview_questions==undefined){
      mparam.interview_questions='';
    }
    this.openModal(mparam);
  }
}

export default angular.module('leafApp.mparam', [uiRouter])
  .config(routes)
  .component('mparam', {
    template: require('./mparam.html').default,
    controller: MparamComponent,
    controllerAs: 'mparamCtrl'
  })
  .name;
