'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('percentilebenchmark', {
      url: '/percentilebenchmark',
      template: '<percentilebenchmark></percentilebenchmark>'
    });
}
