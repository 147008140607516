'use strict';
import angular from 'angular';

/*@ngInject*/
export function SuccessFactorService() {
  let successFactorData = {
    _id : "",
    reload:true
  }
  return {
    setSuccessFactorData : setSuccessFactorData,
    getSuccessFactorData : getSuccessFactorData
  }
  function setSuccessFactorData(id,cb){
    successFactorData["_id"]=id;
    successFactorData["reload"]=false;
    return cb(successFactorData);
  }
  function getSuccessFactorData(cb) {
    return cb(successFactorData);
  }
  // AngularJS will instantiate a singleton by calling "new" on this function
}

export default angular.module('leafApp.SuccessFactorService', [])
.service('SuccessFactorService', SuccessFactorService)
.name;
