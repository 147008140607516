'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gsequences.routes';
/**
  * @class angular_module.GsequencesComponent
  */
export class GsequencesComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteSequence = Modal.confirm.delete(sequence => {
      $http.delete(`/api/gsequences/${sequence._id}`).then(() => this.getSequences());
    });
  }

  /**
	* @function getSequences
  * @memberOf angular_module.GsequencesComponent
  * @description this function calls get gsequences api to get gsequences data.
	*/
  getSequences() {
    this.$http.get('/api/gsequences')
      .then(response => {
        this.sequences = response.data;
      });
  }

  /**
	* @function getMusics
  * @memberOf angular_module.GsequencesComponent
  * @description this function calls get gmusics api to get gmusic data.
	*/
  getMusics() {
    this.$http.get('/api/gmusics')
      .then(response => {
        this.musics = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.GsequencesComponent
  * @fires getMusics
  * @fires getSequences
  * @description this function intiates the gsequences component
	*/
  $onInit() {
    this.getSequences();
    this.$http.get('/api/gcharacters')
      .then(response => {
        this.characters = response.data;
      });
    this.$http.get('/api/gbackgrounds')
      .then(response => {
        this.backgrounds = response.data;
      });
    this.getMusics();
  }

  /**
	* @function saveSequence
  * @memberOf angular_module.GsequencesComponent
  * @param {Object} sequence - sequence data
  * @description this function calls add gsequences api to add gsequence.
	*/
  saveSequence(sequence) {
    return this.$http.post('/api/gsequences', sequence).then(() => this.getSequences());
  }

  /**
	* @function updateSequence
  * @memberOf angular_module.GsequencesComponent
  * @param {Object} sequence - sequence data
  * @description this function calls update gsequences api to update gsequence.
	*/
  updateSequence(sequence) {
    return this.$http.put(`/api/gsequences/${sequence._id}`, sequence);
  }

  /**
	* @function openModal
  * @memberOf angular_module.GsequencesComponent
  * @param {Object} sequence - sequence data
  * @description this function will open modal for adding or editing gsequence.
	*/
  openModal(sequence) {
    var gsequencesCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_sequence.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.sequences = angular.copy(gsequencesCtrl.sequences);
        this.characters = angular.copy(gsequencesCtrl.characters);
        this.backgrounds = angular.copy(gsequencesCtrl.backgrounds);
        this.musics = angular.copy(gsequencesCtrl.musics);
        this.sequence = sequence;

        this.addData = function() {
          this.sequence.data = this.sequence.data || [];
          this.sequence.data.push({});
          
        };

        this.deleteData = function(index) {
          this.sequence.data.splice(index, 1);
        };

        this.commitSequence = function(l) {
          var promise;
          if (l._id) {
            promise = gsequencesCtrl.updateSequence(l);
          } else {
            promise = gsequencesCtrl.saveSequence(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addSequence
  * @memberOf angular_module.GsequencesComponent
  * @fires openModal
  * @description this function is used to add gsequence data.
	*/
  addSequence() {
    this.openModal({});
  }

  /**
	* @function editSequence
  * @memberOf angular_module.GsequencesComponent
  * @fires openModal
  * @param {Object} sequence - sequence data
  * @description this function is used to edit gsequence data.
	*/
  editSequence(sequence) {
    this.openModal(sequence);
  }

  /**
	* @function cloneSequence
  * @memberOf angular_module.GsequencesComponent
  * @fires openModal
  * @param {Object} sequence - sequence data
  * @description this function is used to clone gsequence data.
	*/
  cloneSequence(sequence) {
    let clonesequenceData={}
    if(typeof sequence.data !== undefined)
      clonesequenceData.data = sequence.data
    if(typeof sequence.active !== undefined)
      clonesequenceData.data = sequence.data
    if(typeof sequence.music_id !== undefined)
      clonesequenceData.music_id = sequence.music_id
    if(typeof sequence.music_volume !== undefined)
      clonesequenceData.music_volume = sequence.music_volume
    this.openModal(clonesequenceData)
  }
}

export default angular.module('leafApp.gsequences', [uiRouter])
  .config(routes)
  .component('gsequences', {
    template: require('./gsequences.html').default,
    controller: GsequencesComponent,
    controllerAs: 'gsequencesCtrl'
  })
  .name;
