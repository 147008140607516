'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('success_factor_update', {
      url: '/success_factor_update',
      template: '<success-factor-update></success-factor-update>'
    });
}
