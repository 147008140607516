"use strict";
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import { requestConfigV2 } from "../company.component";
import routes from "./companysections.routes";
/**
 * @class angular_module.CompanySectionsComponent
 */
export class CompanySectionsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;
    this.company_name="";
    this.isCompanyNameEditMode = false;

    this.deleteCompSection = Modal.confirm.delete((data) => {
      console.log(data);
      var company_id;
      this.companySections.splice(data, 1);
      // this.openModalDelete();
      return this.updateSections(this.companySections, this.companySections);
      // this.CompanyService.getCompanyData(function (res) {
      //   company_id = res._id;
      // });
      // $http
      //   .delete(`/api/company/sections/${company_id}`)
      //   .then(() => this.getSections());
    });
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompFrameworkComponent
   * @fires getCompframeworks
   * @description this function intiates the CompFramework Component.
   */
  $onInit() {
    this.getSections();
  }

  /**
   * @function getCompanySections
   * @memberOf angular_module.CompanySection
   * @description this function calls compframeworks api to get comp frameworks.
   */
  getSections() {
    var company_id;

    const cb = function(res){
      company_id=res._id;
      this.company_name=res.company_name;
    }


    this.CompanyService.getCompanyData(cb.bind(this));
    if (company_id == "") {
      this.$location.path("/company");
    } else {
      const apiPath = `/api/company/${company_id}`;
      this.$http.get(apiPath).then((response) => {
        // this.compframeworks = response.data;
        this.companySections = response.data.sections;
      });
    }
  }
  deleteCompanySection(index) {
    // const filteredArray = this.companySections.filter(el => el !== sectionName);
    this.companySections.splice(index, 1);
    // this.openModalDelete();
    return this.updateSections(this.companySections, this.companySections);
  }

  openModalDelete(){
    var companysectionsCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: "confirmDelete.html",
      size: "md",
      controller: function ($uibModalInstance) {
        "ngInject";
        debugger
        // this.compSectionName = typeof currentSectionName === 'object' ? currentSectionName.sectionName: currentSectionName;
        // this.modelType = type;
        // this.commitCompSection = function () {
        //   var promise;
        //   if (type === "Edit") {
        //     promise = companysectionsCtrl.updateCompSectionName(
        //       this.compSectionName, currentSectionName.index
        //     );
        //   } else {
        //     promise = companysectionsCtrl.saveCompSection(this.compSectionName);
        //   }
        //   promise.then(
        //     () => {
        //       $uibModalInstance.dismiss("cancel");
        //     },
        //     (err) => {
        //       this.error = err.statusText;
        //     }
        //   );
        // };
      },
      controllerAs: "$ctrl",
    });

  }

  /**
   * @function addCompframework
   * @memberOf angular_module.CompFrameworkComponent
   * @description this function is used to add compframework.
   */
  addCompanySections() {
    this.openModal("Add", "");
  }

  /**
   * @function saveCompSection
   * @param companySectionName name of the new section that we want to save
   * @description this function calls updateSections api to add section.
   */
  saveCompSection(companySectionName) {

    const sections = this.companySections.slice();
    sections.push(companySectionName);
    return this.updateSections(this.companySections, sections);
  }

  updateSections(oldSectionArray, newSectionArray) {
    var company_id;
    this.CompanyService.getCompanyData(function (res) {
      company_id = res._id;
    });
    const apiPath = `/api/company/${company_id}`;
    return this.$http
      .put(apiPath, { id: company_id, sections: newSectionArray })
      .then(() => {
        const reqConfig = requestConfigV2("EDIT", {
          _id: company_id,
          sections: newSectionArray,
        });
        this.$http(reqConfig)
          .then(() => {
            this.getSections();
          })
          .catch((error) => {
            // error saving in v2 then we will revert the changes done in v1
            this.$http
              .put(apiPath, { id: company_id, sections: oldSectionArray })
              .then(() => {
                console.log("Changes reverted successfully");
                this.getSections();
              });
          });
      })
      .catch((error) => {
        // error in saving in v1

        console.log(error);
      });
  }

  /**
   * @function updateCompSectionName
   * @param editedName is the new name after editing
   * @param oldName is use to find which section name to update
   * @description this function calls updateSection api to update.
   */
  updateCompSectionName(editedName, index) {
    // here we have to update section on both the apis
    // const updatedData = this.companySections.map(el => {
    //   if(el === oldName){
    //     return editedName;
    //   }else{
    //     return el;
    //   }
    // })
    // edited name is the index of the
    this.companySections[index] = editedName
    return this.updateSections(this.companySections, this.companySections);
  }

  /**
   * @function editCompanySection
   * @param currentSectionName we pass the current name of the section that we are going to edit
   * @fires openModal
   * @description this function is used to edit company section.
   */
  editCompanySection(index, sectionName) {
    const data = {index, sectionName}
    this.openModal("Edit", data);
  }

  enableEditCompName(){
    this.isCompanyNameEditMode = true;
    console.log(this.company_name);
  }
  updateCompanyName(){
    this.isCompanyNameEditMode = false;
    console.log(this.company_name);
    var company_id;
    var company_name_old;
    this.CompanyService.getCompanyData(function (res) {
      company_id = res._id;
      company_name_old: res.company_name;
    });
    const apiPath = `/api/company/${company_id}`;
    return this.$http
    .put(apiPath, { id: company_id, company_name: this.company_name })
    .then(() => {
      const reqConfig = requestConfigV2("EDIT", {
        _id: company_id,
        name: this.company_name,
      });

      this.$http(reqConfig).then(() => {
          // if all went well then we update the company name in the service also
          this.CompanyService.setCompanyData(company_id,this.company_name,  function (res) {});
        })
        .catch((error) => {
          // error saving in v2 then we will revert the changes done in v1
          console.log(error);
          this.$http
            .put(apiPath, { id: company_id, company_name: company_name_old })
            .then(() => {
              console.log("Changes reverted successfully");
              // company name is restore to previous company name
              this.company_name = company_name_old;
            });
        });
    })
    .catch((error) => {
      // error in saving in v1

      console.log(error);
    });
  }
  restoreCompanyName(){
    this.CompanyService.getCompanyData( (res) => {

      this.company_name=res.company_name;
      this.isCompanyNameEditMode = false;
    });
  }


  /**
   * @function openModal
   * @param {type} type of model that we want to open can be Edit or Add,
   * @param {currentSectionName} Current name of section
   * @description this function opens modal for adding or editing company section.
   */
  openModal(type, currentSectionName) {
    // var compframeworksCtrl = this;
    var companysectionsCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: "add_companysection.html",
      size: "md",
      controller: function ($uibModalInstance) {
        "ngInject";
        this.compSectionName = typeof currentSectionName === 'object' ? currentSectionName.sectionName: currentSectionName;
        this.modelType = type;
        this.commitCompSection = function () {
          var promise;
          if (type === "Edit") {
            promise = companysectionsCtrl.updateCompSectionName(
              this.compSectionName, currentSectionName.index
            );
          } else {
            promise = companysectionsCtrl.saveCompSection(this.compSectionName);
          }
          promise.then(
            () => {
              $uibModalInstance.dismiss("cancel");
            },
            (err) => {
              this.error = err.statusText;
            }
          );
        };
      },
      controllerAs: "$ctrl",
    });
  }
}

export default angular
  .module("leafApp.companysections", [uiRouter])
  .config(routes)
  .component("companysections", {
    template: require("./companysections.html").default,
    controller: CompanySectionsComponent,
    controllerAs: "companysectionsCtrl",
  }).name;
