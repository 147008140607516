'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('score', {
      url: '/score',
      template: '<score></score>'
    });
}
