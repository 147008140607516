'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('tvs_motors_private_reportspec', {
      url: '/tvs_motors_private_reportspec',
      template: '<tvsmotorsprivatereportspectab></tvsmotorsprivatereportspectab>'
    });
}
