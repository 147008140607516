'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './listitem.routes';

export class ListitemComponent {
  /*@ngInject*/
  constructor($http, $scope, Modal, $stateParams) {
    this.$http = $http;
    this.listid = $stateParams.id;
    this.Modal = Modal;

    this.alertMessage = Modal.message.alert();
    this.deleteItem = Modal.confirm.delete(listitem => {
      $http.delete(`/api/listitems/${listitem._id}`);
    });
  }

  $onInit() {
    this.$http.get(`/api/lists/${this.listid}`)
      .then(response => {
        this.list = response.data;
        this.dropDown();
      });

    this.$http.get(`/api/listitems/${this.listid}/list`)
      .then(response => {
        this.listitems = response.data;
      });
  }

  fetchItems(listid) {
    return this.$http.get(`/api/listitems/${listid}/list`);
  }

  dropDown() {
    if (this.list.primary && this.list.primary.dropdown) {
      this.fetchItems(this.list.primary.dropdown)
        .then(response => {
          this.primaryDropdown = response.data;
        });
    }
    if (this.list.secondary && this.list.secondary.dropdown) {
      this.fetchItems(this.list.secondary.dropdown)
        .then(response => {
          this.secondaryDropdown = response.data;
        });
    }
    if (this.list.tertiary && this.list.tertiary.dropdown) {
      this.fetchItems(this.list.tertiary.dropdown)
        .then(response => {
          this.tertiaryDropdown = response.data;
        });
    }
  }

  addItem() {
    this.listitems.push({ list: this.list._id });
  }

  saveItem(listitem, index) {
    var promise;
    if (listitem._id) {
      promise = this.$http.put(`/api/listitems/${listitem._id}`, listitem);
    } else {
      promise = this.$http.post('/api/listitems', listitem);
    }

    promise.then(() => {
      if (index || index === 0) {
        this.listitems.splice(index, 1);
      }
      this.alertMessage(listitem.name, listitem._id);
      this.$onInit();
    });
  }
}

export default angular.module('leafApp.listitem', [uiRouter])
  .config(routes)
  .component('listitem', {
    template: require('./listitem.html').default,
    controller: ListitemComponent,
    controllerAs: 'listitemCtrl'
  })
  .name;
