'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gamesbenchmark.routes';
 /**
  * @class angular_module.BenchmarkComponent
  */
export class BenchmarkComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;

    this.deleteGame= Modal.confirm.delete(game => {
      $http.delete(`/api/gamesbenchmarks/${game._id}`).then(() => this.getGames());
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.BenchmarkComponent
  * @fires getGames
  * @description this function intiates the game Benchmark component
	*/     
  $onInit() {
    this.getGames();
  }

  /**
	* @function editGame
  * @memberOf angular_module.BenchmarkComponent
  * @fires openModal
  * @param {Object} game - game benchmark data
  * @description this function is used to open modal for edit game benchmark.
	*/ 
  editGame(game) {
    this.openModal(game);
  }

  /**
	* @function getGames
  * @memberOf angular_module.BenchmarkComponent
  * @description this function calls get gamesbenchmarks api which is used to get game benchmarks
	*/ 
  getGames() {
    this.$http.get('/api/gamesbenchmarks')
    .then(response => {
      this.games = response.data;
    });
  }

  /**
	* @function addGame
  * @memberOf angular_module.BenchmarkComponent
  * @fires openModal
  * @description this function is used to open modal for add game benchmark.
	*/
  addGame() {
    this.openModal({});
  }

  /**
	* @function saveGame
  * @memberOf angular_module.BenchmarkComponent
  * @param {Object} game - game benchmark data
  * @description this function calls add gamesbenchmarks api which is used to add game benchmark.
	*/
  saveGame(game){
    return this.$http.post('/api/gamesbenchmarks', game).then(() => this.getGames());
  }

  /**
	* @function updateGame
  * @memberOf angular_module.BenchmarkComponent
  * @param {Object} game - game benchmark data
  * @description this function calls update gamesbenchmarks api which is used to update game benchmark.
	*/
  updateGame(game){
    return this.$http.put(`/api/gamesbenchmarks/${game._id}`, game);
  }

  /**
	* @function openModal
  * @memberOf angular_module.BenchmarkComponent
  * @param {Object} game - game benchmark data
  * @description this function will open modal for adding, editing or cloning game benchmark.
	*/
  openModal(game) {
    var gamesbenchmarkCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_game.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        this.game = game;
        this.commitGame = function(l) {
          if(l._id)
            var promise= gamesbenchmarkCtrl.updateGame(l);
          else {
            var promise= gamesbenchmarkCtrl.saveGame(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}

export default angular.module('leafApp.gamesbenchmark', [uiRouter])
  .config(routes)
  .component('gamesbenchmark', {
    template: require('./gamesbenchmark.html').default,
    controller: BenchmarkComponent,
    controllerAs: 'gamesbenchmarkCtrl'
  })
  .name;
