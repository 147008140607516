"use strict";
import angular from 'angular';

import uiRouter from 'angular-ui-router';


import routes from "./reprocess.routes";
/**
 * @class angular_module.CompanySectionsComponent
 */
export class ReprocessComponent {
  /*@ngInject*/

  constructor(CompanyService, $location, $http) {
    this.CompanyService = CompanyService;
    this.$location = $location;
    this.$http = $http;
    this.loading = 'Loading...';
    this.campusRecruitment;
    this.customizedProfileFit;
    this.learningAndDevelopment;
    this.organizationalMatch;
    this.selectedId = {
      campusRecruitmentId:'',
      customizedProfileFitId:'',
      learningAndDevelopmentId:'',
      organizationalMatchId:''
    }
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompFrameworkComponent
   * @fires getCompframeworks
   * @description this function intiates the CompFramework Component.
   */
  $onInit() {
    this.getAssessmentList();
  }


  getAssessmentList() {
    let companyId;

    const cb = function(res){
      companyId=res._id;
      this.company_name=res.company_name;
    }


    this.CompanyService.getCompanyData(cb.bind(this));
    if(companyId == '') {
      this.$location.path('/company');
    }

    this.$http.get(`/api/assessment/${companyId}`).then((response) => {
      console.log(response.data);
      this.loading = null;
      if(!response.data.data) return;
      this.campusRecruitment = response.data.data["Campus Recruitment"];
      this.customizedProfileFit = response.data.data["Customised Profile Fit"];
      this.learningAndDevelopment = response.data.data["Learning & Development"];
      this.organisationalMatch = response.data.data["Organisational Match"];
    });
  }


  setSuccessNull(){
    this.success = null;
  }


  onReprocessClick(type) {
    console.log(type);
    this.success = null;
    const id = this.selectedId[type];
    if(id === '') {
      alert('Select Assessment first');
      return;
    }

    this.$http.get(`/api/v1/reprocessForAssessment/${id}`).then((response) => {
      console.log(response.data);
      this.success = 'Selected Assessment Reprocessed Successfully';

      setTimeout(this.setSuccessNull, 500);
    });
  }


}

export default angular
  .module("leafApp.reprocess", [uiRouter])
  .config(routes)
  .component("reprocess", {
    template: require("./reprocess.html").default,
    controller: ReprocessComponent,
    controllerAs: "reprocessCtrl",
  }).name;
