'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gsjt.routes';
/**
 * @class angular_module.GquestionsComponent
 */
export class GSjtComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.selectedAptitudeGames = [''];
    this.selectedAptitudeGamesOriginal = [''];
    this.aptitudeGamesOptions = [];
    this.sjtList = [];
    this.sequences = [];
    this.questions = [];
  }

  $onInit() {
    this.getAddedSjts();
    this.getSequences();
    this.getQuestions();
  }

  cloneSjt(sjt) {
    this.openModal(sjt, true);
  }

  editSjt(sjt) {
    this.sjt = sjt;
    this.openModal(sjt);
    console.log('Edit questions: ', sjt);
  }

  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  addSjt() {
    this.openModal();
  }

  // get for all the added sjt game
  getAddedSjts() {
    this.$http
      .get('/api/gsjt', {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => (this.sjtList = res.data));

    //  return;
  }

  getSequences() {
    this.$http
      .get('/api/gsequences2', {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => {
        this.sequences = res.data;
      });

    //  return;
  }

  getQuestions() {
    this.$http
      .get('/api/gorderingquestion', {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => {
        this.questions = res.data;
      });

    //return;
  }

  openModal(sjt, isClone) {
    var gquestionsCtrl = this;
    this.modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_sjt.html',
      size: 'lg',
      controller($uibModalInstance) {
        'ngInject';
        // this is in edit mode

        this.sequenceList = [
          {
            sequenceId: '',
            gOrderingQuestionId: '',
            value: '',
          },
        ];

        this.sequences = gquestionsCtrl.sequences;
        this.questions = gquestionsCtrl.questions;
        this.title = '';
        this.sjtType = '';

        this.isEditMode = false;
        if (sjt) {
          this.sjt = sjt;
          this.aptitudeName = sjt.name;
          this.title = sjt.title;
          this.sjtType = sjt.sjtType;
          this.sequenceList = sjt.sjtGameData.map((item) => {
            return {
              sequenceId: item.sequenceId ? item.sequenceId._id : '',
              gOrderingQuestionId: item.gOrderingQuestionId ? item.gOrderingQuestionId._id : '',
              value: item.value,
            };
          });
          // this.selectedAptitudeGames = sjt.aptitudeGames.map(
          //   (el) => el.gameId
          // );
          this.isEditMode = isClone ? false : true;
        }

        this.addSjt = function () {
          this.sequenceList.push({
            sequenceId: '',
            gOrderingQuestionId: '',
            value: '',
          });
        };

        this.removeSjt = function (index) {
          this.sequenceList = this.sequenceList.filter((el, i) => i !== index);
        };

        this.submitSjt = function () {
          if (this.isEditMode) {
            gquestionsCtrl.submitEditSjt(
              {
                title: this.title,
                sjtGameData: this.sequenceList,
                sjtType: this.sjtType,
              },
              sjt._id,
            );
          } else {
            if (
              !this.title ||
              !this.sjtType ||
              !this.sequenceList.every((obj) => {
                return obj.sequenceId && obj.value && obj.gOrderingQuestionId;
              })
            ) {
              return;
            }

            gquestionsCtrl.submitSendSjt({
              title: this.title,
              sjtGameData: this.sequenceList,
              sjtType: this.sjtType,
            });
          }
        };
      },
      controllerAs: '$ctrl',
    });
  }

  deleteSjt(_id) {
    this.$http
      .delete(`/api/gsjt/${_id}`, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => this.getAddedSjts());

    return;
  }

  submitSendSjt(data) {
    this.$http
      .post('/api/gsjt/', data, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then(() => {
        this.modalInstance.close();
        this.getAddedSjts();
      });
  }

  submitEditSjt(data, id) {
    this.$http
      .put(`/api/gsjt/${id}`, data, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then(() => {
        this.modalInstance.close();
        this.getAddedSjts();
      });
  }
}

export default angular
  .module('leafApp.gsjt', [uiRouter])
  .config(routes)
  .component('gsjt', {
    template: require('./gsjt.html').default,
    controller: GSjtComponent,
    controllerAs: 'gsjtCtrl',
  }).name;
