'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './percentilebenchitem.routes';
/**
  * @class angular_module.PercentilebenchitemComponent
  */
export class PercentilebenchitemComponent {
  /*@ngInject*/
  constructor($http, $scope,$uibModal, Modal, $stateParams,$location) {
    this.$http = $http;
    this.percentileid = $stateParams.id;
    this.$uibModal = $uibModal;
    this.$location=$location;
    this.totalScoreCount=16;
    this.percentileBenchmarkid='';
    this.perAttrData={};
    this.noWhiteSpace=/^\s*(\w.*)$/;
    
    this.alertMessage = Modal.message.alert();
    this.deleteItem = Modal.confirm.delete(listitem => {
      $http.delete(`/api/attrsbenchmarks/${listitem._id}`);
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.PercentilebenchitemComponent
  * @fires getPsychoAttributes
  * @description this function intiates the Percentilebenchitem component
	*/
  $onInit() {
    if(this.percentileid!=""){
      this.$http.get(`/api/attrsbenchmarks/${this.percentileid}`)
      .then(response => {
        this.percentilebenchitem = response.data;
        this.percentilebenchitem2=response.data;
        //to remove $$hashKey (to remove all dom update on dismiss modal)
        this.percentilebenchitem=JSON.parse(angular.toJson(this.percentilebenchitem));
        var percentileCtrl=this;
        if(this.percentilebenchitem.percentileattributes){
          if(this.percentilebenchitem.percentileattributes.length==0){
            /*this.$http.get('/api/psychos')
            .then(response => {
              percentileCtrl.attributes = response.data;*/
              this.percentilebenchitem.attributes.forEach(psychoItem => {
                var percentileItem={};
                percentileItem['attribute_id']=psychoItem.attribute_id;
                percentileItem['attribute_name']=psychoItem.attribute_name;
                percentileItem['percentages']=[];
                for(var i=0;i<16;i++){
                  var percentageValues={};
                  percentageValues['key']=i;
                  percentageValues['percentage']=0;
                  percentileItem['percentages'].push(percentageValues);
                }
                this.percentilebenchitem.percentileattributes.push(percentileItem);
              });
              
              
              this.updateAttributeBenchmark(this.percentilebenchitem);
           /* }).catch(err=>{

            });*/
          }
        }
      });
    }else{
      this.percentilebenchitem={};
      this.percentilebenchitem2=={};
    }
    this.getPsychoAttributes();
  }

  /**
	* @function getPsychoAttributes
  * @memberOf angular_module.PercentilebenchitemComponent
  * @description this function calls get psychos api which is used to get Psycho Attributes
	*/
  getPsychoAttributes(){
    var percentileCtrl=this;
    this.$http.get('/api/psychos')
    .then(response => {
      percentileCtrl.attributes = response.data;
    });
  }

  /**
	* @function addAttributeBenchmark
  * @memberOf angular_module.PercentilebenchitemComponent
  * @fires openModal
  * @description this function opens modal for adding benchmark
	*/
  addAttributeBenchmark() {
    this.openModal({},'new');
  }

  /**
	* @function editAttributeBenchmark
  * @memberOf angular_module.PercentilebenchitemComponent
  * @fires openModal
  * @param {Object} benchmark - benchmark data
  * @description this function opens modal for editing benchmark
	*/
  editAttributeBenchmark(benchmark) {
    this.percentileBenchmarkid=benchmark._id;
    this.countArr=[];
    this.countArr['_id']=this.percentilebenchitem._id;
    this.countArr['attribute_id']= benchmark.attribute_id;
    this.currentAttributeId=benchmark.attribute_id;
    /*this.attributes.forEach(psychos => {
      var isPresent=false;
      this.percentilebenchitem.percentileattributes.forEach(element => {
          if(element.attribute_id==psychos._id){
            isPresent=true;
          }
      });
      if(!isPresent){
        var percentileData={};
        percentileData['attribute_id']=psychos._id;
        percentileData['attribute_name']=psychos.name;
        percentileData['percentages']=[];
        this.percentilebenchitem.percentileattributes.push(percentileData);
      }
    });*/
    this.percentilebenchitem.percentileattributes.forEach(element => {
      if(element.attribute_id==benchmark.attribute_id){
          this.countArr= element.percentages;
      }
    });
    //to remove $$hashKey (to remove all dom update on dismiss modal)
    this.countArr=JSON.parse(angular.toJson(this.countArr));
    //
    this.openModal(benchmark,'edit',this.countArr);
  }

  /**
	* @function editbenchmarkName
  * @memberOf angular_module.PercentilebenchitemComponent
  * @description this function is used to edit benchmark Name.
	*/
  editbenchmarkName(){
    if(this.percentilebenchitem._id){
        this.updateAttributeBenchmark(this.percentilebenchitem).then( response =>{
          this.success="Update Successfully";
     });
    }else{
      this.percentilebenchitem['percentileattributes']=[];
      this.percentilebenchitem['attributes']=[];
      this.attributes.forEach(attr=>{
        var attrHMLData={};
        attrHMLData['attribute_name']=attr.name;
        attrHMLData['attribute_id']=attr._id;
        attrHMLData['High']='';
        attrHMLData['Low']='';
        this.percentilebenchitem['attributes'].push(attrHMLData);

        var attrPercentileData={};
        attrPercentileData['attribute_name']=attr.name;
        attrPercentileData['attribute_id']=attr._id;
        attrPercentileData['percentages']=[];
        for(var i=0;i<16;i++){
          var percentageValues={};
          percentageValues['key']=i;
          percentageValues['percentage']=0;
          attrPercentileData['percentages'].push(percentageValues);
        }

        this.percentilebenchitem['percentileattributes'].push(attrPercentileData);
      })
      this.saveAttributeBenchmark(this.percentilebenchitem);
    }
  }

  /**
	* @function saveAttributeBenchmark
  * @memberOf angular_module.PercentilebenchitemComponent
  * @param {Object} benchmark - attribute benchmark data
  * @description this function calls add attrsbenchmarks api which is used to save Attribute Benchmarks
	*/
  saveAttributeBenchmark(benchmark) {
    return this.$http.post('/api/attrsbenchmarks', benchmark).then((result) =>{
     try{
       
        this.percentileid=result.data._id;
        this.$location.path('percentilebenchitem/'+this.percentileid);
     }catch(e){
      
     }
      
    });
  }

  /**
	* @function updateAttributeBenchmark
  * @memberOf angular_module.PercentilebenchitemComponent
  * @param {Object} benchmark - attribute benchmark data
  * @description this function calls update attrsbenchmarks api which is used to update Attribute Benchmarks
	*/
  updateAttributeBenchmark(benchmark) {
   
   // 
    return this.$http.put(`/api/attrsbenchmarks/${benchmark._id}`, benchmark);
  }

  /**
	* @function openModal
  * @memberOf angular_module.PercentilebenchitemComponent
  * @param {Object} benchmark - benchmark data
  * @param {String} task - task type
  * @param {String} totalCount - total count of benchmarks
  * @description this function will open modal for adding, editing or cloning benchmark.
	*/
  openModal(benchmark, task,totalCount) {
    var percentilebenchitemCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_attribute.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        this.benchmark = benchmark;
        this.percentileArray=[];
        this.countTotalScores=[];
        this.attributeData = percentilebenchitemCtrl.attributes;
        this.countTotalScores=totalCount;
        this.onlyNumbers = /^[0-9]*\.?[0-9]+$/;
        if(task == 'new') {
            
        }

        this.commitAttrBenchmarks = function(l) {
             percentilebenchitemCtrl.percentilebenchitem.percentileattributes.forEach(element => {
                  if(element.attribute_id===l.attribute_id){
                    delete(l._id);
                    delete(l.attribute_id);
                    element.percentages=l;
                  }
             });
             percentilebenchitemCtrl.percentilebenchitem.percentileattributes.forEach(element => {
              if(element.attribute_id==percentilebenchitemCtrl.currentAttributeId){
                element.percentages=this.countTotalScores;
              }
            })
          if(percentilebenchitemCtrl.percentilebenchitem._id)
           {
             
             
            var promise= percentilebenchitemCtrl.updateAttributeBenchmark(percentilebenchitemCtrl.percentilebenchitem);
           }
          else {
              
              
            //var promise= percentilebenchitemCtrl.saveAttributeBenchmark(percentilebenchitemCtrl.percentilebenchitem);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}
export default angular.module('leafMainApp.percentilebenchitem', [uiRouter])
  .config(routes)
  .component('percentilebenchitem', {
    template: require('./percentilebenchitem.html').default,
    controller: PercentilebenchitemComponent,
    controllerAs: 'percentilebenchitemCtrl'
  })
  .name;
