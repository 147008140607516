'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './persona_factors.routes';
/**
  * @class angular_module.PersonaFactorsComponent
  */
export class PersonaFactorsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, $location) {
    this.message = 'Hello';
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$location=$location;
    this.deletepersona_factorData= Modal.confirm.delete(report => {
      $http.delete(`/api/persona_factors/${report._id}`).then(() => this.getpersona_factorData());
    });
}

/**
	* @function $onInit
  * @memberOf angular_module.PersonaFactorsComponent
  * @fires getpersona_factorData
  * @fires psychoAttributeData
  * @description this function intiates the PersonaFactors Component
	*/
$onInit() {
 this.getpersona_factorData();    
 this.psychoAttributeData();
 this.$http.get('/api/lists/persona/name')
      .then(response => {
        this.personacategories = response.data;

  }); 
}

/**
	* @function getpersona_factorData
  * @memberOf angular_module.PersonaFactorsComponent
  * @description this function calls persona_factors api to get persona factors attributes.
	*/
getpersona_factorData() {
this.$http.get('/api/persona_factors').then(response => {

  this.persona_factorDatas = response.data;
  
});
}

/**
	* @function psychoAttributeData
  * @memberOf angular_module.PersonaFactorsComponent
  * @description this function calls psychos api to get psycho attributes.
	*/
psychoAttributeData()
{
  this.$http.get('/api/psychos')
  .then(response => {

    this.attrs = response.data;

  });
}

/**
	* @function addNew
  * @memberOf angular_module.PersonaFactorsComponent
  * @fires openModal
  * @description this function open modal for adding persona factors.
	*/
addNew() {
 this.openModal({},this.personacategories);
}

/**
	* @function saveReport
  * @memberOf angular_module.PersonaFactorsComponent
  * @param {Object}company - persona attribute data
  * @description this function calls persona_factors api to add persona attribute
	*/
saveReport(company){

 return this.$http.post('/api/persona_factors', company).then((response) => this.getpersona_factorData());
}

/**
	* @function updateAttr
  * @memberOf angular_module.PersonaFactorsComponent
  * @param {Object}attr - attr data
  * @description this function calls persona_factors api to update persona factors attributes.
	*/
updateAttr(attr) {
 return this.$http.put(`/api/persona_factors/${attr._id}`, attr);
}

/**
	* @function editpersona_factorData
  * @memberOf angular_module.PersonaFactorsComponent
  * @fires openModal
  * @param {Object} persona_factorData - persona_factor data
  * @description this function open modal for editing app persona attribute
	*/
editpersona_factorData(persona_factorData) {
this.openModal(persona_factorData,this.personacategories);
}

/**
	* @function openModal
  * @memberOf angular_module.PersonaFactorsComponent
  * @param {Object} persona_factor - persona_factor data
  * @param {String} personacategories - personacategories
  * @description this function will open modal for adding or editing persona attribute.
	*/
openModal(persona_factor,personacategories) {
var personaFactorsCtrl = this;
var modalInstance = this.$uibModal.open({
  animation: true,
  templateUrl: 'add_New.html',
  size: 'md',
  controller: function ($uibModalInstance) {
    'ngInject';
    this.persona_factor = persona_factor;
    this.persona_factor_attribute_id = this.persona_factor.psycho_attribute_id;
    this.persona_factor_name = this.persona_factor.name;
    this.attrs = personaFactorsCtrl.attrs
    this.personacategories=personacategories;
    this.commitpersona_factorData = function(ModalData) {
      var psycho_attribute_name = ModalData.psycho_attribute;
      for(var k =0 ; k < this.attrs.length ; k ++)
      {
        if(psycho_attribute_name == this.attrs[k].name)
        {
          ModalData.psycho_attribute_id = this.attrs[k]._id
        }
      }
      this.personacategories.forEach(personacategory => {
          if(personacategory._id==ModalData.persona_attribute_id){
            ModalData.persona_attribute=personacategory.name;
          }
      }); 
      var promise;
      if (ModalData._id) {
        promise = personaFactorsCtrl.updateAttr(ModalData);
      } else { 
        promise = personaFactorsCtrl.saveReport(ModalData);
      }
      promise.then(() => {
        $uibModalInstance.dismiss('cancel');
      }, (err) => {
        this.error = err.statusText;
      });       
    }
  },
  controllerAs: '$ctrl'
});
}
}

export default angular.module('persona_factors', [uiRouter])
  .config(routes)
  .component('personaFactors', {
    template: require('./persona_factors.html').default,
    controller: PersonaFactorsComponent,
    controllerAs: 'personaFactorsCtrl'
  })
  .name;
