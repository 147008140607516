"use strict";
const angular = require("angular");
const _ = require("lodash");
import uiRouter from "angular-ui-router";

import routes from "./tvs_motors_private_benchmark.routes";
import "./tvs_motors_private_benchmark.css";
class TvsMotorsPrivateBenchmarkComponent {
  /*@ngInject*/
  constructor($http) {
    this.$http = $http;
    this.assessments = [];
    this.isUpdating = false;
    this.success = false;
    this.error = false;
    this.b1 = 100;
    this.b2 = 50;
    this.b3 = 35;
    this.b4 = 15;

    this.mgb1 = 100;
    this.mgb2 = 50;
    this.mgb3 = 35;
    this.mgb4 = 15;

    this.company = "";
  }

  $onInit() {
    this.getCompany();
  }

  getCompany() {
    this.$http.get("/api/company/5f6325c4fd451b19e243df7e").then((response) => {
      if (response && response.data && response.data.valuesVerditBoundaries) {
        this.b1 = response.data.valuesVerditBoundaries["Very High"];
        this.b2 = response.data.valuesVerditBoundaries["High"];
        this.b3 = response.data.valuesVerditBoundaries["Medium"];
        this.b4 = response.data.valuesVerditBoundaries["Low"];
      }
      if (response && response.data && response.data.mgvaluesVerditBoundaries) {
        this.mgb1 = response.data.mgvaluesVerditBoundaries["Very High"];
        this.mgb2 = response.data.mgvaluesVerditBoundaries["High"];
        this.mgb3 = response.data.mgvaluesVerditBoundaries["Medium"];
        this.mgb4 = response.data.mgvaluesVerditBoundaries["Low"];
      }
      this.company = response.data;
      console.log(this.company);
    });
  }

  submit() {
    this.company.valuesVerditBoundaries = {
      "Very High": this.b1 ? this.b1 : 100,
      High: this.b2,
      Medium: this.b3,
      Low: this.b4,
    };
    this.company.mgvaluesVerditBoundaries = {
      "Very High": this.mgb1 ? this.mgb1 : 100,
      High: this.mgb2,
      Medium: this.mgb3,
      Low: this.mgb4,
    };
    console.log(this.company);

    this.isUpdating = true;
    this.success = false;
    this.error = false;

    this.$http
      .put("/api/company/5f6325c4fd451b19e243df7e", this.company)
      .then((data) => {
        this.getCompany();
        if (data.data) {
          this.success = "Verdict boundary values are successfully updated";
        }
      });
  }
}

export default angular
  .module("leafApp.tvs_motors_private_benchmark", [uiRouter])
  .config(routes)
  .component("tvsmotorsprivatebenchmarktab", {
    template: require("./tvs_motors_private_benchmark.html").default,
    controller: TvsMotorsPrivateBenchmarkComponent,
    controllerAs: "tvsCtrl",
  }).name;
