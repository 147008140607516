'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('company', {
    url: '/company',
    template: '<company></company>'
  })
}
