'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('motivation', {
      url: '/motivation',
      template: '<motivation></motivation>'
    });
}
