/* eslint-disable comma-style */
'use strict';

import angular from 'angular';
// import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
// import 'angular-socket-io';
import 'ui-select';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
// import ngMessages from 'angular-messages';
//import ngValidationMatch from 'angular-validation-match';
import markdown from 'angular-marked';

import { routeConfig } from './app.config';

import _Auth from '../components/auth/auth.module';
import account from './account';
import admin from './admin';
import navbar from '../components/navbar/navbar.component';
import footer from '../components/footer/footer.component';
import main from './main/main.component';
import jrt from './jrt/jrt.component';
import list from './list/list.component';
import listitem from './listitem/listitem.component';
import psycho from './psycho/psycho.component';
import activity from './activity/activity.component';
import scatter from './cultfactor/scatter/scatter.component';
import section from './cultfactor/section/section.component';
import mparam from './mparam/mparam.component';
import gamedata from './gamedata/gamedata.component';
import gcharacters from './gamedata/gcharacters/gcharacters.component';
import gbackgrounds from './gamedata/gbackgrounds/gbackgrounds.component';
import gsequences from './gamedata/gsequences/gsequences.component';
import gquestions from './gamedata/gquestions/gquestions.component';
import gaptitude from './gamedata/gaptitude/gaptitude.component';
import gsjt from './gamedata/sjt/gsjt.component';
import gsequence2 from './gamedata/sequences/gsequences2.component';
import gorderingquestions from './gamedata/gorderingquestions/gorderingquestions.component';
import gminigames from './gamedata/gminigames/gminigames.component';
import gmusics from './gamedata/gmusic/gmusic.component';
import companies from './company/company.component';
import orgcompetencies from './company/orgcompetency/orgcompetency.component';
import suborgcompetencies from './company/orgcompetency/suborgcompetency/suborgcompetency.component';
import compframework from './company/compframework/compframework.component';
import companySectionsComponent from './company/sections/companysections.component';
import companyDepartmentsComponent from './company/departments/companydepartments.component';
import companygame from './company/game/companygame.component';
import AptitudeComponent from './company/aptitude/aptitude.component';
import ReprocessComponent from './company/reprocess/reprocess.component';
import testcount from './company/testcount/testcount.component';
import verdict from './company/verdict/verdict.component';
import score from './score/score.component';
import invite from './invite/invite.component';
import benchmark from './benchmark/benchmark.component';
import attrsbenchmark from './benchmark/attrsbenchmark/attrsbenchmark.component';
import gamesbenchmark from './benchmark/games/gamesbenchmark.component';
import constants from './app.constants';
import util from '../components/util/util.module';
// import socket from '../components/socket/socket.service';
import modal from '../components/modal/modal.service';
import CompanyService from './company/company.service';
import OrgCompetencyService from './company/orgcompetency/orgcompetency.service';
import Version from './version/version.component';
import learning_development from './learning_development/learning_development.component';
import persona_factors from './personafactors/persona_factors.component';
import reportspec from './reportspec/reportspec.component';
import percentilebenchmark from './benchmark/percentilebenchmark/percentilebenchmark.component';
import percentilebenchmarkitem from './benchmark/percentilebenchitem/percentilebenchitem.component';
import derailers from './derailers/derailers.component';
import motivation from './motivation/motivation.component';
import successfactors from './successfactors/successfactors.component';
import successFactorUpdate from './successfactorupdate/successfactorupdate.component';
import successFactorTagging from './successfactorupdate/successfactortagging/successfactortagging.component';
import successFactorVideos from './successfactorupdate/successfactorvideos/successfactorvideos.component';
import SuccessFactorService from './successfactors/successfactors.service';
import managerialpot from './managerialpot/managerialpot.component';
import alldepartments from './alldepartments/alldepartments.component';
import bhired_candidate from './bhiredcandidate/bhired_candidate.component';
import bhired_competency from './bhiredcompetency/bhired_competency.component';
import bhired_benchmark from './bhiredcompetency/bhiredbenchmark/bhired_benchmark.component';
import tvs_benchmark from './tvs_benchmark/tvs_benchmark.component';
import tvsreportspec from './tvsreportspec/tvsreportspec.component';
import keva from './keva/keva.component';
import kevaCandidate from './kevaCandidate/kevaCandidate.component';
import sonyCandidate from './sonyCandidate/sonyCandidate.component';
import tvs from './tvs/tvs.component';
import tvs_motors_private from './tvs_motors_private/tvs_motors_private.component';
import tvs_motors_private_benchmark from './tvs_motors_private_benchmark/tvs_motors_private_benchmark.component';
import tvs_motors_private_reportspec from './tvs_motors_private_reportspec/tvs_motors_private_reportspec.component'
import abgcandidate from './abgcandidate/abgcandidate.component';
import './app.scss';

angular
  .module('leafApp', [
    markdown,
    // ngAnimate,
    ngCookies,
    ngResource,
    ngSanitize,
    uiRouter,
    uiBootstrap,
    'ui.select',
    // ngMessages,

    // ngValidationMatch,
    _Auth,
    account,
    admin,
    navbar,
    footer,
    main,
    jrt,
    list,
    listitem,
    psycho,
    activity,
    mparam,
    gamedata,
    gquestions,
    gcharacters,
    gaptitude,
    gsjt,
    gsequence2,
    gorderingquestions,
    gbackgrounds,
    gsequences,
    gminigames,
    score,
    invite,
    companies,
    constants,
    util,
    modal,
    CompanyService,
    orgcompetencies,
    OrgCompetencyService,
    suborgcompetencies,
    testcount,
    companygame,
    compframework,
    companySectionsComponent,
    companyDepartmentsComponent,
    benchmark,
    attrsbenchmark,
    gamesbenchmark,
    gmusics,
    scatter,
    section,
    reportspec,
    Version,
    learning_development,
    percentilebenchmark,
    AptitudeComponent,
    ReprocessComponent,
    persona_factors,
    motivation,
    managerialpot,
    alldepartments,
    derailers,
    successfactors,
    successFactorUpdate,
    successFactorTagging,
    successFactorVideos,
    SuccessFactorService,
    percentilebenchmarkitem,
    bhired_candidate,
    bhired_competency,
    bhired_benchmark,
    tvs_benchmark,
    tvsreportspec,
    keva,
    kevaCandidate,
    sonyCandidate,
    tvs,
    tvs_motors_private,
    tvs_motors_private_benchmark,
    tvs_motors_private_reportspec,
    abgcandidate,
    verdict,
  ])
  .config(routeConfig)
  .run(function($rootScope, $location, Auth) {
    'ngInject';
    // Redirect to login if route requires auth and you're not logged in
    $rootScope.$on('$stateChangeStart', function(event, next) {
      Auth.isLoggedIn(function(loggedIn) {
        if (!loggedIn) {
          $location.path('/login');
        }
      });
    });
  });

angular.element(document).ready(() => {
  angular.bootstrap(document, ['leafApp'], {
    strictDi: true,
  });
});
