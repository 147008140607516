"use strict";
const angular = require("angular");

import uiRouter from 'angular-ui-router';

import { requestConfigV2 } from "../../company/company.component";

import routes from "./gaptitude.routes";
/**
 * @class angular_module.GquestionsComponent
 */
export class GaptitudeComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.selectedAptitudeGames = [""];
    this.selectedAptitudeGamesOriginal = [""];
    this.aptitudeGamesOptions = [];
    this.addedAptitudeList = [];
  }

  $onInit() {
    this.getAptitudeGamesOptions();
    this.getAddedAptitudes();
  }

  cloneAptitude(aptitude) {
    console.log("Clone functionality: ", aptitude);
    this.openModal({
      name: aptitude.name,
      aptitudeGames: aptitude.aptitudeGames,
    });
  }

  editAptitude(aptitude) {
    this.aptitude = aptitude;
    this.openModal(aptitude);
    console.log("Edit questions: ", aptitude);
  }

  deleteAptitude(aptitude) {
    console.log("Delete question: ", aptitude);
  }

  addAptitudeAssessment() {
    this.openModal();
  }

  // get for all the added aptitude game
  getAddedAptitudes() {
    this.$http(requestConfigV2("GET", null, "aptitude/game-list", 2)).then(
      (data) => {
        this.addedAptitudeList = data.data.list;
      }
    );
  }

  // get aptitude game list like - Sequence, Tower Of hanoi
  getAptitudeGamesOptions() {
    this.$http(requestConfigV2("GET", null, "aptitude/games/list", 2)).then(
      (data) => {
        this.aptitudeGamesOptions = data.data.list;
      }
    );
  }

  openModal(aptitude) {
    var gquestionsCtrl = this;
    this.modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: "add_question.html",
      size: "lg",
      controller: function ($uibModalInstance) {
        "ngInject";
        // this is in edit mode
        this.aptitudeName = "";
        this.selectedAptitudeGames = gquestionsCtrl.selectedAptitudeGames;

        this.aptitudeGamesOptions = gquestionsCtrl.aptitudeGamesOptions;
        this.isEditMode = false;
        if (aptitude) {
          this.aptitude = aptitude;
          this.aptitudeName = aptitude.name;
          this.selectedAptitudeGames = aptitude.aptitudeGames.map(
            (el) => el.gameId
          );
          this.isEditMode = aptitude._id ? true : false;

          console.log("Fetched aptitude game list: ", this.aptitudeGamesList);
        }

        this.addAptitude = function () {
          console.log("Add aptitude called");
          this.selectedAptitudeGames.push("");
        };

        this.removeAptitude = function (index) {
          this.selectedAptitudeGames = this.selectedAptitudeGames.filter(
            (el, i) => i !== index
          );
        };

        this.submitAptitude = function () {
          if (!this.isEditMode) {
            // then add new assessment
            gquestionsCtrl.submitAptitude(
              this.aptitudeName,
              this.selectedAptitudeGames
            );
          } else {
            gquestionsCtrl.submitEditedAptitude(
              aptitude._id,
              this.aptitudeName,
              this.selectedAptitudeGames
            );
          }
        };
      },
      controllerAs: "$ctrl",
    });
  }

  deleteAptitude(_id) {
    this.$http(
      requestConfigV2("DELETE", { _id }, "aptitude/game-list", 2)
    ).then((resp) => {
      console.log(resp);
      this.getAddedAptitudes();
    });
  }

  submitAptitude(name, aptitudeGamesList) {
    const aptitudeGames = aptitudeGamesList.map((gameId, i) => ({
      gameId,
      order: i + 1,
    }));

    this.$http(
      requestConfigV2("ADD", { name, aptitudeGames }, "aptitude/game-list", 2)
    )
      .then((resp) => {
        console.log(resp);
        console.log(this.modalInstance);
        this.modalInstance.close();
        this.getAddedAptitudes();
      })
      .catch((err) => {
        console.log(err);
        alert(err.data.message);
      });
  }

  submitEditedAptitude(_id, name, aptitudeGamesList) {
    const aptitudeGames = aptitudeGamesList.map((gameId, i) => ({
      gameId,
      order: i + 1,
    }));

    this.$http(
      requestConfigV2(
        "EDIT",
        { _id, name, aptitudeGames },
        "aptitude/game-list",
        2
      )
    )
      .then((resp) => {
        console.log(resp);

        this.modalInstance.close();
        this.getAddedAptitudes();
      })
      .catch((err) => {
        console.log(err);
        alert(err.data.message);
      });
  }
}

export default angular
  .module("leafApp.gaptitude", [uiRouter])
  .config(routes)
  .component("gaptitude", {
    template: require("./gaptitude.html").default,
    controller: GaptitudeComponent,
    controllerAs: "gaptitudeCtrl",
  }).name;
