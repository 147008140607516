
'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gorderingquestions.routes';
/**
 * @class angular_module.GquestionsComponent
 */
export class GOrderingQuestionsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {

    this.$http = $http;
    this.$uibModal = $uibModal;
    this.orderingQuestionList = [];
    this.departments = [];
  }

  $onInit() {
    this.getAddedorderingQuestion();
    this.getMusics();
    this.getImages();
    this.getDepartments()
  }

  getDepartments() {
    this.$http.get('/api/department').then((res) => {
      this.departments = res.data;
    });
  }

  cloneOrderingQuestion(orderingQuestion) {
    console.log('Clone functionality: ', orderingQuestion);
    this.openModal(orderingQuestion, true);
  }

  editOrderingQuestion(orderingQuestion) {
    console.log({orderingQuestion});
    this.orderingQuestion = orderingQuestion;
    this.openModal(orderingQuestion);
    console.log('Edit questions: ', orderingQuestion);
  }

  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  addOrderingQuestion() {
    this.openModal();
  }

  // get for all the added orderingQuestion game
  getAddedorderingQuestion() {
    this.$http
      .get(`/api/gorderingquestion`, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => (this.orderingQuestionList = res.data
        ));

    return;
  }

  openModal(orderingQuestion, isClone) {
    var gCtrl = this;

    this.modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_orderingQuestion.html',
      size: 'lg',
      controller($uibModalInstance) {
        'ngInject';
        // this is in edit mode
        this.departments = gCtrl.departments;
        this.backgrounds = gCtrl.backgrounds;
        this.musics = gCtrl.musics;
        this.sequenceList = [{
          text: '',
          order: ''
        }];
        this.current = this.sequenceList + 1;

        this.title = '';
        this.questionText = '';
        this.image = '';
        this.music = '';
        this.department = ''
        this.isEditMode = false;
        this.department = ''
        if (orderingQuestion) {
          this.orderingQuestion = orderingQuestion;
          this.title = orderingQuestion.title;
          this.questionText = orderingQuestion.questionText;
          this.image = orderingQuestion.image;
          this.music = orderingQuestion.music;
          this.sequenceList = orderingQuestion.options;
          this.department = orderingQuestion.department;
          this.isEditMode = isClone ? false : true;
        }

        this.addOrderingQuestion = function() {
          console.log('Add orderingQuestion called');
          this.sequenceList.push({
            text: '',
            order: ''
          });
        };

        this.removeOrderingQuestion = function(index) {
          this.sequenceList = this.sequenceList.filter(
            (el, i) => i !== index
          );
        };

        this.submitOrderingQuestion = function() {
          if (this.isEditMode) {
            gCtrl.submitEditOrderingQuestion({
              title: this.title,
              music: this.music  || undefined,
              questionText: this.questionText,
              image: this.image || undefined,
              options: this.sequenceList,
              department: this.department
            }, orderingQuestion._id);
          } else {
            if (!this.title || !this.questionText || !this.sequenceList.every(obj => {
              return obj.text && obj.order;
            })) {
              return;
            }
            gCtrl.submitSendOrderingQuestion({
              title: this.title,
              music: this.music || undefined,
              questionText: this.questionText,
              image: this.image || undefined,
              options: this.sequenceList,
              department: this.department
            });
          }
          // then add new assessment

          //return;
        };
      },
      controllerAs: '$ctrl',
    });
  }

  deleteOrderingQuestion(_id) {
    this.$http
      .delete(`/api/gorderingquestion/${_id}`, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => this.getAddedorderingQuestion());

    return;
  }

  submitSendOrderingQuestion(data) {
    this.$http.post('/api/gorderingquestion/', data, {
      headers: {
        Authorization: `Bearer ${this.getCookie('token')}`,

      }
    }).then(() => {
      this.modalInstance.close();
      this.getAddedorderingQuestion();
    });
  }

  submitEditOrderingQuestion(data, id) {
    this.$http.put(`/api/gorderingquestion/${id}`, data, {
      headers: {
        Authorization: `Bearer ${this.getCookie('token')}`,

      }
    }).then(() => {
      this.modalInstance.close();
      this.getAddedorderingQuestion();
    });
  }

/**
	* @function getMusics
  * @memberOf angular_module.GquestionsComponent
  * @description this function calls get gmusics api to get gmusic data.
	*/
  getMusics() {
    this.$http.get('/api/gmusics')
      .then(response => {
        this.musics = response.data;
      });
  }

  /**
	* @function getImages
  * @memberOf angular_module.GOrderingQuestionComponent
  * @description this function calls get gmusics api to get gmusic data.
	*/
  getImages() {
    this.$http.get('/api/gbackgrounds')
      .then(response => {
        this.backgrounds = response.data;
      });
  }
}

export default angular
  .module('leafApp.gorderingquestions', [uiRouter])
  .config(routes)
  .component('gorderingquestions', {
    template: require('./gorderingquestions.html').default,
    controller: GOrderingQuestionsComponent,
    controllerAs: 'gorderingQuestionCtrl',
  }).name;
