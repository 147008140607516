'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('scatter', {
      url: '/scatter',
      template: '<scatter></scatter>'
    });
}
