'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('alldepartments', {
    url: '/alldepartments',
    template: '<alldepartments></alldepartments>'
  });
}
