'use strict';
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './reportspec.routes';
/**
  * @class angular_module.ReportspecComponent
  */
export class ReportspecComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, $location) {
    this.message = 'Hello';
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$location=$location;

    this.deleteReportSpec= Modal.confirm.delete(report => {
      $http.delete(`/api/reportspecs/${report._id}`).then(() => this.getReportsSpec());
    });

  }

  /**
	* @function $onInit
  * @memberOf angular_module.ReportspecComponent
  * @fires getReportsSpec
  * @description this function intiates the Reportspec component
	*/
  $onInit() {
    this.getReportsSpec();
  }

  /**
	* @function addReportSpec
  * @memberOf angular_module.ReportspecComponent
  * @fires openModal
  * @description this function open modal for adding report specs
	*/
  addReportSpec() {
    this.openModal({});
  }

  /**
	* @function editReportSpec
  * @memberOf angular_module.ReportspecComponent
  * @fires openModal
  * @param {Object} report - report data
  * @description this function open modal for editing report specs
	*/
  editReportSpec(report) {
    this.openModal(report);
  }

  /**
	* @function getReportsSpec
  * @memberOf angular_module.ReportspecComponent
  * @description this function calls reportspecs api to get report specs
	*/
  getReportsSpec() {
    this.$http.get('/api/reportspecs')
    .then(response => {
      this.reportspec = response.data;
    });
  }

  /**
	* @function saveReport
  * @memberOf angular_module.ReportspecComponent
  * @param {Object}company - company data
  * @description this function calls reportspecs api to add report specs
	*/
  saveReport(company){
    return this.$http.post('/api/reportspecs', company).then(() => this.getReportsSpec());
  }

  /**
	* @function updateAttr
  * @memberOf angular_module.ReportspecComponent
  * @param {Object}attr - attr data
  * @description this function calls reportspecs api to update report specs
	*/
  updateAttr(attr) {
    return this.$http.put(`/api/reportspecs/${attr._id}`, attr);
  }

  /**
	* @function openModal
  * @memberOf angular_module.ReportspecComponent
  * @param {Object} report - report data
  * @description this function will open modal for adding or editing report spec.
	*/
  openModal(report) {
    var reportspecCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_reportspec.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        this.ReportSpec = report;
        this.commitReportSpec = function(l) {
          var promise;
          if (l._id) {
            promise = reportspecCtrl.updateAttr(l);
          } else {
            promise = reportspecCtrl.saveReport(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}

export default angular.module('leafApp.reportspec', [uiRouter])
  .config(routes)
  .component('reportspec', {
    template: require('./reportspec.html').default,
    controller: ReportspecComponent,
    controllerAs: 'reportspecCtrl'
  })
  .name;
