'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('keva', {
      url: '/keva',
      template: '<kevatab></kevatab>',
      authenticate: 'admin'
    });
}
