'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gquestions.routes';
 /**
  * @class angular_module.GquestionsComponent
  */
export class GquestionsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteQuestion = Modal.confirm.delete(question => {
      $http.delete(`/api/gquestions/${question._id}`).then(() => this.getQuestions());
    });
  }

  /**
	* @function getQuestions
  * @memberOf angular_module.GquestionsComponent
  * @description this function calls get gquestions api to get gquestions data.
	*/
  getQuestions() {
    this.$http.get('/api/gquestions')
      .then(response => {
        this.questions = response.data;
      });
  }

  /**
	* @function getMusics
  * @memberOf angular_module.GquestionsComponent
  * @description this function calls get gmusics api to get gmusic data.
	*/
  getMusics() {
    this.$http.get('/api/gmusics')
      .then(response => {
        this.musics = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.GquestionsComponent
  * @fires getMusics
  * @description this function intiates the gquestions component
	*/
  $onInit() {
    this.getQuestions();
    this.$http.get('/api/gbackgrounds')
      .then(response => {
        this.backgrounds = response.data;
      });
    this.$http.get('/api/gsequences')
      .then(response => {
        this.sequences = response.data;
      });
    this.$http.get('/api/psychos')
      .then(response => {
        this.attributes = response.data;
      });
    this.getMusics();
  }

  /**
	* @function saveQuestion
  * @memberOf angular_module.GquestionsComponent
  * @param {Object} question - question data
  * @description this function calls add gquestions api to add gquestion.
	*/
  saveQuestion(question) {
    return this.$http.post('/api/gquestions', question).then(() => this.getQuestions());
  }

  /**
	* @function updateQuestion
  * @memberOf angular_module.GquestionsComponent
  * @param {Object} question - question data
  * @description this function calls update gquestions api to update gquestion.
	*/
  updateQuestion(question) {
    return this.$http.put(`/api/gquestions/${question._id}`, question);
  }

  /**
	* @function openModal
  * @memberOf angular_module.GquestionsComponent
  * @param {Object} question - question data
  * @description this function will open modal for adding or editing gquestion.
	*/
  openModal(question) {
    var gquestionsCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_question.html',
      size: 'lg',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.backgrounds = angular.copy(gquestionsCtrl.backgrounds);
        this.sequences = angular.copy(gquestionsCtrl.sequences);
        this.attributes = angular.copy(gquestionsCtrl.attributes);
        this.question = question;
        this.musics = angular.copy(gquestionsCtrl.musics);
        this.question.options = this.question.options || [];

        this.buildOptions = function() {
          this.options_scoring = [];
          this.question.options.forEach((option, index) => {
            this.options_scoring[index] = {};
            option.scoring.forEach(score => {
              this.options_scoring[index][score.attribute] = score.value;
            });
          });
        };

        this.rebuildOptions = function() {
          this.question.options.forEach((option, index) => {
            option.scoring = [];
            this.options_scoring[index] && Object.keys(this.options_scoring[index]).forEach((attr) => {
              // don't include if not in attributes
              gquestionsCtrl.attributes.forEach((attribute) => {
                if (attribute._id == attr) {
                  option.scoring.push({ attribute: attr, value: this.options_scoring[index][attr] });
                }
              });
            });
          });
        };

        this.buildOptions();

        this.scores = [0, 1, 2, 3, 4, 5];

        this.commitQuestion = function(l) {
          this.rebuildOptions();
          var promise;
          if (l._id) {
            promise = gquestionsCtrl.updateQuestion(l);
          } else {
            promise = gquestionsCtrl.saveQuestion(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        };

        this.addOption = function() {
          if (!this.question.options) {
            this.question.options = [];
          }
          this.question.options.push({ scoring: [] });
        };

        this.deleteOption = function(index) {
          this.question.options.splice(index, 1);
        };
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addQuestion
  * @memberOf angular_module.GquestionsComponent
  * @fires openModal
  * @description this function is used to add gquestion data.
	*/
  addQuestion() {
    this.openModal({});
  }

  /**
	* @function editQuestion
  * @memberOf angular_module.GquestionsComponent
  * @fires openModal
  * @param {Object} question - question data
  * @description this function is used to edit gquestion data.
	*/
  editQuestion(question) {
    this.openModal(question);
  }
  /**
	* @function cloneQuestion
  * @memberOf angular_module.GquestionsComponent
  * @fires openModal
  * @param {Object} question - question data
  * @description this function is used to clone gquestion data.
	*/
  cloneQuestion(question) {
    let clonequestionData={}
    if(typeof question.name !== undefined)
      clonequestionData.name = question.name
    if(typeof question.background !== undefined)
      clonequestionData.background = question.background
    if(typeof question.options !== undefined)
      clonequestionData.options = question.options
    if(typeof question.active !== undefined)
      clonequestionData.active = question.active
    if(typeof question.music_id !== undefined)
      clonequestionData.music_id = question.music_id
    if(typeof question.music_volume !== undefined)
      clonequestionData.music_volume = question.music_volume
    this.openModal(clonequestionData);
  }
}

export default angular.module('leafApp.gquestions', [uiRouter])
  .config(routes)
  .component('gquestions', {
    template: require('./gquestions.html').default,
    controller: GquestionsComponent,
    controllerAs: 'gquestionsCtrl'
  })
  .name;
