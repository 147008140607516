'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('abgcandidate', {
      url: '/abgcandidate',
      template: '<actab></actab>',
      authenticate: 'admin'
    });
}
