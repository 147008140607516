'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('success_factors', {
      url: '/success_factors',
      template: '<success-factors></success-factors>'
    });
}
