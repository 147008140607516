'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('companysections', {
    url: '/companysections',
    template: '<companysections></companysections>'
  })
}
