'use strict';
const angular = require('angular');

import uiRouter from 'angular-ui-router';

import { requestConfigV2 } from '../company.component';
import routes from './companydepartments.routes';
/**
 * @class angular_module.CompanyDepartmentComponent
 */
export class CompanyDepartmentsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;
    this.company_name = '';
    this.isCompanyNameEditMode = false;
    this.company = '';
    this.departments = [];
    this.companydepartments = [];
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompFrameworkComponent
   * @fires getCompframeworks
   * @description this function intiates the CompFramework Component.
   */
  $onInit() {
    this.getCompany();
    this.getDepartments();
  }

  getCompany() {
    this.$http.get('/api/company/' + sessionStorage.company_id).then(
      (res) => {
        this.company = res.data;
      },
      (err) => {
        this.$location.path('/company');
      }
    );
  }
  getDepartments() {
    this.$http.get('/api/department').then((res) => {
      this.departments = res.data;
    });
  }

  addDepartment() {
    this.openModal('Add', '');
  }

  saveCompDepartment(data) {
    return this.$http.put('/api/company/' + this.company._id, {departments: data});
  }


  openModal(type, currentSectionName) {
    // var compframeworksCtrl = this;
    var companydepartmentsCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_companysection.html',
      size: 'md',
      controller($uibModalInstance) {
        'ngInject';

        this.departments = companydepartmentsCtrl.departments;

        this.selected = [...companydepartmentsCtrl.company.departments.map((item) => item._id)];

        this.addDept = function(id) {
          if (this.selected.includes(id)) {
            this.selected = this.selected.filter((item) => item !== id);
          } else {
            this.selected.push(id);
          }
        };
        this.modelType = type;
        this.isChecked = function(id) {
          return this.selected.includes(id);
        };
        this.commitSave = function() {
          companydepartmentsCtrl.saveCompDepartment(this.selected).then(
          () => {
            $uibModalInstance.dismiss('cancel');
            companydepartmentsCtrl.getCompany();
          },
          (err) => {
            this.error = err.statusText;
          }
        );
        };
      },
      controllerAs: '$ctrl',
    });
  }
}

export default angular
  .module('leafApp.companydepartments', [uiRouter])
  .config(routes)
  .component('companydepartments', {
    template: require('./companydepartments.html').default,
    controller: CompanyDepartmentsComponent,
    controllerAs: 'companydepartmentsCtrl',
  }).name;
