'use strict';
import angular from 'angular';
const _ = require('lodash');
import uiRouter from 'angular-ui-router';

import routes from './abgcandidate.routes';
/**
  * @class angular_module.abgcandidateComponent
  */
export class abgcandidateComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$uibModal = $uibModal;
          this.pageSize = 50;
          this.showAll = false;
          this.scores = [];
          this.candidates = [];
          this.candidateCount = 0;
          this.hideToggleButtonOption = false;
          this.deletecandidate = Modal.confirm.delete(candidate => {
               $http.delete(`/abg_api/candidate/${candidate._id}`).then((response) => {
                    _.remove(this.candidates , {_id:candidate._id})
                    this.getcandidates(1);
               })
          });
     }
     respondWithResult(res, statusCode) {
          statusCode = statusCode || 200;
          return function (entity) {
               if (entity) {
                    return res.status(statusCode).json(entity);
               }
               return null;
          };
     }

     /**
	* @function getcandidates
     * @memberOf angular_module.abgcandidateComponent
     * @param {Number}itemCount - candidate count
     * @description this function calls abg candidates api to get abg candidates.
	*/
     getcandidates(itemCount) {
          
          this.$http.get('/abg_api/candidate/' + itemCount)
               .then(response => {
                    this.refresh = false;
                    // this.candidates = response.data
                    this.candidates = _.concat(this.candidates, response.data.candidates);
                    this.candidateCount = response.data.candidateCount;
                    if (response.data.candidates.length >= 50) {
                         this.hideToggleButtonOption = true;
                    } else {
                         this.hideToggleButtonOption = false;
                    }
               });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.abgcandidateComponent
     * @fires getcandidates
     * @description this function intiates the abgcandidate component.
	*/
     $onInit() {
          this.getcandidates(50);
     }

     /**
	* @function showToggle
     * @memberOf angular_module.abgcandidateComponent
     * @fires getcandidates
     * @description this function is used to showToggle.
	*/
     showToggle() {
          this.refresh = true;
          this.pageSize += 50;
          this.getcandidates(this.pageSize);
     }
}

export default angular.module('leafApp.abgcandidate', [uiRouter])
     .config(routes)
     .component('actab', {
          template: require('./abgcandidate.html').default,
          controller: abgcandidateComponent,
          controllerAs: 'abgcandidateCtrl'
     })
     .name;