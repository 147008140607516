'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './successfactorvideos.routes';

export class SuccessFactorVideosComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,SuccessFactorService,$location) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.SuccessFactorService=SuccessFactorService;
    this.$location=$location;
    this.videosDeletedArr=[];
    this.deleteVideo = Modal.confirm.delete(video => {
     for( var index = 0; index < this.successfactor.videos.length; index++){ 
      if ( this.successfactor.videos[index]._id === video._id) {
        this.successfactor.videos.splice(index, 1); 
      }
     }
      return this.$http.put(`/api/success_factors/${this.successfactor._id}`, this.successfactor).then(() =>{
          this.$onInit();
      });
    });
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  getFactor(success_factor_id) {
    var thisVar=this;
    this.$http.get(`/api/success_factors/${success_factor_id}`)
      .then(response => {
        this.successfactor = response.data[0]?response.data[0]:response.data;
        if(this.successfactor.videos){
          //to handle solf deleted video (don't show soft deleted video - if node version update then change it with datebase query)
          var unDeletedVideos=[];
          this.successfactor.videos.forEach(video => {
             if(!video.isDeleted){
              unDeletedVideos.push(video);
             }else{
               this.videosDeletedArr.push(video);
             }
          });
          this.successfactor.videos=unDeletedVideos;
        }
        
        
      });
  }

  $onInit() {
    this.allowoperation=false;
    this.videosDeletedArr=[];
    var success_factor_id;
    var reloadPage=false;
    this.SuccessFactorService.getSuccessFactorData(function(res){
        success_factor_id=res._id;
        reloadPage=res.reload;
    });
    if(reloadPage){
      this.$location.path('/success_factors');
    }
    if(success_factor_id==undefined||success_factor_id==""){
        this.successfactor={};
        this.allowoperation=true;
    }
    else {
        this.getFactor(success_factor_id);
    }
  }
  openModal(videos){
    var videoCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_video.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        if (!videos._id) {
          this.video = {};
        } else {
          this.video = videos;
        }

        this.commitVideo = function(l) {
          var promise;
          if (l._id) {
            promise = videoCtrl.updateVideo(l);
          } else {
            promise = videoCtrl.saveVideo(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
  saveVideo(video) {
    if(!this.successfactor.videos){
      this.successfactor.videos=new Array();
    }
     this.successfactor.videos.push(video);
     this.videosDeletedArr.forEach(element => {
      this.successfactor.videos.push(element);
     });
     return this.$http.put('/api/success_factors/'+this.successfactor._id, this.successfactor).then(() =>{
       this.$onInit();
     });
   }
 
   updateVideo(video) {
    this.successfactor.videos.forEach(element => {
        if(element._id==video._id){
            element=video;
        }
     });
     this.videosDeletedArr.forEach(element => {
      this.successfactor.videos.push(element);
     });
     return this.$http.put(`/api/success_factors/${this.successfactor._id}`, this.successfactor).then(() =>{
      this.$onInit();
    });
   }
   addVideo() {
    this.openModal({});
    }

  editVideo(video) {
    this.openModal(video);
  }
}

export default angular.module('leafMainApp.successfactorvideos', [uiRouter])
  .config(routes)
  .component('successFactorVideos', {
    template: require('./successfactorvideos.html').default,
    controller: SuccessFactorVideosComponent,
    controllerAs: 'successFactorVideosCtrl'
  })
  .name;
