'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gmusic.routes';

 /**
  * @class angular_module.GmusicComponent
  */
export class GmusicComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteMusic = Modal.confirm.delete(music => {
      $http.delete(`/api/gmusics/${music._id}`).then(() => this.getMusics());
    });
  }

  /**
	* @function getMusics
  * @memberOf angular_module.GmusicComponent
  * @description this function calls get gmusics api to get gmusic data.
	*/
  getMusics() {
    this.$http.get('/api/gmusics')
      .then(response => {
        this.gmusics = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.GmusicComponent
  * @fires getMusics
  * @description this function intiates the gmusics component
	*/
  $onInit() {
    this.getMusics();
  }

  /**
	* @function saveMusic
  * @memberOf angular_module.GmusicComponent
  * @param {Object} music - music data
  * @description this function calls add gmusics api to add gmusic.
	*/
  saveMusic(music) {
    return this.$http.post('/api/gmusics', music).then(() => this.getMusics());
  }

  /**
	* @function updateMusic
  * @memberOf angular_module.GmusicComponent
  * @param {Object} music - music data
  * @description this function calls update gmusics api to update gmusic.
	*/
  updateMusic(music) {
    return this.$http.put(`/api/gmusics/${music._id}`, music);
  }

  /**
	* @function openModal
  * @memberOf angular_module.GmusicComponent
  * @param {Object} music - music data
  * @description this function will open modal for adding or editing gmusic.
	*/
  openModal(music) {
    var gminigamesCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_minigame.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.gmusics = gminigamesCtrl.gmusics;
        this.music = music;

        this.commitMusic = function(l) {
          var promise;
          if (l._id) {
            promise = gminigamesCtrl.updateMusic(l);
          } else {
            promise = gminigamesCtrl.saveMusic(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addMusic
  * @memberOf angular_module.GmusicComponent
  * @fires openModal
  * @description this function is used to add gmusic data.
	*/
  addMusic() {
    this.openModal({});
  }

  /**
	* @function editMusic
  * @memberOf angular_module.GmusicComponent
  * @fires openModal
  * @param {Object} music - music data
  * @description this function is used to edit gmusic data.
	*/
  editMusic(music) {
    this.openModal(music);
  }
}

export default angular.module('leafApp.gmusic', [uiRouter])
  .config(routes)
  .component('gmusic', {
    template: require('./gmusic.html').default,
    controller: GmusicComponent,
    controllerAs: 'gmusicCtrl'
  })
  .name;
