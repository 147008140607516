'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './derailers.routes';
/**
  * @class angular_module.DerailersComponent
  */
export class DerailersComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.deleteDerailer = Modal.confirm.delete(derailer => {
      $http.delete(`/api/derailers/${derailer._id}`).then(() => this.getDerailer());
    });
  }
  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function getDerailer
  * @memberOf angular_module.DerailersComponent
  * @description this function calls derailers api to get derailers.
	*/
  getDerailer() {
    this.$http.get('/api/derailers')
      .then(response => {
        this.derailers = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.DerailersComponent
  * @fires getDerailer
  * @description this function intiates the Derailers Component
	*/
  $onInit() {
    this.$http.get('/api/psychos')
      .then(response => {
        this.attrs = this.arrayToMap(response.data);
      });
    this.getDerailer();
  }

  /**
	* @function saveDerailer
  * @memberOf angular_module.DerailersComponent
  * @param {Object}derailer - derailer data
  * @description this function calls derailers api to add derailers.
	*/
  saveDerailer(derailer) {
    return this.$http.post('/api/derailers', derailer).then(() => this.getDerailer());
  }

  /**
	* @function updateDerailer
  * @memberOf angular_module.DerailersComponent
  * @param {Object}derailer - derailer data
  * @description this function calls derailers api to update derailers.
	*/
  updateDerailer(derailer) {
    return this.$http.put(`/api/derailers/${derailer._id}`, derailer);
  }

  /**
	* @function openModal
  * @memberOf angular_module.DerailersComponent
  * @param {Object} derailer - derailer data
  * @description this function will open modal for adding or editing derailer.
	*/
  openModal(derailer) {
    var derailersCtrl = this;
    var modalInstance = this.$uibModal.open({
         animation: true,
         templateUrl: 'add_derailer.html',
         size: 'md',
         controller: function ($uibModalInstance) {
              'ngInject';

              this.derailer = derailer;

              if (!derailer._id) {
                   this.derailer.priorities = derailersCtrl.mapToArray(derailersCtrl.attrs);
                   this.derailer.priorities.forEach(element => {
                    element.value="";
                   });
              } else {
                   
                   let keys = Object.keys(derailersCtrl.attrs);
                   this.derailer.priorities.forEach(priority => {
                        let index = keys.indexOf(priority.name);
                        if (index > -1) {
                             keys.splice(index, 1);
                        }
                   });
                   
                   
                   keys.forEach(k => {
                        this.derailer.priorities.push(derailersCtrl.attrs[k]);
                   });
              }

              this.commitDerailer = function(l) {
                
                   var promise;
                   if (l._id) {
                        promise = derailersCtrl.updateDerailer(l);
                   } else {
                        promise = derailersCtrl.saveDerailer(l);
                   }
                   promise.then(() => {
                        $uibModalInstance.dismiss('cancel');
                   }, (err) => {
                        this.error = err.statusText;
                   });
              }
         },
         controllerAs: '$ctrl'
    });
}

  /**
	* @function addDerailer
  * @memberOf angular_module.DerailersComponent
  * @fires openModal
  * @description this function is used to add derailer.
	*/
  addDerailer() {
    this.openModal({});
  }

  /**
	* @function addDerailer
  * @memberOf angular_module.DerailersComponent
  * @param {Object}derailer - derailer data
  * @fires openModal
  * @description this function is used to edit derailer.
	*/
  editDerailer(derailer) {
    this.openModal(derailer);
  }
}

export default angular.module('leafMainApp.derailers', [uiRouter])
  .config(routes)
  .component('derailers', {
    template: require('./derailers.html').default,
    controller: DerailersComponent,
    controllerAs: 'derailersCtrl'
  })
  .name;
