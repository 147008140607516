'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('tvs_motors_private', {
      url: '/tvs_motors_private',
      template: '<tvsmotorsprivatetab></tvsmotorsprivatetab>',
      authenticate: 'admin'
    });
}
