'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('gmusic', {
      url: '/gmusic',
      template: '<gmusic></gmusic>'
    });
}
