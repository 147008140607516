'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('tvs', {
      url: '/tvs',
      template: '<tvstab></tvstab>',
      authenticate: 'admin'
    });
}
