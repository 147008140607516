'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gamedata.routes';
 /**
  * @class angular_module.GamedataComponent
  */
export class GamedataComponent {
     /*@ngInject*/
     constructor($http, $q, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$q = $q;
          this.$uibModal = $uibModal;

          this.deleteGamedata = Modal.confirm.delete(game => {
               $http.delete(`/api/games/${game._id}`).then(() => this.getGames());
          });
     }

     /**
	* @function getGameSequences
     * @memberOf angular_module.GamedataComponent
     * @description this function calls get gsequences api to get gsequences data.
	*/
     getGameSequences() {
          return this.$http.get('/api/gsequences')
          .then(response => {
               let gsequences = response.data;
               gsequences.forEach(gsequence => {
                    gsequence.displayName = gsequence.name;
               });
               this.gsequences = gsequences;
          });
     }

     /**
	* @function getGameQuestions
     * @memberOf angular_module.GamedataComponent
     * @description this function calls get gquestions api to get gquestions data.
	*/
     getGameQuestions() {
          return this.$http.get('/api/gquestions')
          .then(response => {
               let gquestions = response.data;
               gquestions.forEach(gquestion => {
                    gquestion.displayName = gquestion.title;
               });
               this.gquestions = gquestions;
          });
     }

     /**
	* @function getMiniGames
     * @memberOf angular_module.GamedataComponent
     * @description this function calls get gminigames api to get gminigames data.
	*/
     getMiniGames() {
          return this.$http.get('/api/gminigames')
          .then(response => {
               let gminigames = response.data;
               gminigames.forEach(gminigame => {
                    gminigame.displayName = gminigame.name;
               });
               this.gminigames = gminigames;

          });
     }

     /**
	* @function getGames
     * @memberOf angular_module.GamedataComponent
     * @description this function calls get games api to get games data.
	*/
     getGames() {
          this.$http.get('/api/games')
          .then(response => {
               let games = response.data;
               games.forEach(game => {
                    game.displayName = game.name;
               });
               this.games = games;
          });
     }

     /**
	* @function getAttributeBenchmarks
     * @memberOf angular_module.GamedataComponent
     * @description this function calls get attrsbenchmarks api to get attrsbenchmarks data.
	*/
     getAttributeBenchmarks(){
          this.$http.get('/api/attrsbenchmarks')
          .then(response =>{
               this.benchmarks = response.data;
          })
     }

     /**
	* @function $onInit
     * @memberOf angular_module.GamedataComponent
     * @description this function intiates the Gamedata component
	*/
     $onInit() {
          this.$q.all([
               this.getGameSequences(),
               this.getGameQuestions(),
               this.getMiniGames(),
               this.getAttributeBenchmarks()
          ]).then(() => {
               this.getGames();
          }, (err) => {

          });
     }

     /**
	* @function saveGamedata
     * @memberOf angular_module.GamedataComponent
     * @param {Object} game - game data
     * @description this function calls add games api to add game.
	*/
     saveGamedata(game) {
          return this.$http.post('/api/games', game).then(() => this.getGames());
     }

     /**
	* @function updateGamedata
     * @memberOf angular_module.GamedataComponent
     * @param {Object} game - game data
     * @description this function calls update games api to update game.
	*/
     updateGamedata(game) {
          return this.$http.put(`/api/games/${game._id}`, game);
     }

     /**
	* @function openModal
     * @memberOf angular_module.GamedataComponent
     * @param {Object} game - game data
     * @description this function will open modal for adding or editing game.
	*/
     openModal(game) {
          var gameCtrl = this;
          var modalInstance = this.$uibModal.open({
               animation: true,
               templateUrl: 'add_game.html',
               size: 'lg',
               controller: function ($uibModalInstance) {
                    'ngInject';

                    this.game = game;
                    this.game.benchmarkList = gameCtrl.benchmarks;
                    this.gameList = gameCtrl.games;
                    this.game.language = game.language || "English";
                    this.game.main_game = game.main_game || "";
                    this.game.game_version = game.game_version || "1";

                    if (!this.game.sequences || this.game.sequences.length === 0) {
                         this.game.sequences = [{}];
                    }

                    this.addAfter = function(index) {
                         game.sequences.splice(index + 1, 0, {});
                    };

                    this.removeSequence = function(index) {
                         game.sequences.splice(index, 1);
                    };

                    this.applySequences = function(category, index) {
                         game.sequences[index].options = gameCtrl[category];
                    };

                    this.removeSequenceOptions = function(game) {
                         if (!game || !game.sequences) {
                              return;
                         }
                         game.sequences = game.sequences.map(sq => {
                              delete sq.options;
                              return sq;
                         });
                    };

                    this.commitGamedata = function(l) {
                         var promise;
                         this.removeSequenceOptions(l);
                         if (l._id) {
                              promise = gameCtrl.updateGamedata(l);
                         } else {
                              promise = gameCtrl.saveGamedata(l);
                         }
                         promise.then(() => {
                              $uibModalInstance.dismiss('cancel');
                         }, (err) => {
                              this.error = err.statusText;
                         });
                    };
               },
               controllerAs: '$ctrl'
          });
     }

     /**
	* @function addGamedata
     * @memberOf angular_module.GamedataComponent
     * @fires openModal
     * @description this function is used to add game data.
	*/
     addGamedata() {
          this.openModal({});
     }

     /**
	* @function editGamedata
     * @memberOf angular_module.GamedataComponent
     * @fires openModal
     * @param {Object} game - game data
     * @description this function is used to edit game data.
	*/
     editGamedata(game) {
          this.openModal(game);
     }

     /**
	* @function cloneGamedata
     * @memberOf angular_module.GamedataComponent
     * @fires openModal
     * @param {Object} game - game data
     * @description this function is used to clone game data.
	*/
     cloneGamedata(game) {
          let cloneGamedata = {}
          if(typeof game.sequences !== undefined)
          cloneGamedata.sequences = game.sequences;
          if(typeof game.benchmark_id !== undefined)
          cloneGamedata.benchmark_id = game.benchmark_id;
          if(typeof game.active !== undefined)
          cloneGamedata.active = game.active;
          if(game.language) cloneGamedata.language = game.language;
          if(game.main_game) cloneGamedata.main_game = game.main_game;
          if(game.game_version) cloneGamedata.game_version = game.game_version;

          cloneGamedata.benchmark_id_above_31 = game.benchmark_id_above_31;
          cloneGamedata.benchmark_id_above_40 = game.benchmark_id_above_40;
          this.openModal(cloneGamedata);
     }
}

export default angular.module('leafApp.gamedata', [uiRouter])
.config(routes)
.component('gamedata', {
     template: require('./gamedata.html').default,
     controller: GamedataComponent,
     controllerAs: 'gamedataCtrl'
})
.name;
