'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('learning_development', {
      url: '/learning_development',
      template: '<ld></ld>'
    });
}
