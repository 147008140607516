'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gsjt', {
      url: '/gamedata/gsjt',
      template: '<gsjt></gsjt>'
    });
}
