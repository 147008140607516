'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gcharacters', {
      url: '/gamedata/characters',
      template: '<gcharacters></gcharacters>'
    });
}
