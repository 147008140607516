'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('invite', {
      url: '/invite',
      template: '<invite></invite>'
    });
}
