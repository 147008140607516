'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('testcount', {
    url: '/testcount',
    template: '<testcount></testcount>'
  })
}
