'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('orgcompetency', {
    url: '/orgcompetency',
    template: '<orgcompetency></orgcompetency>'
  })
}
