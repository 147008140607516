'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './motivation.routes';
/**
  * @class angular_module.MotivationComponent
  */
export class MotivationComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, $location) {
    this.message = 'Hello';
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$location=$location;
    this.deletemotivationData= Modal.confirm.delete(report => {
      //

      $http.delete(`/api/motivation/${report._id}`).then(() => this.getmotivationData());
    });
}

/**
	* @function $onInit
  * @memberOf angular_module.MotivationComponent
  * @fires getmotivationData
  * @fires psychoAttributeData
  * @description this function intiates the Motivation Component
	*/
$onInit() {
 this.getmotivationData();    
 this.psychoAttributeData(); 
}

/**
	* @function getmotivationData
  * @memberOf angular_module.MotivationComponent
  * @description this function calls motivation api to get key drivers.
	*/
getmotivationData() {
this.$http.get('/api/motivation').then(response => {
  //
  this.motivationDatas = response.data;   
});
}

/**
	* @function psychoAttributeData
  * @memberOf angular_module.MotivationComponent
  * @description this function calls psychos api to get psychos.
	*/
psychoAttributeData()
{
  this.$http.get('/api/psychos')
  .then(response => {
    //
    this.attrs = response.data;
    //
  });
}

/**
	* @function addNew
  * @memberOf angular_module.MotivationComponent
  * @fires openModal
  * @description this function is used to add key driver.
	*/
addNew() {
 this.openModal({})
}

/**
	* @function saveReport
  * @memberOf angular_module.MotivationComponent
  * @description this function calls motivation api to add key driver.
	*/
saveReport(company){
 return this.$http.post('/api/motivation', company).then((response) => this.getmotivationData());
}

/**
	* @function updateAttr
  * @memberOf angular_module.MotivationComponent
  * @param {Object}attr -attr data
  * @description this function calls motivation api to add key driver.
	*/
updateAttr(attr) {
 return this.$http.put(`/api/motivation/${attr._id}`, attr);
}

/**
	* @function editmotivationData
  * @memberOf angular_module.MotivationComponent
  * @param {Object}motivationData -motivationData data
  * @description this function is used to edit key driver
	*/
editmotivationData(motivationData) {
this.openModal(motivationData);
}

/**
	* @function openModal
  * @memberOf angular_module.MotivationComponent
  * @param {Object} motivation - motivation data
  * @description this function will open modal for adding or editing key driver.
	*/
openModal(motivation) {
var motivationCtrl = this;
var modalInstance = this.$uibModal.open({
  animation: true,
  templateUrl: 'add_New.html',
  size: 'md',
  controller: function ($uibModalInstance) {
    'ngInject';
    this.motivation = motivation;
    this.attrs = motivationCtrl.attrs
    //

    this.commitmotivationData = function(ModalData) {
      //
      var promise;
      if (ModalData._id) {
        promise = motivationCtrl.updateAttr(ModalData);
      } else { 
        promise = motivationCtrl.saveReport(ModalData);
      }
      promise.then(() => {
        $uibModalInstance.dismiss('cancel');
      }, (err) => {
        this.error = err.statusText;
      });       
    }
  },
  controllerAs: '$ctrl'
});
}
}

export default angular.module('leafApp.motivation', [uiRouter])
  .config(routes)
  .component('motivation', {
    template: require('./motivation.html').default,
    controller: MotivationComponent,
    controllerAs: 'motivationCtrl'
  })
  .name;
