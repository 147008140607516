'use strict';
import angular from 'angular';

/*@ngInject*/
export function OrgCompetencyService() {
  let orgcompetencyData = {
    _id : "",
    company_id : ""
  }
  return {
    setOrgcompetencyData : setOrgcompetencyData,
    getOrgcompetencyData : getOrgcompetencyData
  }
  function setOrgcompetencyData(orgcompetency,cb){
    orgcompetencyData["_id"]=orgcompetency._id;
    orgcompetencyData["company_id"]=orgcompetency.company_id;
    return cb(orgcompetencyData);
  }
  function getOrgcompetencyData(cb) {
    return cb(orgcompetencyData);
  }
  // AngularJS will instantiate a singleton by calling "new" on this function
}

export default angular.module('leafApp.OrgCompetencyService', [])
.service('OrgCompetencyService', OrgCompetencyService)
.name;
