'use strict';

export default function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('jrt', {
      url: '/jrt',
      template: '<jrt></jrt>'
    });
}
