'use strict';
const angular = require('angular');

import uiRouter from 'angular-ui-router';

// import { requestConfigV2 } from '../company/company.component';
import routes from './alldepartments.routes';
/**
 * @class angular_module.CompanyDepartmentComponent
 */
export class AllDepartmentsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$location = $location;
    this.departments = [];
  }

  $onInit() {
    this.getDepartments();
  }

  getDepartments() {
    this.$http.get('/api/department').then((res) => {
      this.departments = res.data;
    });
  }

  deleteDepartment(dept) {
    this.$http.delete('/api/department/' + dept._id).then(() => {
      this.getDepartments();
    });
  }

  editDepartment(dept) {
    this.openModal('Edit', dept);
  }
  addDepartment() {
    this.openModal('Add', '');
  }

  saveDepartment(payload) {
    const data = this.$http.post('/api/department', payload);

    return data;
  }

  saveUpdateDepartment(payload, dept) {
    const data = this.$http.put('/api/department/' + dept._id, payload);

    return data;
  }

  openModal(type, currentDept) {
    // var compframeworksCtrl = this;
    var alldepartmentsCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_companysection.html',
      size: 'md',
      controller($uibModalInstance) {
        'ngInject';
        this.benchmarks = currentDept && currentDept.benchmarks && currentDept.benchmarks.length > 0 ? currentDept.benchmarks : [
          {
            order: 1,
            benchmarkValue: null,
          },
          {
            order: 2,
            benchmarkValue: null,
          },
          {
            order: 3,
            benchmarkValue: null,
          },
          {
            order: 4,
            benchmarkValue: null,
          },
          {
            order: 5,
            benchmarkValue: null,
          },
          {
            order: 6,
            benchmarkValue: null,
          },
          {
            order: 7,
            benchmarkValue: null,
          },
          {
            order: 8,
            benchmarkValue: null,
          },
          {
            order: 9,
            benchmarkValue: null,
          },
          {
            order: 10,
            benchmarkValue: null,
          },
        ];
        this.modelType = type;
        this.name = currentDept ? currentDept.name : '';
        this.reportTitle = currentDept ? currentDept.reportTitle : '';
        this.topicsAssessed = currentDept ? currentDept.topicsAssessed : '';
        this.commitCompSection = function() {
          if (type === 'Edit') {
            alldepartmentsCtrl
              .saveUpdateDepartment({
                name: this.name,
                benchmarks: this.benchmarks,
                reportTitle: this.reportTitle,
                topicsAssessed: this.topicsAssessed,
              }, currentDept)
              .then(
                () => {
                  alldepartmentsCtrl.getDepartments();
                  $uibModalInstance.dismiss('cancel');
                },
                (err) => {
                  this.error = err.statusText;
                }
              );
          } else {
            alldepartmentsCtrl
              .saveDepartment({
                name: this.name,
                reportTitle: this.reportTitle,
                topicsAssessed: this.topicsAssessed,
                benchmarks: this.benchmarks
              })
              .then(
                () => {
                  alldepartmentsCtrl.getDepartments();
                  $uibModalInstance.dismiss('cancel');
                },
                (err) => {
                  this.error = err.statusText;
                }
              );
          }
        };
      },
      controllerAs: '$ctrl',
    });
  }
}

export default angular
  .module('leafApp.alldepartments', [uiRouter])
  .config(routes)
  .component('alldepartments', {
    template: require('./alldepartments.html').default,
    controller: AllDepartmentsComponent,
    controllerAs: 'alldepartmentsCtrl',
  }).name;
