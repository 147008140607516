'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('bhired_candidate', {
      url: '/bhired_candidate',
      template: '<bctab></bctab>',
      authenticate: 'admin'
    });
}
