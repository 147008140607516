"use strict";
import angular from "angular";
const _ = require("lodash");
import uiRouter from "angular-ui-router";

import routes from "./tvs.routes";
import "./tvs.css";
class TvsComponent {
  /*@ngInject*/
  constructor($http) {
    this.$http = $http;
    this.assessments = [];
    this.isUpdating = false;
    this.success = false;
    this.error = false;
  }

  $onInit() {
    this.getAssessments();
  }

  getAssessments() {
    this.$http
      .get("/api/assessment/641c0a74bc13fe001122dffc")
      .then((response) => {
        this.assessments = Object.values(response.data.data)
          .flat()
          .sort((a, b) => {
            const nameA = a.job_title.toUpperCase();
            const nameB = b.job_title.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
      });
  }

  updateBehaviour(id, isBehavioural) {
    this.isUpdating = true;
    this.success = false;
    this.error = false;
    this.$http
      .put(`/api/assessment/${id}`, { isBehavioralOff: !isBehavioural })
      .then(() => {
        this.isUpdating = false;
        this.success = "assessment updated successfully";
      })
      .catch(() => {
        this.isUpdating = false;
        this.error = "error updating assessment";
      });
  }

  download(id) {
    this.error = "";
    this.$http
      .get(`/api/candidates/valuesPlayer/list/downloadValuesQuestionwise/excel-report/${id}`, {
        responseType: "blob",
      })
      .then((response) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result.includes("assessment not attached")) {
            this.error = reader.result;
          } else {
            this.downloadPdf(response);
          }
        };
        reader.readAsText(response.data);
      })
      .catch(() => {
        this.error = "error downloading assessment";
      });
  }

  async downloadPdf(response) {
    try {
      const blob = response.data;
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = "tvs-assessment-report.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  }
}

export default angular
  .module("leafApp.tvs", [uiRouter])
  .config(routes)
  .component("tvstab", {
    template: require("./tvs.html").default,
    controller: TvsComponent,
    controllerAs: "tvsCtrl",
  }).name;
