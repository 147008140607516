'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './successfactorupdate.routes';

export class SuccessFactorUpdateComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,SuccessFactorService,$location) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.SuccessFactorService=SuccessFactorService;
    this.$location=$location;
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  getFactor(success_factor_id) {
    this.$http.get(`/api/success_factors/${success_factor_id}`)
      .then(response => {
        this.successfactor = response.data[0]?response.data[0]:response.data;
      });
  }

  $onInit() {
    var success_factor_id;
    var thisVar=this;
    var refreshPage=false;
    this.SuccessFactorService.getSuccessFactorData(function(res){
        success_factor_id=res._id;
        refreshPage=res.reload;
        if(success_factor_id==undefined||success_factor_id==""){
          //thisVar.$location.path("/success_factors");
        }
        else {
          thisVar.getFactor(success_factor_id);
        }
    });
    if(refreshPage){
      this.$location.path('/success_factors');
    }
  }
  commitFactor(l){
    if (l._id!=undefined) {
     this.updateFactor(l);
    } else {
      this.saveFactor(l);
    }
   
  }
  saveFactor(successfactor) {
    var thisVar=this;
    return this.$http.post('/api/success_factors', successfactor).then((result) => {
      this.SuccessFactorService.setSuccessFactorData(result.data._id,function(res){
        thisVar.$onInit();
      })
      this.success=' successfully saved';
      
    });
    
  }

  updateFactor(successfactor) {
    return this.$http.put(`/api/success_factors/${successfactor._id}`, successfactor).then((result)=>{
      this.success=' successfully updated';
    
    });;
    
  }
}

export default angular.module('leafMainApp.success_factor_update', [uiRouter])
  .config(routes)
  .component('successFactorUpdate', {
    template: require('./successfactorupdate.html').default,
    controller: SuccessFactorUpdateComponent,
    controllerAs: 'successFactorUpdateCtrl'
  })
  .name;
