'use strict';

export default function($stateProvider) {
  'ngInject';
  
  $stateProvider
    .state('gbackgrounds', {
      url: '/gamedata/backgrounds',
      template: '<gbackgrounds></gbackgrounds>'
    });
}
