'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gsequences2.routes';
/**
 * @class angular_module.GquestionsComponent
 */
export class GSequences2Component {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.selectedAptitudeGames = [''];
    this.selectedAptitudeGamesOriginal = [''];
    this.aptitudeGamesOptions = [];
    this.sequences2List = [];
  }

  $onInit() {
    this.getAddedSequences2();
    this.getMusics();
    this.getImages();
  }

  cloneSequences2(sequences2) {
    this.openModal(sequences2, true);
  }

  editSequences2(sequences2) {
    console.log({ sequences2 });
    this.sequences2 = sequences2;
    this.openModal(sequences2);
    console.log('Edit questions: ', sequences2);
  }

  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  addSequences2() {
    this.openModal();
  }

  // get for all the added sequences2 game
  getAddedSequences2() {
    this.$http
      .get('/api/gsequences2', {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => (this.sequences2List = res.data));

    return;
  }

  // get sequences2 game list like - Sequence, Tower Of hanoi

  openModal(sequences2, isClone) {
    var gCtrl = this;
    this.modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_sequences2.html',
      size: 'lg',
      controller($uibModalInstance) {
        'ngInject';
        // this is in edit mode
        this.backgrounds = gCtrl.backgrounds;
        this.musics = gCtrl.musics;
        this.sequenceList = [
          {
            text: '',
            image: '',
          },
        ];
        this.current = this.sequenceList + 1;

        this.sequences2Name = '';
        this.music = '';
        this.musicVolume = '';
        this.isEditMode = false;
        if (sequences2) {
          this.sequences2 = sequences2;
          this.sequences2Name = sequences2.title;
          this.musicVolume = sequences2.musicVolume;
          this.music = sequences2.music;
          this.sequenceList = sequences2.sequenceData;
          // this.selectedAptitudeGames = sequences2.aptitudeGames.map(
          //   (el) => el.gameId
          // );
          this.isEditMode = isClone ? false : true;
        }

        this.addSequences2 = function () {
          console.log('Add sequences2 called');
          this.sequenceList.push({
            text: '',
            image: '',
          });
        };

        this.removeSequences2 = function (index) {
          this.sequenceList = this.sequenceList.filter((el, i) => i !== index);
        };

        this.submitSequences2 = function () {
          if (this.isEditMode) {
            gCtrl.submitEditSequences2(
              {
                title: this.sequences2Name,
                music: this.music,
                musicVolume: this.musicVolume,
                sequenceData: this.sequenceList,
              },
              sequences2._id,
            );
          } else {
            if (
              !this.sequences2Name ||
              !this.music ||
              !this.musicVolume ||
              !this.sequenceList.every((obj) => {
                return obj.text && obj.image;
              })
            ) {
              return;
            }
            gCtrl.submitSendSequences2({
              title: this.sequences2Name,
              music: this.music,
              musicVolume: this.musicVolume,
              sequenceData: this.sequenceList,
            });
          }
          // then add new assessment

          //return;
        };
      },
      controllerAs: '$ctrl',
    });
  }

  deleteSequences2(_id) {
    this.$http
      .delete(`/api/gsequences2/${_id}`, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then((res) => this.getAddedSequences2());

    return;
  }

  submitSendSequences2(data) {
    this.$http
      .post('/api/gsequences2/', data, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then(() => {
        this.modalInstance.close();
        this.getAddedSequences2();
      });
  }

  submitEditSequences2(data, id) {
    this.$http
      .put(`/api/gsequences2/${id}`, data, {
        headers: {
          Authorization: `Bearer ${this.getCookie('token')}`,
        },
      })
      .then(() => {
        this.modalInstance.close();
        this.getAddedSequences2();
      });
  }

  /**
   * @function getMusics
   * @memberOf angular_module.GquestionsComponent
   * @description this function calls get gmusics api to get gmusic data.
   */
  getMusics() {
    this.$http.get('/api/gmusics').then((response) => {
      this.musics = response.data;
    });
  }

  /**
   * @function getImages
   * @memberOf angular_module.GOrderingQuestionComponent
   * @description this function calls get gmusics api to get gmusic data.
   */
  getImages() {
    this.$http.get('/api/gbackgrounds').then((response) => {
      this.backgrounds = response.data;
    });
  }
}

export default angular
  .module('leafApp.gsequences2', [uiRouter])
  .config(routes)
  .component('gsequences2', {
    template: require('./gsequences2.html').default,
    controller: GSequences2Component,
    controllerAs: 'gsequences2Ctrl',
  }).name;
