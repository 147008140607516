'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './successfactors.routes';
/**
  * @class angular_module.SuccessFactorsComponent
  */
export class SuccessFactorsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,SuccessFactorService,$location) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.SuccessFactorService=SuccessFactorService;
    this.$location=$location;
    this.deleteSuccessFactor = Modal.confirm.delete(successfactor => {
      $http.delete(`/api/success_factors/${successfactor._id}`).then(() => this.getSuccessFactors());
    });
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function getSuccessFactors
  * @memberOf angular_module.SuccessFactorsComponent
  * @description this function calls success_factors api to get success factors.
	*/
  getSuccessFactors() {
    this.$http.get('/api/success_factors')
      .then(response => {
        this.successfactors = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.SuccessFactorsComponent
  * @fires getSuccessFactors
  * @description this function intiates the success factors component.
	*/
  $onInit() {
    this.getSuccessFactors();
  }

  /**
	* @function editSuccessFactor
  * @memberOf angular_module.SuccessFactorsComponent
  * @param {object}successfactor - successfactor data
  * @description this function is used to edit success factor.
	*/
  editSuccessFactor(successfactor) {
    var successCntrl=this;
    this.SuccessFactorService.setSuccessFactorData(successfactor._id,function(res){
      
      successCntrl.$location.path('/success_factor_update');
    })
    
  }

  /**
	* @function addSuccessFactor
  * @memberOf angular_module.SuccessFactorsComponent
  * @description this function is used to add success factor.
	*/
  addSuccessFactor() {
    var successCntrl=this;
    this.SuccessFactorService.setSuccessFactorData('',function(res){
      
      successCntrl.$location.path('/success_factor_update');
    })
    
  }

  /**
	* @function saveSuccessFactor
  * @memberOf angular_module.SuccessFactorsComponent
  * @param {object}successfactor - successfactor data
  * @description this function calls success_factors api to add success factor.
	*/
  saveSuccessFactor(successfactor) {
    return this.$http.post('/api/success_factors', successfactor).then(() => this.getSuccessFactors());
  }

  /**
	* @function updateSuccessFactor
  * @memberOf angular_module.SuccessFactorsComponent
  * @param {object}successfactor - successfactor data
  * @description this function calls success_factors api to add success factor.
	*/
  updateSuccessFactor(successfactor) {
    return this.$http.put(`/api/success_factors/${successfactor._id}`, successfactor);
  }
}
export default angular.module('leafMainApp.success_factors', [uiRouter])
  .config(routes)
  .component('successFactors', {
    template: require('./successfactors.html').default,
    controller: SuccessFactorsComponent,
    controllerAs: 'successFactorsCtrl'
  })
  .name;
