'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './bhired_competency.routes';

export class bhired_candidateComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.getPsychoAttributes();
  }

  getPsychoAttributes(){
    this.$http.get('/api/psychos')
      .then(response => {
        this.psychos = response.data;
      });
  }

  updateBenchmark(psychos) {
    let status = false;
    psychos.forEach(psycho => {
      status=false;
      this.$http.put(`/api/psychos/${psycho._id}`, psycho);
      status=true;
    });
    if(status===true){
      this.success="Benchmark Successfully Updated";
    }
    else{
      this.error="Error in updation"
    }

  }
}

export default angular.module('leafApp.bhired_competency', [uiRouter])
  .config(routes)
  .component('bcompetencytab', {
    template: require('./bhired_competency.html').default,
    controller: bhired_candidateComponent,
    controllerAs: 'bhired_competencyCtrl'
  })
  .name;
