'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gsequences', {
      url: '/gamedata/sequences',
      template: '<gsequences></gsequences>'
    });
}
