'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('companygame', {
    url: '/companygame',
    template: '<companygame></companygame>'
  })
}
