'use strict';
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './tvsreportspec.routes';
/**
  * @class angular_module.TvsReportSpec
  */
export class TvsReportSpec {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, $location) {
    this.message = 'Hello';
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$location = $location;

    this.deleteReportSpec = Modal.confirm.delete(report => {
      console.log({report});
     // $http.delete(`/api/reportspecs/${report._id}`).then(() => this.getReportsSpec());
    });
  }

  $onInit() {
    this.getCompany();
  }

  addReportSpec() {
    this.openModal({});
  }

  editReportSpec(report) {
    this.openModal(report);
  }

  /**
	* @function saveReport
  * @memberOf angular_module.TvsReportSpec
  * @param {Object}company - company data
  * @description this function calls reportspecs api to add report specs
	*/
  saveReport(company) {
    // console.log(company);
    // return;
    return this.$http.put('/api/company/641c0a74bc13fe001122dffc', company).then(() => this.getCompany());
  }

  getCompany() {
    this.$http.get('/api/company/641c0a74bc13fe001122dffc').then((response) => {
      this.company = response.data;
    });
  }

  openModal(report) {
    // eslint-disable-next-line consistent-this
    var reportspecCtrl = this;
    var company = reportspecCtrl.company;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_reportspec.html',
      size: 'md',
      controller($uibModalInstance) {
        'ngInject';
        this.ReportSpec = report;
        this.commitReportSpec = function(l) {
          console.log({l});
          // return
          var promise;

          if (l._id) {
            company.valuesReportSpec = company.valuesReportSpec.map((item) => {
              if (item._id === l._id) {
                item.value = this.ReportSpec.value;
                item.text['Very High'] = this.ReportSpec.text['Very High'];
                item.text['High'] = this.ReportSpec.text['High'];
                item.text['Medium'] = this.ReportSpec.text['Medium'];
                item.text['Low'] = this.ReportSpec.text['Low'];
              }
              return item;
            });
            console.log({company});
            reportspecCtrl.$http.put('/api/company/641c0a74bc13fe001122dffc', company).then(() => {
              $uibModalInstance.dismiss('cancel');
            });
            return;
            // item.value = this.ReportSpec.value;
            // console.log({item});
          } else {
            company.valuesReportSpec = company.valuesReportSpec ? company.valuesReportSpec.concat(report) : [report];
            promise = reportspecCtrl.saveReport(company);
            promise.then(() => {
              $uibModalInstance.dismiss('cancel');
            }, (err) => {
              this.error = err.statusText;
            });
          }
        };
      },
      controllerAs: '$ctrl'
    });
  }


  openDeleteModal(report) {
    console.log({report});
    var reportspec = this;
    var company = reportspec.company;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'delete_reportspec.html',
      size: 'md',
      controller($uibModalInstance) {
        'ngInject';
        this.reportSpec = report;
        this.commitReportSpec = function() {
          company.valuesReportSpec = company.valuesReportSpec.filter((item) => item._id !== report._id);
          reportspec.$http.put('/api/company/641c0a74bc13fe001122dffc', company).then(() => {
            $uibModalInstance.dismiss('cancel');
          });
        };
      },
      controllerAs: '$deleteCtrl'
    });
  }


}

export default angular.module('leafApp.tvsreportspec', [uiRouter])
  .config(routes)
  .component('tvsreportspectab', {
    template: require('./tvsreportspec.html').default,
    controller: TvsReportSpec,
    controllerAs: 'reportspecCtrl'
  })
  .name;
