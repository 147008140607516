'use strict';
import angular from 'angular';

/*@ngInject*/
export function CompanyService() {
  let companyData = {
    _id : "",
    company_name: ""
  }
  return {
    setCompanyData : setCompanyData,
    getCompanyData : getCompanyData
  }
  function setCompanyData(company_id, company_name,cb){
    companyData["_id"]=company_id;
    companyData["company_name"]=company_name;
    return cb(companyData);
  }
  function getCompanyData(cb) {
    return cb(companyData);
  }
  // AngularJS will instantiate a singleton by calling "new" on this function
}

export default angular.module('leafApp.CompanyService', [])
.service('CompanyService', CompanyService)
.name;
