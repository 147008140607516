'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gminigames.routes';
 /**
  * @class angular_module.GminigamesComponent
  */
export class GminigamesComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteMinigame = Modal.confirm.delete(minigame => {
      $http.delete(`/api/gminigames/${minigame._id}`).then(() => this.getMinigames());
    });
  }

  /**
	* @function getMinigames
  * @memberOf angular_module.GminigamesComponent
  * @description this function calls get gminigames api to get gminigames data.
	*/
  getMinigames() {
    this.$http.get('/api/gminigames')
      .then(response => {
        this.minigames = response.data;
      });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.GminigamesComponent
  * @description this function intiates the gminigames component
	*/
  $onInit() {
    this.getMinigames();
    this.$http.get('/api/gsequences')
      .then(response => {
        this.sequences = response.data;
      });
  }

  /**
	* @function saveMinigame
  * @memberOf angular_module.GminigamesComponent
  * @param {Object} minigame - minigame data
  * @description this function calls add gminigames api to add gminigame.
	*/
  saveMinigame(minigame) {
    return this.$http.post('/api/gminigames', minigame).then(() => this.getMinigames());
  }

  /**
	* @function updateMinigame
  * @memberOf angular_module.GminigamesComponent
  * @param {Object} minigame - minigame data
  * @description this function calls update gminigames api to update gminigame.
	*/
  updateMinigame(minigame) {
    return this.$http.put(`/api/gminigames/${minigame._id}`, minigame);
  }

  /**
	* @function openModal
  * @memberOf angular_module.GminigamesComponent
  * @param {Object} minigame - minigame data
  * @description this function will open modal for adding or editing gminigame.
	*/
  openModal(minigame) {
    var gminigamesCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_minigame.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.minigames = angular.copy(gminigamesCtrl.minigames);
        this.sequences = angular.copy(gminigamesCtrl.sequences);
        this.minigame = minigame;

        this.addData = function() {
          this.minigame.data = this.minigame.data || [];
          this.minigame.data.push({});
        };

        this.deleteData = function(index) {
          this.minigame.data.splice(index, 1);
        };

        this.commitMinigame = function(l) {
          var promise;
          if (l._id) {
            promise = gminigamesCtrl.updateMinigame(l);
          } else {
            promise = gminigamesCtrl.saveMinigame(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addMinigame
  * @memberOf angular_module.GminigamesComponent
  * @fires openModal
  * @description this function is used to add gminigame data.
	*/
  addMinigame() {
    this.openModal({});
  }

  /**
	* @function editMinigame
  * @memberOf angular_module.GminigamesComponent
  * @fires openModal
  * @param {Object} minigame - minigame data
  * @description this function is used to update gminigame data.
	*/
  editMinigame(minigame) {
    this.openModal(minigame);
  }
}

export default angular.module('leafApp.gminigames', [uiRouter])
  .config(routes)
  .component('gminigames', {
    template: require('./gminigames.html').default,
    controller: GminigamesComponent,
    controllerAs: 'gminigamesCtrl'
  })
  .name;
