'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gcharacters.routes';
 /**
  * @class angular_module.GcharactersComponent
  */
export class GcharactersComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteCharacter = Modal.confirm.delete(character => {
      $http.delete(`/api/gcharacters/${character._id}`).then(() => this.$onInit());
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.GcharactersComponent
  * @description this function intiates the gcharacters component
	*/
  $onInit() {
      this.$http.get('/api/gcharacters')
      .then(response => {
        this.characters = response.data;
      });
  }

  /**
	* @function saveCharacter
  * @memberOf angular_module.GcharactersComponent
  * @param {Object} character - character data
  * @description this function calls add gcharacters api to add gcharacters.
	*/
  saveCharacter(character) {
    return this.$http.post('/api/gcharacters', character).then(() => this.$onInit());
  }

  /**
	* @function updateCharacter
  * @memberOf angular_module.GcharactersComponent
  * @param {Object} character - character data
  * @description this function calls update gcharacters api to update gcharacters.
	*/
  updateCharacter(character) {
    return this.$http.put(`/api/gcharacters/${character._id}`, character);
  }

  /**
	* @function openModal
  * @memberOf angular_module.GcharactersComponent
  * @param {Object} character - character data
  * @description this function will open modal for adding or editing gcharacter.
	*/
  openModal(character) {
    var gcharactersCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_character.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.characters = angular.copy(gcharactersCtrl.characters);
        this.character = character;

        this.commitCharacter = function(l) {
          var promise;
          if (l._id) {
            promise = gcharactersCtrl.updateCharacter(l);
          } else {
            promise = gcharactersCtrl.saveCharacter(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addCharacter
  * @memberOf angular_module.GcharactersComponent
  * @fires openModal
  * @description this function is used to add gcharacter data.
	*/
  addCharacter() {
    this.openModal({});
  }

  /**
	* @function editCharacter
  * @memberOf angular_module.GcharactersComponent
  * @fires openModal
  * @param {Object} character - character data
  * @description this function is used to update gcharacter data.
	*/
  editCharacter(character) {
    this.openModal(character);
  }
}

export default angular.module('leafApp.gcharacters', [uiRouter])
  .config(routes)
  .component('gcharacters', {
    template: require('./gcharacters.html').default,
    controller: GcharactersComponent,
    controllerAs: 'gcharactersCtrl'
  })
  .name;
