'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('suborgcompetency', {
    url: '/suborgcompetency',
    template: '<suborgcompetency></suborgcompetency>'
  })
}
