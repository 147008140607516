'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('percentilebenchitem', {
      url: '/percentilebenchitem/:id',
      template: '<percentilebenchitem></percentilebenchitem>'
    });
}
