"use strict";
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from "./testcount.routes";
/**
 * @class angular_module.TestCountComponent
 */
export class TestCountComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;
  }

  /**
   * @function $onInit
   * @memberOf angular_module.TestCountComponent
   * @fires getCompany
   * @description this function intiates the TestCount Component.
   */
  $onInit() {
    this.getCompany();
  }

  /**
   * @function getCompany
   * @memberOf angular_module.TestCountComponent
   * @description this function calls company api to get company data.
   */
  getCompany() {
    var company_id;
    const cb = function (res) {
      company_id = res._id;
      this.company_name = res.company_name;
    };

    this.CompanyService.getCompanyData(cb.bind(this));
    if (company_id == "") {
      this.$location.path("/company");
    }
    this.$http.get(`/api/company/${company_id}`).then((response) => {
      this.company = response.data;
    });
  }

  /**
   * @function add
   * @memberOf angular_module.TestCountComponent
   * @param {String} attribute - attribute name
   * @param {Object} company - company name
   * @description this function is used to add company data.
   */
  add(attribute, company) {
    this.openModal(attribute, company);
  }

  /**
   * @function updateCompany
   * @memberOf angular_module.TestCountComponent
   * @param {Object} company - company name
   * @description this function calls company api to update company data.
   */
  updateCompany(company) {
    return this.$http.put(`/api/company/${company._id}`, company);
  }

  /**
   * @function openModal
   * @memberOf angular_module.TestCountComponent
   * @param {String} attribute - attribute name
   * @param {Object} company - company data
   * @description this function open modal for adding or editing company test counts.
   */
  openModal(attribute, company) {
    var testcountCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: "add_testcount.html",
      size: "sm",
      controller: function ($uibModalInstance) {
        "ngInject";
        this.companydata = company;
        this.attribute = attribute;
        this.attributedata = {
          JobFitNNum: "Job Fit Non Managers",
          JobFitMNum: "Job Fit Managers",
          CampusNum: "Campus",
          OrgCompNum: "Org Competencies",
        };
        this.commitCompany = function (l) {
          var promise;
          if (l._id) {
            promise = testcountCtrl.updateCompany(l);
          }
          promise.then(
            () => {
              $uibModalInstance.dismiss("cancel");
            },
            (err) => {
              this.error = err.statusText;
            }
          );
        };
      },
      controllerAs: "$ctrl",
    });
  }
}

export default angular
  .module("leafApp.testcount", [uiRouter])
  .config(routes)
  .component("testcount", {
    template: require("./testcount.html").default,
    controller: TestCountComponent,
    controllerAs: "testcountCtrl",
  }).name;
