'use strict';
import angular from 'angular';
const _ = require('lodash');
import uiRouter from 'angular-ui-router';

import routes from './sonyCandidate.routes';
/**
  * @class angular_module.sonyCandidateComponent
  */
export class sonyCandidateComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$uibModal = $uibModal;
          this.pageSize = 50;
          this.showAll = false;
          this.scores = [];
          this.candidates = [];
          this.hideToggleButtonOption = false;
          this.deletecandidate = Modal.confirm.delete(candidate => {
               $http.delete(`/sony_api/candidate/${candidate._id}`).then((response) => {
                    _.remove(this.candidates , {_id:candidate._id})
                    this.getcandidates(1);
               })
          });
     }
     respondWithResult(res, statusCode) {
          statusCode = statusCode || 200;
          return function (entity) {
               if (entity) {
                    return res.status(statusCode).json(entity);
               }
               return null;
          };
     }

     /**
	* @function getcandidates
     * @memberOf angular_module.sonyCandidateComponent
     * @param {Number}itemCount - candidate count
     * @description this function calls sony candidates api to get sony candidates.
	*/
     getcandidates(itemCount) {
          
          this.$http.get('/sony_api/candidate/' + itemCount + '?onlySony=false')
               .then(response => {
                    
                    this.refresh = false;
                    // this.candidates = response.data
                    this.candidates = _.concat(this.candidates, response.data);
                    if (response.data.length >= 50) {
                         this.hideToggleButtonOption = true;
                    } else {
                         this.hideToggleButtonOption = false;
                    }
               });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.sonyCandidateComponent
     * @fires getcandidates
     * @description this function intiates the sonyCandidate component.
	*/
     $onInit() {
          this.getcandidates(50);
     }

     /**
	* @function showToggle
     * @memberOf angular_module.sonyCandidateComponent
     * @fires getcandidates
     * @description this function is used to showToggle.
	*/
     showToggle() {
          this.refresh = true;
          this.pageSize += 50;
          this.getcandidates(this.pageSize);
     }
}

export default angular.module('leafApp.sonyCandidate', [uiRouter])
     .config(routes)
     .component('sctab', {
          template: require('./sonyCandidate.html').default,
          controller: sonyCandidateComponent,
          controllerAs: 'sonyCandidateCtrl'
     })
     .name;