'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('version', {
      url: '/version',
      template: '<version></version>'
    });
}
