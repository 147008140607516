'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gsequences2', {
      url: '/gamedata/gsequences2',
      template: '<gsequences2></gsequences2>'
    });
}
