'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './compframework.routes';
/**
  * @class angular_module.CompFrameworkComponent
  */
export class CompFrameworkComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location=$location;

    this.deleteOrgcompetency= Modal.confirm.delete(compframework => {
      $http.delete(`/api/compframeworks/${compframework._id}`).then(() => this.getCompframeworks());
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.CompFrameworkComponent
  * @fires getCompframeworks
  * @description this function intiates the CompFramework Component.
	*/
  $onInit() {
    this.getCompframeworks();
  }

  /**
	* @function getCompframeworks
  * @memberOf angular_module.CompFrameworkComponent
  * @description this function calls compframeworks api to get comp frameworks.
	*/
  getCompframeworks() {
    var company_id;
    const cb = function(res){
      company_id=res._id;
      this.company_name=res.company_name;
    }


    this.CompanyService.getCompanyData(cb.bind(this));
    if(company_id==""){
      this.$location.path("/company");
    }
    else {
      this.$http.get(`/api/compframeworks/findAllByCompanyId/${company_id}`)
      .then(response => {
        this.compframeworks = response.data;
      });
    }
  }

  /**
	* @function addCompframework
  * @memberOf angular_module.CompFrameworkComponent
  * @description this function is used to add compframework.
	*/
  addCompframework() {
    this.openModal({});
  }

  /**
	* @function saveCompframework
  * @memberOf angular_module.CompFrameworkComponent
  * @param {Object}compframework - compframework data
  * @description this function calls compframeworks api to add compframework.
	*/
  saveCompframework(compframework){
    var company_id;
    this.CompanyService.getCompanyData(function(res){
      company_id=res._id;
    });
    compframework.company_id=company_id;
    return this.$http.post('/api/compframeworks', compframework).then(() => this.getCompframeworks());
  }

  /**
	* @function updateCompframework
  * @memberOf angular_module.CompFrameworkComponent
  * @param {Object}compframework - compframework data
  * @description this function calls compframeworks api to update compframework.
	*/
  updateCompframework(compframework){
    return this.$http.put(`/api/compframeworks/${compframework._id}`, compframework);
  }

  /**
	* @function editCompframework
  * @memberOf angular_module.CompFrameworkComponent
  * @param {Object}compframework - compframework data
  * @fires openModal
  * @description this function is used to edit compframework.
	*/
  editCompframework(compframework) {
    this.openModal(compframework);
  }

  /**
	* @function cloneCompframework
  * @memberOf angular_module.CompFrameworkComponent
  * @param {Object}compframework - compframework data
  * @fires openModal
  * @description this function is used to clone compframework.
	*/
  cloneCompframework(compframework) {
    let clonedcompframework = {}
    if(typeof compframework.company_id !== undefined)
    clonedcompframework.company_id = compframework.company_id
    if(typeof compframework.report_ai_statements !== undefined)
    clonedcompframework.report_ai_statements = compframework.report_ai_statements
    this.openModal(clonedcompframework);
  }

  /**
	* @function openModal
  * @memberOf angular_module.CompFrameworkComponent
  * @param {object}compframework - compframework data
  * @description this function opens modal for adding or editing compframework.
	*/
  openModal(compframework) {
    var compframeworksCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_compframework.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        this.compframework = compframework;
        this.commitCompframework = function(l) {
          var promise;
          if (l._id) {
            promise = compframeworksCtrl.updateCompframework(l);
          } else {
            promise = compframeworksCtrl.saveCompframework(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}

export default angular.module('leafApp.compframework', [uiRouter])
.config(routes)
.component('compframework', {
  template: require('./compframework.html').default,
  controller: CompFrameworkComponent,
  controllerAs: 'compframeworksCtrl'
})
.name;
