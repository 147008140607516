'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './gbackgrounds.routes';
 /**
  * @class angular_module.GbackgroundsComponent
  */
export class GbackgroundsComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteBackground = Modal.confirm.delete(background => {
      $http.delete(`/api/gbackgrounds/${background._id}`).then(() => this.$onInit());;
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.GbackgroundsComponent
  * @description this function intiates the Gbackgrounds component
	*/
  $onInit() {
    this.$http.get('/api/gbackgrounds')
      .then(response => {
        this.backgrounds = response.data;
      });
  }

  /**
	* @function saveBackground
  * @memberOf angular_module.GbackgroundsComponent
  * @param {Object} background - gbackground data
  * @description this function calls add gbackgrounds api to add gbackground.
	*/
  saveBackground(background) {
    return this.$http.post('/api/gbackgrounds', background).then(() => this.$onInit());
  }

  /**
	* @function updateBackground
  * @memberOf angular_module.GbackgroundsComponent
  * @param {Object} background - gbackground data
  * @description this function calls update gbackgrounds api to update gbackground.
	*/
  updateBackground(background) {
    return this.$http.put(`/api/gbackgrounds/${background._id}`, background);
  }

  /**
	* @function openModal
  * @memberOf angular_module.GbackgroundsComponent
  * @param {Object} background - gbackground data
  * @description this function will open modal for adding or editing gbackground.
	*/
  openModal(background) {
    var gbackgroundsCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_background.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.backgrounds = angular.copy(gbackgroundsCtrl.backgrounds);
        this.background = background;

        this.commitBackground = function(l) {
          var promise;
          if (l._id) {
            promise = gbackgroundsCtrl.updateBackground(l);
          } else {
            promise = gbackgroundsCtrl.saveBackground(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addBackground
  * @memberOf angular_module.GbackgroundsComponent
  * @fires openModal
  * @description this function is used to add gbackground data.
	*/
  addBackground() {
    this.openModal({});
  }

  /**
	* @function editBackground
  * @memberOf angular_module.GbackgroundsComponent
  * @fires openModal
  * @description this function is used to update gbackground data.
	*/
  editBackground(background) {
    this.openModal(background);
  }
}

export default angular.module('leafApp.gbackgrounds', [uiRouter])
  .config(routes)
  .component('gbackgrounds', {
    template: require('./gbackgrounds.html').default,
    controller: GbackgroundsComponent,
    controllerAs: 'gbackgroundsCtrl'
  })
  .name;
