"use strict";
import angular from 'angular';
const _ = require("lodash");
import uiRouter from 'angular-ui-router';

import routes from "./invite.routes";
/**
 * @class angular_module.scoreComponent
 */
export class inviteComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.playerData = [];
  }

  /**
   * @function $onInit
   * @memberOf angular_module.scoreComponent
   * @fires getscores
   * @description this function intiates the score component.
   */
  $onInit() {
    console.log("Hi");
  }

  searchEmail(emailId) {
    //  console.log(email);
    //  this.scores =  this.scoreOriginal.filter(el => el.email.includes(emailId));

    if (!emailId) {
      return;
    }
    this.$http
      .get("/api/candidates/query?email=" + emailId)
      .then((response) => {
        console.log(response);
        this.playerData = response.data;
      });
  }
}

export default angular
  .module("leafApp.invite", [uiRouter])
  .config(routes)
  .component("invite", {
    template: require("./invite.html").default,
    controller: inviteComponent,
    controllerAs: "inviteCtrl",
  }).name;
