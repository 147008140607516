"use strict";
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './companygame.routes';

import { requestConfigV2 } from '../company.component';
/**
 * @class angular_module.CompanyGameComponent
 */
export class CompanyGameComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompanyGameComponent
   * @fires getCompany
   * @fires getGames
   * @description this function intiates the CompanyGame Component.
   */
  $onInit() {
    this.getCompany();
    this.getGames();
  }

  /**
   * @function getCompany
   * @memberOf angular_module.CompanyGameComponent
   * @description this function calls company api to get company data.
   */
  getCompany() {
    var company_id;
    const cb = function(res) {
      company_id = res._id;
      this.company_name = res.company_name;
    };


    this.CompanyService.getCompanyData(cb.bind(this));
    if (company_id == '') {
      this.$location.path('/company');
    }
    this.$http.get(`/api/company/${company_id}`).then((response) => {
      this.company = response.data;
      // this.company.languages = [{ Hindi: true }];
      this.proctoringInitialData = this.company.Proctoring;
    });
  }

  /**
   * @function commitCompany
   * @memberOf angular_module.CompanyGameComponent
   * @param {String}attribute - attribute name
   * @param {Object}company - company data
   * @description this function calls company api to update company attributes.
   */
  commitCompany(attribute, company) {
    this.attributedata = {
      AssesmentStory: 'Assessment Game',
      CampusStory: 'Campus Game',
      Proctoring: 'Proctoring',
      Reports: 'Reports',
      attempts: 'attempts',
      languages: 'Languages',
      mcqCount: 'MCQ Count',
      mcqTimeout: 'MCQ Timeout'
    };
    var promise;
    if (company[attribute] == null) company[attribute] = '';
    if (company._id) {
      promise = this.updateCompany(company);

      const reqConfig = requestConfigV2('EDIT', {
        _id: company._id,
        Proctoring: company.Proctoring === 'On' ? true : false,

      });

      // first we will update the data in v2 then we will update the data in v1
      this.$http(reqConfig).then(
        (compData) => {
          console.log('Updated in v2', compData);

          promise.then(
            () => {
              this.success =
                this.attributedata[attribute] + ' Successfully Updated';
              this.proctoringInitialData = company.Proctoring;
            },
            (err) => {
              // if fail to update in v1 then we will revert the changed done in v1
              console.log(err);
              const reqConfigFail = requestConfigV2('EDIT', {
                _id: company._id,
                Proctoring: this.proctoringInitialData === 'On' ? true : false,
              });
              this.error = err.statusText;
            }
          );
        },
        (err) => {
          this.error = this.attributedata[attribute] + ' Fail to Update';
        }
      );
    }
  }

  /**
   * @function updateCompany
   * @memberOf angular_module.CompanyGameComponent
   * @param {Object}company - company data
   * @description this function calls company api to update company.
   */
  updateCompany(company) {
    return this.$http.put(`/api/company/${company._id}`, company);
  }

  /**
   * @function getGames
   * @memberOf angular_module.CompanyGameComponent
   * @description this function calls company api to get company games.
   */
  getGames() {
    this.$http.get('/api/games?only_main_game=true').then((response) => {
      this.games = response.data;
    });
  }
}

export default angular
  .module("leafApp.game", [uiRouter])
  .config(routes)
  .component("companygame", {
    template: require("./companygame.html").default,
    controller: CompanyGameComponent,
    controllerAs: 'gameCtrl',
  }).name;
