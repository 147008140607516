"use strict";
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import { API_V2_BASE_URL, API_V2_KEY, API_V2_KEY2 } from "../app.constants";
import routes from "./company.routes";
/**
 * @class angular_module.CompanyComponent
 */
export class CompanyComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;

    this.deleteCompany = Modal.confirm.delete((Company) => {
      // $http
      //   .delete(`/api/company/${Company._id}`)
      //   .then(() => this.getCompanies());
      this._deleteCompany(Company);
    });
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompanyComponent
   * @fires getCompanies
   * @description this function intiates the company component.
   */
  $onInit() {
    this.getCompanies();
  }

  /**
   * @function editCompany
   * @memberOf angular_module.CompanyComponent
   * @param {object}company - company data
   * @description this function is used to edit company.
   */
  editCompany(company) {
    console.log({company})
    sessionStorage.setItem('company_id' , company._id)
    this.CompanyService.setCompanyData(company._id, company.company_name, function (res) { });
    this.$location.path("/compframework");
  }

  /**
   * @function getCompanies
   * @memberOf angular_module.CompanyComponent
   * @description this function calls company api to get companies data.
   */
  getCompanies() {
    this.$http.get("/api/company").then((response) => {
      this.companies = response.data;
    });
  }

  /**
   * @function addCompany
   * @memberOf angular_module.CompanyComponent
   * @fires openModal
   * @description this function is used to add company.
   */
  addCompany() {
    this.openModal({});
  }

  /**
   * @function saveCompany
   * @memberOf angular_module.CompanyComponent
   * @description this function calls company api to add company.
   */
  saveCompanyOld(company) {
    return this.$http
      .post("/api/company", company)
      .then(() => this.getCompanies());
  }

  saveCompany(company) {
    /**
     * company: {
        company_name: "asdasd"
        email: "zxcxz@adsfds.com"
        password: "123123123"
     }
     */
    // add to v2

    return this.$http(
      requestConfigV2("ADD", { name: company.company_name })
    )
      .then((response) => {
        const resp = response.data;
        const _id = resp._id;
        const companyData = {
          _id: _id,
          company_name: company.company_name,
          email: company.email,
          password: company.password,
        };
        this.$http
          .post("/api/company", companyData)
          .then(() => {
            this.getCompanies();
          })
          .catch((error) => {
            console.log(error);
            //error in saving v1
            // delete the added entry from v2 using id
            this.$http(
              requestConfigV2("DELETE", { _id: _id, name: name })
            );
          });
      })
      .catch((error) => {
        console.log(error);
        // if error in v2 then do nothing, just return
        return;
      });
  }

  _deleteCompany(Company) {
    const _id = Company._id;
    const name = Company.company_name;
    // delete from v2
    const reqConfig = requestConfigV2("DELETE", { _id: _id, name: name });
    this.$http(reqConfig)
      .then((resp) => {
        this.$http
          .delete(`/api/company/${Company._id}`)
          .then(() => {
            this.getCompanies();
          })
          .catch((error) => {
            console.log(error);
            this.$http(requestConfigV2("ADD", { _id, name })).then(() => {
              console.log("Company restored successfully");
            });
          });
      })
      .catch((error) => {
        // if error in deleting from v2 then do nothing
        console.log(error);
      });
    // delete from v1
  }



  /**
   * @function openModal
   * @memberOf angular_module.CompanyComponent
   * @param {object}company - company data
   * @description this function opens modal for adding or editing company.
   */
  openModal(Company) {
    var companyCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: "add_company.html",
      size: "md",
      controller: function ($uibModalInstance) {
        "ngInject";
        this.Company = Company;
        this.commitCompany = function (l) {
          var promise = companyCtrl.saveCompany(l);
          promise.then(
            () => {
              $uibModalInstance.dismiss("cancel");
            },
            (err) => {
              this.error = err.statusText;
            }
          );
        };
      },
      controllerAs: "$ctrl",
    });
  }
}

export function requestConfigV2(type, data, apiPath = null, apiKeyVer = 1) {
  if (type === "ADD") {
    // for backward compatibility, url has to be passed in the function instead of hardcoded it
    let url = apiPath ? apiPath : "companies/add";

    const requestConfig = {
      method: "POST",
      url: `${API_V2_BASE_URL}/${url}`,
      headers: {
        "api-key": apiKeyVer === 1 ? API_V2_KEY : API_V2_KEY2,
      },
      data: data,
    };
    return requestConfig;
  }
  if (type === "DELETE") {
    let url = apiPath ? apiPath : "companies";

    const requestConfig = {
      method: "DELETE",
      url: `${API_V2_BASE_URL}/${url}`,
      headers: {
        "api-key": apiKeyVer === 1 ? API_V2_KEY : API_V2_KEY2,
        "Content-Type": "application/json",
      },
      data: data,
    };
    return requestConfig;
  }
  if (type === "EDIT") {
    let url = apiPath ? apiPath : "companies/edit";

    const requestConfig = {
      method: "PUT",
      url: `${API_V2_BASE_URL}/${url}`,
      headers: {
        "api-key": apiKeyVer === 1 ? API_V2_KEY : API_V2_KEY2,
      },
      data: data,
    };
    return requestConfig;
  }

  if (type === "GET") {
    const requestConfig = {
      method: "GET",
      url: `${API_V2_BASE_URL}/${apiPath}`,
      headers: {
        "api-key": apiKeyVer === 1 ? API_V2_KEY : API_V2_KEY2,
      },
    };

    return requestConfig;
  }
}

export default angular
  .module("leafApp.company", [uiRouter])
  .config(routes)
  .component("company", {
    template: require("./company.html").default,
    controller: CompanyComponent,
    controllerAs: "companyCtrl",
  }).name;
