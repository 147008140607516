'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('persona_factors', {
      url: '/persona_factors',
      template: '<persona-factors></persona-factors>'
    });
}
