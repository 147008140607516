'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('section', {
      url: '/section',
      template: '<section></section>'
    });
}
