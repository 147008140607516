'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gamedata', {
      url: '/gamedata',
      template: '<gamedata></gamedata>'
    });
}
