'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('mparam', {
      url: '/manager-parameter',
      template: '<mparam></mparam>'
    });
}
