'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './percentilebenchmark.routes';
/**
  * @class angular_module.PercentileBenchmarkComponent
  */
export class PercentileBenchmarkComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal,$location) {
          this.$http = $http;
          this.$scope = $scope;
          this.$uibModal = $uibModal;
          this.$location=$location;
          this.deleteAttributeBenchmark= Modal.confirm.delete(benchmark => {
               $http.delete(`/api/attrsbenchmarks/${benchmark._id}`).then(() => this.getAttributeBenchmarks());
          });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @fires getAttributeBenchmarks
     * @fires getPsychoAttributes
     * @description this function intiates the PercentileBenchmark component
	*/ 
     $onInit() {
          this.getAttributeBenchmarks();
          this.getPsychoAttributes();
     }
     arrayToMap(data) {
          let map = {};
          data.forEach(d => {
               map[d.name] = d;
          });
          return map;
     }
     mapToArray(map) {
          return Object.keys(map).map(d => map[d]);
     }

     /**
	* @function getAttributeBenchmarks
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @description this function calls get attrsbenchmarks api which is used to get Attribute Benchmarks
	*/ 
     getAttributeBenchmarks(){
          this.$http.get('/api/attrsbenchmarks')
          .then(response => {
            
               this.benchmarks = response.data;
               this.benchmarks=JSON.parse(angular.toJson(response.data));
          });
     }

     /**
	* @function saveAttributeBenchmark
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @param {Object} benchmark - attribute benchmark data
     * @description this function calls add attrsbenchmarks api which is used to save Attribute Benchmarks
	*/
     saveAttributeBenchmark(benchmark) {
          return this.$http.post('/api/attrsbenchmarks', benchmark).then(() => this.getAttributeBenchmarks());
     }

     /**
	* @function updateAttributeBenchmark
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @param {Object} benchmark - attribute benchmark data
     * @description this function calls update attrsbenchmarks api which is used to update Attribute Benchmarks
	*/
     updateAttributeBenchmark(benchmark) {
          return this.$http.put(`/api/attrsbenchmarks/${benchmark._id}`, benchmark);
     }

     /**
	* @function getPsychoAttributes
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @description this function calls get psychos api which is used to get Psycho Attributes
	*/
     getPsychoAttributes(){
          this.$http.get('/api/psychos')
          .then(response => {
               this.attributes = response.data;
          });
     }

     /**
	* @function addAttributeBenchmark
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @fires openModal
     * @description this function opens modal for adding benchmark
	*/
     addAttributeBenchmark() {
       this.openModal({},'new');
     }

     /**
	* @function editAttributeBenchmark
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @fires openModal
     * @param {Object} benchmark - benchmark data
     * @description this function opens modal for editing benchmark
	*/
     editAttributeBenchmark(benchmark) {
       this.openModal(benchmark,'edit');
     }

     /**
	* @function cloneAttributeBenchmark
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @fires openModal
     * @param {Object} benchmark - benchmark data
     * @description this function opens modal for cloning benchmark
	*/
     cloneAttributeBenchmark(benchmark) {
          let clonedBenchmark = {};
          benchmark=JSON.parse(angular.toJson(benchmark));
          delete benchmark._id;
          clonedBenchmark = typeof benchmark == undefined ? {} : benchmark;
       this.openModal(clonedBenchmark,'clone');
     }

     /**
	* @function openModal
     * @memberOf angular_module.PercentileBenchmarkComponent
     * @param {Object} benchmark - benchmark data
     * @param {String} task - task type
     * @description this function will open modal for adding, editing or cloning benchmark.
	*/
     openModal(benchmark, task) {
       var percentilebenchmarkCtrl = this;
       var modalInstance = this.$uibModal.open({
         animation: true,
         templateUrl: 'add_attribute.html',
         size: 'md',
         controller: function ($uibModalInstance) {
           'ngInject';
           this.benchmark = benchmark;
           this.attributeData = percentilebenchmarkCtrl.attributes;

           if(task == 'new') {
                this.benchmark.attributes = [];
                this.attributeData.forEach(data => {
                     let attribute ={}
                     attribute["attribute_id"] = data._id;
                     attribute["attribute_name"] = data.name;
                     this.benchmark.attributes.push(attribute);
                });
           }
           

           this.commitAttrBenchmarks = function(l) {
                
             if(l._id)
               var promise= percentilebenchmarkCtrl.updateAttributeBenchmark(l);
             else {
               var promise= percentilebenchmarkCtrl.saveAttributeBenchmark(l);
             }
             promise.then(() => {
               $uibModalInstance.dismiss('cancel');
             }, (err) => {
               this.error = err.statusText;
             });
           }
         },
         controllerAs: '$ctrl'
       });
     }

}

export default angular.module('leafApp.percentilebenchmark', [uiRouter])
.config(routes)
.component('percentilebenchmark', {
     template: require('./percentilebenchmark.html').default,
     controller: PercentileBenchmarkComponent,
     controllerAs: 'percentilebenchmarkCtrl'
})
.name;
