'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gorderingquestions', {
      url: '/gamedata/gorderingquestions',
      template: '<gorderingquestions></gorderingquestions>'
    });
}
