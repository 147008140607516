'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './section.routes';

/**
  * @class angular_module.SectionComponent
  */
export class SectionComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
       this.$http = $http;
       this.$scope = $scope;
       this.$uibModal = $uibModal;
       this.deleteSection= Modal.confirm.delete(section => {
            $http.delete(`/api/sections/${section._id}`).then(() => this.getSections());
       });
  }

     /**
	* @function getSections
     * @memberOf angular_module.SectionComponent
     * @description this function calls get sections api to get sections data.
     */
     getSections() {
          this.$http.get('/api/sections')
          .then(response => {
               this.sections = response.data;
          });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.SectionComponent
     * @fires getSections
     * @description this function intiates the section component
	*/
  $onInit() {
       this.getSections();
  }

     /**
	* @function saveSection
     * @memberOf angular_module.SectionComponent
     * @param {Object} section - section data
     * @description this function calls add sections api to add section.
	*/
  saveSection(section) {
       return this.$http.post('/api/sections', section).then(() => this.getSections());
  }

  /**
	* @function updateSection
     * @memberOf angular_module.SectionComponent
     * @param {Object} section - section data
     * @description this function calls update sections api to update section.
	*/
  updateSection(section) {
       return this.$http.put(`/api/sections/${section._id}`, section);
  }

  /**
	* @function openModal
     * @memberOf angular_module.SectionComponent
     * @param {Object} section - section data
     * @description this function will open modal for adding or editing section.
	*/
  openModal(section) {
       var sectionCtrl = this;
       var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'add_section.html',
            size: 'md',
            controller: function ($uibModalInstance) {
                 'ngInject';

                 this.section = section;

                 this.commitSection = function(l) {
                      var promise;
                      if (l._id) {
                           promise = sectionCtrl.updateSection(l);
                      } else {
                           promise = sectionCtrl.saveSection(l);
                      }
                      promise.then(() => {
                           $uibModalInstance.dismiss('cancel');
                      }, (err) => {
                           this.error = err.statusText;
                      });
                 }
            },
            controllerAs: '$ctrl'
          });
     }

     /**
	* @function addSection
     * @memberOf angular_module.SectionComponent
     * @fires openModal
     * @description this function will open modal for adding section
	*/
     addSection() {
          this.openModal({});
     }

     /**
	* @function editScatter
     * @memberOf angular_module.SectionComponent
     * @fires openModal
     * @param {Object} section - section data
     * @description this function will open modal for editing section
	*/
     editSection(section) {
          this.openModal(section);
     }
}

export default angular.module('leafApp.section', [uiRouter])
  .config(routes)
  .component('section', {
    template: require('./section.html').default,
    controller: SectionComponent,
    controllerAs: 'sectionCtrl'
  })
  .name;
