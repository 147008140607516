'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './scatter.routes';

 /**
  * @class angular_module.ScatterComponent
  */
export class ScatterComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$scope = $scope;
          this.$uibModal = $uibModal;
          this.deleteScatter= Modal.confirm.delete(scatter => {
               $http.delete(`/api/scatters/${scatter._id}`).then(() => this.getScatters());
          });
     }
     arrayToMap(data) {
          let map = {};
          data.forEach(d => {
               map[d.name] = d;
          });
          return map;
     }

     mapToArray(map) {
          return Object.keys(map).map(d => map[d]);
     }

     /**
	* @function getScatters
     * @memberOf angular_module.ScatterComponent
     * @description this function calls get scatters api to get scatters data.
	*/
     getScatters() {
          this.$http.get('/api/scatters')
          .then(response => {
               this.scatters = response.data;
          });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.ScatterComponent
     * @fires getScatters
     * @description this function intiates the Scatter component
	*/
     $onInit() {
          this.$http.get('/api/psychos')
          .then(response => {
               this.attrs = this.arrayToMap(response.data);
          });
          this.getScatters();
     }

     /**
	* @function saveScatter
     * @memberOf angular_module.ScatterComponent
     * @param {Object} scatter - scatter data
     * @description this function calls add scatters api to add scatter.
	*/
     saveScatter(scatter) {
          return this.$http.post('/api/scatters', scatter).then(() => this.getScatters());
     }

     /**
	* @function updateScatter
     * @memberOf angular_module.ScatterComponent
     * @param {Object} scatter - scatter data
     * @description this function calls update scatters api to update scatter.
	*/
     updateScatter(scatter) {
          return this.$http.put(`/api/scatters/${scatter._id}`, scatter);
     }

     /**
	* @function openModal
     * @memberOf angular_module.ScatterComponent
     * @param {Object} scatter - scatter data
     * @description this function will open modal for adding or editing scatter.
	*/
     openModal(scatter) {
          var scatterCtrl = this;
          var modalInstance = this.$uibModal.open({
               animation: true,
               templateUrl: 'add_scatter.html',
               size: 'md',
               controller: function ($uibModalInstance) {
                    'ngInject';

                    this.scatter = scatter;

                    if (!scatter._id) {
                         this.scatter.priorities = scatterCtrl.mapToArray(scatterCtrl.attrs);
                    } else {
                         
                         let keys = Object.keys(scatterCtrl.attrs);
                         this.scatter.priorities.forEach(priority => {
                              let index = keys.indexOf(priority.name);
                              if (index > -1) {
                                   keys.splice(index, 1);
                              }
                         });
                         
                         
                         keys.forEach(k => {
                              this.scatter.priorities.push(scatterCtrl.attrs[k]);
                         });
                    }

                    this.commitScatter = function(l) {
                         var promise;
                         if (l._id) {
                              promise = scatterCtrl.updateScatter(l);
                         } else {
                              promise = scatterCtrl.saveScatter(l);
                         }
                         promise.then(() => {
                              $uibModalInstance.dismiss('cancel');
                         }, (err) => {
                              this.error = err.statusText;
                         });
                    }
               },
               controllerAs: '$ctrl'
          });
     }

     /**
	* @function addScatter
     * @memberOf angular_module.ScatterComponent
     * @fires openModal
     * @description this function will open modal for adding scatter
	*/
     addScatter() {
          this.openModal({});
     }

     /**
	* @function editScatter
     * @memberOf angular_module.ScatterComponent
     * @fires openModal
     * @param {Object} scatter - scatter data
     * @description this function will open modal for editing scatter
	*/
     editScatter(scatter) {
          this.openModal(scatter);
     }
}

export default angular.module('leafApp.scatter', [uiRouter])
.config(routes)
.component('scatter', {
     template: require('./scatter.html').default,
     controller: ScatterComponent,
     controllerAs: 'scatterCtrl'
})
.name;
