"use strict";
import angular from 'angular';

import uiRouter from 'angular-ui-router';
import { requestConfigV2 } from "../company.component";

import routes from "./aptitude.routes";
/**
 * @class angular_module.CompanySectionsComponent
 */
export class AptitudeComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.aptitudeGamesList = [];
    this.techAssessmentsLists = [];
    this.$http = $http;
    this.selectedApti = "";
    this.selectedTechAssessment = "";
    this.allAptitudeAssessments = {
      aptitude: "",
      customisedIndividual: "",
      customisedManagerial: "",
      organisationalMatch: "",
      campus: "",
    };
    this.allAptitudeAssessmentsOriginal = {
      aptitude: "",
      customisedIndividual: "",
      customisedManagerial: "",
      organisationalMatch: "",
      campus: "",
    };

    this.allTechAssessments = [""];
    this.allTechAssessmentsOriginal = [""];
    this.CompanyService = CompanyService;
    this.companyData = null;
    this.$location = $location;
    this.isSavingData = false;
    this.assessmentsTypes = [
      { displayName: "Aptitude", internalName: "aptitude" },
      {
        displayName: "Customised-Individual",
        internalName: "customisedIndividual",
      },
      {
        displayName: "Customised-Managerial",
        internalName: "customisedManagerial",
      },
      {
        displayName: "Organisational Match",
        internalName: "organisationalMatch",
      },
      { displayName: "Campus", internalName: "campus" },
    ];
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompFrameworkComponent
   * @fires getCompframeworks
   * @description this function intiates the CompFramework Component.
   */
  $onInit() {
    this.getCompanyData();
    this.getAddedAssessments();
  }

  getAddedAssessments() {
    this.$http(
      requestConfigV2("GET", null, `companies/${this.companyData._id}`, 2)
    )
      .then((data) => {
        // const techAssessmentsQuestions = data.questions;
        console.log("assessments data", data);
        const resp = data.data;

        this.assessmentsTypes.forEach((assessment) => {
          this.allAptitudeAssessments[assessment.internalName] =
            resp[assessment.internalName] || null;
          this.allAptitudeAssessmentsOriginal[assessment.internalName] =
            resp[assessment.internalName] || null;
        });

        this.allTechAssessments = data.data.techAssessments
          .sort()
          .map((tech) => tech._id);
        this.allTechAssessmentsOriginal = data.data.techAssessments.map(
          (tech) => tech._id
        );
        // this.allAptitudeAssessments = data.data.aptitudeGames
        //   .sort((first, second) => first.order - second.order)
        //   .map((aptiGame) => aptiGame.gameId._id);

        // this.allAptitudeAssessmentsOriginal = data.data.aptitudeGames
        //   .sort((first, second) => first.order - second.order)
        //   .map((aptiGame) => aptiGame.gameId._id);

        this.getAptitudeGames();
        this.getTechAssessments();
      })
      .catch((error) => {
        this.error = "Something went wrong";
        setTimeout(() => {
          this.error = null;
        }, 2000);
        console.log(error);
      });
  }

  getCompanyData() {
    this.CompanyService.getCompanyData((res) => {
      this.companyData = res;
    });
    // if we don't have  company data then we will redirect
    if (!this.companyData || this.companyData._id === "") {
      this.$location.path("/company");
    }
  }

  getAptitudeGames() {
    this.$http(requestConfigV2("GET", null, "aptitude/game-list", 2)).then(
      (data) => {
        this.aptitudeGamesList = data.data.list;
      }
    );
  }
  getTechAssessments() {
    this.$http(requestConfigV2("GET", null, "questions-game/list", 2)).then(
      (data) => {
        this.techAssessmentsLists = data.data.list;
      }
    );
  }

  addAptitude() {
    this.allAptitudeAssessments.push("");
  }

  addTechAssessment() {
    this.allTechAssessments.push("");
  }

  removeTechAssessment(index) {
    this.allTechAssessments = this.allTechAssessments.filter(
      (el, i) => i !== index
    );
  }
  removeAptitudeAssessments(index) {
    this.allAptitudeAssessments = this.allAptitudeAssessments.filter(
      (el, i) => i !== index
    );
  }

  submitData() {
    this.isSavingData = true;
    // console.log("Selected Apti", this.allAptitudeAssessments);
    // console.log("Selected tech assessment ", this.allTechAssessments);
    // const aptitudeGames = this.allAptitudeAssessments.map((gameId, i) => ({
    //   gameId,
    //   order: i + 1,
    // }));
    const techAssessments = this.allTechAssessments;
    const techAssessmentsOld = this.allTechAssessmentsOriginal;
    // const company_name = this.companyData.company_name;
    const company_id = this.companyData._id;

    // const reqBody = {
    //   id: company_id,
    //   aptitudeGames: aptitudeGames,
    //   techAssessments: techAssessments,
    // };

    const apiPath = `/api/company/${company_id}`;

    const mergedData = Object.assign(
      {},
      { techAssessments: techAssessments },
      this.allAptitudeAssessments
    );

    const mergedDataOriginal = Object.assign(
      {},
      { techAssessments: techAssessmentsOld },
      this.allAptitudeAssessmentsOriginal
    );

    return this.$http
      .put(apiPath, mergedData)
      .then(() => {
        //TODO this null and 2 is temp
        mergedData._id = company_id;
        const reqConfig = requestConfigV2("EDIT", mergedData, null, 2);
        this.$http(reqConfig)
          .then(() => {
            console.log("data saved successfully");
            this.success = "Assessments updated successfully";
            setTimeout(() => {
              this.success = null;
            }, 2000);
            this.isSavingData = false;
          })
          .catch((error) => {
            // error saving in v2 then we will revert the changes done in v1
            this.$http
              .put(apiPath, mergedDataOriginal)
              .then(() => {
                console.log("Changes reverted successfully");
                this.isSavingData = false;
              })
              .catch((err) => {
                console.log(err.message);
                this.error = "Error updating assessments";
                setTimeout(() => {
                  this.error = null;
                }, 2000);
                this.isSavingData = false;
              });
            console.log(error);
          });
      })
      .catch((error) => {
        // error in saving in v1
        this.isSavingData = false;
        console.log(error);
      });
  }
}

export default angular
  .module("leafApp.aptitude", [uiRouter])
  .config(routes)
  .component("aptitude", {
    template: require("./aptitude.html").default,
    controller: AptitudeComponent,
    controllerAs: "aptitudeCtrl",
  }).name;
