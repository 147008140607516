'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './orgcompetency.routes';
/**
  * @class angular_module.OrgCompetencyComponent
  */
export class OrgCompetencyComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,CompanyService, OrgCompetencyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.OrgCompetencyService = OrgCompetencyService;
    this.$location=$location;

    this.deleteOrgcompetency= Modal.confirm.delete(orgcompetency => {
      $http.delete(`/api/orgcompetencies/${orgcompetency._id}`).then(() => this.getOrgcompetencies());
    });
  }


  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d._id] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function $onInit
  * @memberOf angular_module.OrgCompetencyComponent
  * @fires getOrgcompetencies
  * @fires getCompframeworks
  * @description this function intiates the OrgCompetency Component.
	*/
  $onInit() {
    this.getOrgcompetencies();
    this.getCompframeworks();
  }

  /**
	* @function getOrgcompetencies
  * @memberOf angular_module.OrgCompetencyComponent
  * @description this function calls orgcompetencies api to get orgcompetencies data.
	*/
  getOrgcompetencies() {
    var company_id;
    const cb = function(res){
      company_id=res._id;
      this.company_name=res.company_name;
    }


    this.CompanyService.getCompanyData(cb.bind(this));
    if(company_id==""){
      this.$location.path("/company");
    }
    else{
      this.$http.get(`/api/orgcompetencies/findAllByCompanyId/${company_id}`)
      .then(response => {
        this.orgcompetencies = response.data;
      });
    }
  }

  /**
	* @function getCompframeworks
  * @memberOf angular_module.OrgCompetencyComponent
  * @description this function calls compframeworks api to get compframeworks data.
	*/
  getCompframeworks() {
    var company_id;
    this.CompanyService.getCompanyData(function(res){
      company_id=res._id;
    });
    if(company_id==""){
      this.$location.path("/company");
    }
    else{
      this.$http.get(`/api/compframeworks/findAllByCompanyId/${company_id}`)
      .then(response => {
        this.compframeworks = this.arrayToMap(response.data);
      });
    }
  }

  /**
	* @function addOrgcompetency
  * @memberOf angular_module.OrgCompetencyComponent
  * @description this function is used to add org competency.
	*/
  addOrgcompetency() {
    this.openModal({});
  }

  /**
	* @function saveOrgcompetency
  * @memberOf angular_module.OrgCompetencyComponent
  * @param {Object}orgcompetency - orgcompetency data
  * @description this function calls orgcompetencies api to add new orgcompetency.
	*/
  saveOrgcompetency(orgcompetency){
    var company_id;
    this.CompanyService.getCompanyData(function(res){
      company_id=res._id;
    });
    orgcompetency.company_id=company_id;
   if(orgcompetency.interview_questions==undefined){
      orgcompetency.interview_questions='';
    }

    return this.$http.post('/api/orgcompetencies', orgcompetency).then(() => this.getOrgcompetencies());
  }

  /**
	* @function updateOrgcompetency
  * @memberOf angular_module.OrgCompetencyComponent
  * @param {Object}orgcompetency - orgcompetency data
  * @description this function calls orgcompetencies api to update orgcompetency.
	*/
  updateOrgcompetency(orgcompetency){
    return this.$http.put(`/api/orgcompetencies/${orgcompetency._id}`, orgcompetency);
  }

  /**
	* @function editOrgcompetency
  * @memberOf angular_module.OrgCompetencyComponent
  * @param {Object}orgcompetency - orgcompetency data
  * @description this function is used to edit orgcompetency.
	*/
  editOrgcompetency(orgcompetency) {
   if(orgcompetency.interview_questions==undefined){
      orgcompetency.interview_questions='';
    }

    this.openModal(orgcompetency);
  }

  /**
	* @function updateSuborgcompetencies
  * @memberOf angular_module.OrgCompetencyComponent
  * @param {Object}orgcompetency - orgcompetency data
  * @description this function calls suborgcompetency api to update Suborgcompetencies.
	*/
  updateSuborgcompetencies(orgcompetency){
    this.OrgCompetencyService.setOrgcompetencyData(orgcompetency,function(res){
    });
    this.$location.path("/suborgcompetency");

  }

  /**
	* @function openModal
  * @memberOf angular_module.OrgCompetencyComponent
  * @param {Object} orgcompetency - orgcompetency data
  * @description this function open modal for adding or editing orgcompetencies.
	*/
  openModal(orgcompetency) {
    var orgcompetenciesCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_orgcompetency.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        this.orgcompetency = orgcompetency;
        this.orgcompetency.compframeworks = orgcompetenciesCtrl.compframeworks;
        this.commitOrgcompetency = function(l) {
          var promise;
          if (l._id) {
            promise = orgcompetenciesCtrl.updateOrgcompetency(l);
          } else {
            promise = orgcompetenciesCtrl.saveOrgcompetency(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}

export default angular.module('leafApp.orgcompetency', [uiRouter])
.config(routes)
.component('orgcompetency', {
  template: require('./orgcompetency.html').default,
  controller: OrgCompetencyComponent,
  controllerAs: 'orgcompetenciesCtrl'
})
.name;
