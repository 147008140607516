'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('compframework', {
    url: '/compframework',
    template: '<compframework></compframework>'
  })
}
