'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('gamesbenchmark', {
      url: '/gamesbenchmark',
      template: '<gamesbenchmark></gamesbenchmark>'
    });
}
