'use strict';

import angular from 'angular';

export class NavbarComponent {
  constructor($location, $rootScope, Auth) {
    'ngInject';

    this.isLoggedIn = Auth.isLoggedInSync;
    this.isAdmin = Auth.isAdminSync;
    this.getCurrentUser = Auth.getCurrentUserSync;
    this.isTalentLitmusActive = true;
    this.isBHiredActive = false;
    this.$location = $location;
    this.$rootScope = $rootScope;
    if(this.$location.$$path === "/bhired_candidate" || this.$location.$$path === "/bhired_benchmark"){
      this.changeClientTabs('bhired_label');
    }
    if(this.$location.$$path === "/kevaCandidate"){
      this.changeClientTabs('keva');
    }
    if(this.$location.$$path === "/sonyCandidate"){
      this.changeClientTabs('sony');
    }
    if(this.$location.$$path === "/abgcandidate"){
      this.changeClientTabs('abg');
    }
    if(this.$location.$$path === "/tvs" ||this.$location.$$path === "/tvs_benchmark" ||this.$location.$$path === "/tvs_reportspec" ){
      this.changeClientTabs('tvs');
    }
    if(this.$location.$$path === "/tvs_motors_private" ||this.$location.$$path === "/tvs_motors_private_benchmark" ||this.$location.$$path === "/tvs_motors_private_reportspec" ){
      this.changeClientTabs('tvs_motors_private');
    }
    
  }

  $onInit() {
    if (
      this.$location.$$path === '/bhired_candidate' ||
      this.$location.$$path === '/bhired_benchmark'
    ) {
      this.changeClientTabs('bhired_label');
    }
    if (this.$location.$$path === '/kevaCandidate') {
      this.changeClientTabs('keva');
    }
    if (this.$location.$$path === '/sonyCandidate') {
      this.changeClientTabs('sony');
    }
    if (this.$location.$$path === '/abgcandidate') {
      this.changeClientTabs('abg');
    }
    if (
      this.$location.$$path === '/tvs' ||
      this.$location.$$path === '/tvs_benchmark' ||
      this.$location.$$path === '/tvsreportspec'
    ) {
      this.changeClientTabs('tvs');
    }
  }

  changeClientTabs(tabName) {
    angular
      .element(document.querySelector('.company_active_tab_bar'))
      .removeClass('company_active_tab_bar');
    switch (tabName) {
      case 'talent_litmus_label':
        this.isTalentLitmusActive = true;
        this.isBHiredActive = false;
        this.isKevaActive = false;
        this.isSonyActive = false;
        this.isAbgActive = false;
        this.isTvsActive = false;
        this.isTvsMotorsPrivateActive = false;
        break;
      case 'bhired_label':
        this.isBHiredActive = true;
        this.isTalentLitmusActive = false;
        this.isKevaActive = false;
        this.isSonyActive = false;
        this.isAbgActive = false;
        this.isTvsActive = false;
        this.isTvsMotorsPrivateActive = false;
        break;
      case 'keva':
        this.isTvsMotorsPrivateActive = false;
        this.isKevaActive = true;
        this.isBHiredActive = false;
        this.isTalentLitmusActive = false;
        this.isSonyActive = false;
        this.isAbgActive = false;
        this.isTvsActive = false;
        this.isTvsMotorsPrivateActive = false;
        break;
      case 'sony':
        this.isKevaActive = false;
        this.isBHiredActive = false;
        this.isTalentLitmusActive = false;
        this.isSonyActive = true;
        this.isAbgActive = false;
        this.isTvsActive = false;
        this.isTvsMotorsPrivateActive = false;
        break;
      case 'abg':
        this.isKevaActive = false;
        this.isBHiredActive = false;
        this.isTalentLitmusActive = false;
        this.isSonyActive = false;
        this.isAbgActive = true;
        this.isTvsActive = false;
        break;
      case 'tvs':
      this.isTvsMotorsPrivateActive = false;
        this.isKevaActive = false;
        this.isBHiredActive = false;
        this.isTalentLitmusActive = false;
        this.isSonyActive = false;
        this.isTvsActive = true;
        this.isAbgActive = false;
        break;
      case 'tvs_motors_private':
        this.isKevaActive = false;
        this.isBHiredActive = false;
        this.isTalentLitmusActive = false;
        this.isSonyActive = false;
        this.isTvsActive = false
        this.isAbgActive = false;
        this.isTvsMotorsPrivateActive = true;
      }
      angular.element(document.querySelector("#"+tabName)).addClass('company_active_tab_bar');
  }
}

export default angular.module('directives.navbar', []).component('navbar', {
  template: require('./navbar.html').default,
  controller: NavbarComponent,
}).name;
