'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './version.routes';
/**
  * @class angular_module.VersionComponent
  */
export class VersionComponent {
  /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal, $location) {
          this.message = 'Hello';
          this.$http = $http;
          this.$scope = $scope;
          this.$uibModal = $uibModal;
          this.$location=$location;
          this.deleteAppVersion= Modal.confirm.delete(report => {
            $http.delete(`/api/appversions/${report._id}`).then(() => this.getappversion());
          });
     }


  
     /**
	* @function $onInit
  * @memberOf angular_module.VersionComponent
  * @fires getappversion
  * @description this function intiates the Version component
	*/
     $onInit() {
          this.getappversion();
     }

     /**
	* @function addVersions
  * @memberOf angular_module.VersionComponent
  * @fires openModal
  * @description this function open modal for adding app versions
	*/
     addVersions() {
          this.openModal({});
     }

     /**
	* @function editAppVersion
  * @memberOf angular_module.VersionComponent
  * @fires openModal
  * @param {Object} version - version data
  * @description this function open modal for editing app versions
	*/
     editAppVersion(version) {
          this.openModal(version);
     }

     /**
	* @function getappversion
  * @memberOf angular_module.VersionComponent
  * @description this function calls appversions api to get app versions
	*/
     getappversion() {
          this.$http.get('/api/appversions').then(response => {
               this.appversions = response.data;
          });
     }

     /**
	* @function saveReport
  * @memberOf angular_module.VersionComponent
  * @param {Object}company - company data
  * @description this function calls appversions api to add app version
	*/
     saveReport(company){
          return this.$http.post('/api/appversions', company).then(() => this.getappversion());
     }

     /**
	* @function updateAttr
  * @memberOf angular_module.VersionComponent
  * @param {Object}attr - attr data
  * @description this function calls appversions api to update app version
	*/
     updateAttr(attr) {
          return this.$http.put(`/api/appversions/${attr._id}`, attr).then(() => this.getappversion());
     }


     /**
	* @function openModal
  * @memberOf angular_module.VersionComponent
  * @param {Object} version - version data
  * @description this function will open modal for adding or editing app version.
	*/
  openModal(version) {
    var versionCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_version.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        function stringifyObjectValues(obj) {
          const stringifiedObj = {};
          Object.keys(obj).forEach(key => {
            stringifiedObj[key] = String(obj[key]);
          });
          return stringifiedObj;
        }
        this.Version = stringifyObjectValues(version)
        this.commitVersionData = function(l) {
          var promise;
          if (l._id) {
            promise = versionCtrl.updateAttr(l);
          } else {
            promise = versionCtrl.saveReport(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}

export default angular.module('leafApp.version', [uiRouter])
  .config(routes)
  .component('version', {
    template: require('./version.html').default,
    controller: VersionComponent,
    controllerAs: 'versionCtrl'
  })
  .name;
