'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('success_factor_tagging', {
      url: '/success_factor_tagging',
      template: '<success-factor-tagging></success-factor-tagging>'
    });
}
