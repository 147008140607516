'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gminigames', {
      url: '/gamedata/gminigames',
      template: '<gminigames></gminigames>'
    });
}
