import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routing from './main.routes';

export class MainController {

  /*@ngInject*/
  constructor($http, $scope) {
    // this.$http = $http;
    // this.socket = socket;

    // $scope.$on('$destroy', function() {
    //   socket.unsyncUpdates('domain');
    //   socket.unsyncUpdates('sector');
    // });
  }

  $onInit() {
    // this.$http.get('/api/domains')
    //   .then(response => {
    //     this.jobDomains = response.data;
    //     this.socket.syncUpdates('domain', this.jobDomains);
    //   });

    // this.$http.get('/api/sectors')
    //   .then(response => {
    //     this.jobSectors = response.data;
    //     this.socket.syncUpdates('sector', this.jobSectors);
    //   });
  }

  // addDomain() {
  //   if (this.newDomain) {
  //     this.$http.post('/api/domains', {
  //       name: this.newDomain
  //     });
  //     this.newDomain = '';
  //   }
  // }

  // deleteDomain(domain) {
  //   this.$http.delete('/api/domains/' + domain._id);
  // }

  // addSector() {
  //   if (this.newSector) {
  //     this.$http.post('/api/sectors', {
  //       name: this.newSector
  //     });
  //     this.newSector = '';
  //   }
  // }

  // deleteSector(sector) {
  //   this.$http.delete('/api/sectors/' + sector._id);
  // }
}

export default angular.module('leafApp.main', [uiRouter])
  .config(routing)
  .component('main', {
    template: require('./main.html').default,
    controller: MainController
  })
  .name;
