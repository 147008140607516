'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './managerialpot.routes';
/**
  * @class angular_module.ManagerialpotComponent
  */
export class ManagerialpotComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, $location) {
    this.message = 'Hello';
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$location = $location;
    this.deletemanagerialpotData = Modal.confirm.delete(report => {
      //

      $http.delete(`/api/managerialpot/${report._id}`).then(() => this.getmanagerialpotData());
    });
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function $onInit
  * @memberOf angular_module.ManagerialpotComponent
  * @fires getmanagerialpotData
  * @fires psychoAttributeData
  * @description this function intiates the managerialpot component.
	*/
  $onInit() {
    this.getmanagerialpotData();
    this.psychoAttributeData();
    this.managerialcategories = [];
    this.$http.get('/api/lists/managerial/name')
      .then(response => {
        this.managerialcategories = response.data;
        
      });
  }

  /**
	* @function getmanagerialpotData
  * @memberOf angular_module.ManagerialpotComponent
  * @description this function calls managerialpot api to get managerialpot data.
	*/
  getmanagerialpotData() {
    this.$http.get('/api/managerialpot').then(response => {
      //
      this.managerialpotDatas = response.data;
    });
  }

  /**
	* @function psychoAttributeData
  * @memberOf angular_module.ManagerialpotComponent
  * @description this function calls psychos api to get psychos.
	*/
  psychoAttributeData() {
    this.$http.get('/api/psychos')
      .then(response => {
        //
        this.attrs = response.data;
        //
      });
  }


  /**
	* @function addNew
  * @memberOf angular_module.ManagerialpotComponent
  * @description this function is used to add new managerial pot.
	*/
  addNew() {
    this.openModal({}, this.managerialcategories)
  }

  /**
	* @function saveReport
  * @memberOf angular_module.ManagerialpotComponent
  * @param {object} company - managerialpot data
  * @description this function call managerial pot api to add managerialpot.
	*/
  saveReport(company) {
    return this.$http.post('/api/managerialpot', company).then((response) => this.getmanagerialpotData());
  }

  /**
	* @function updateAttr
  * @memberOf angular_module.ManagerialpotComponent
  * @param {object} attr - attr data
  * @description this function call managerial pot api to update managerialpot.
	*/
  updateAttr(attr) {
    return this.$http.put(`/api/managerialpot/${attr._id}`, attr);
  }

  /**
	* @function editmanagerialpotData
  * @memberOf angular_module.ManagerialpotComponent
  * @param {object} managerialpotData - managerialpot data
  * @description this function is used to edit managerial pot.
	*/
  editmanagerialpotData(managerialpotData) {
    this.openModal(managerialpotData, this.managerialcategories);
  }

  /**
	* @function openModal
  * @memberOf angular_module.ManagerialpotComponent
  * @param {Object} managerialpot - managerialpot data
  * @param {Array} managerialcategories - managerialcategories array
  * @description this function will open modal for adding or editing managerialpot.
	*/
  openModal(managerialpot, managerialcategories) {

    var managerialpotCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_New.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';
        this.managerialpot = managerialpot;
        this.attrs = managerialpotCtrl.attrs;
        this.managerialcategories = managerialcategories;
        this.managerialpot.managerial_domain = '';
        
        managerialcategories.forEach(element => {
          if (this.managerialpot.managerial_domain_id == element._id) {
            // 
            this.managerialpot.managerial_domain = element.name;
          }
        })

        if (!managerialpot._id) {
          // 
          this.managerialpot.priorities = managerialpotCtrl.mapToArray(managerialpotCtrl.attrs);
          this.managerialpot.priorities.forEach(element => {
            element.value = "";
          });

        } else {
          // 
          //     
          let keys = Object.keys(managerialpotCtrl.attrs);
          this.managerialpot.priorities.forEach(priority => {
            let index = keys.indexOf(priority.name);
            if (index > -1) {
              keys.splice(index, 1);
            }
          });

          // 
          // 
          // keys.forEach(k => {
          //      this.managerialpot.priorities.push(managerialpotCtrl.attrs[k]);
          // });
        }


        this.commitmanagerialpotData = function (ModalData) {
          //
          var promise;
          if (ModalData._id) {
            promise = managerialpotCtrl.updateAttr(ModalData);
          } else {
            promise = managerialpotCtrl.saveReport(ModalData);
          }
          this.managerialcategories.forEach(managerialcategory => {
            if (managerialcategory.name == ModalData.managerial_domain) {
              ModalData.managerial_domain_id = managerialcategory._id;
            }
          });
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }
}

export default angular.module('leafApp.managerialpot', [uiRouter])
  .config(routes)
  .component('managerialpot', {
    template: require('./managerialpot.html').default,
    controller: ManagerialpotComponent,
    controllerAs: 'managerialpotCtrl'
  })
  .name;