'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './activity.routes';

 /**
  * @class angular_module.ActivityComponent
  */
export class ActivityComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteActivity = Modal.confirm.delete(activity => {
      $http.delete(`/api/activities/${activity._id}`).then(() => this.getActivities());
    });

    $scope.$on('$destroy', function() {
      //
    });
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function $onInit
  * @memberOf angular_module.ActivityComponent
  * @description this function intiates the Activity component
	*/
  $onInit() {
    this.$http.get('/api/psychos')
      .then(response => {
        this.attrs = this.arrayToMap(response.data);
      });
    this.$http.get('/api/lists/domain/name')
      .then(response => {
        this.domains = response.data;
        this.getActivities();
      });

    this.$http.get('/api/lists/activity/name')
      .then(response => {
        this.activityList = response.data;
      });

    this.$http.get('/api/lists/Subtypes/name')
        .then(response => {
          this.subtypeList = response.data;
        });
  }

  /**
	* @function getActivities
  * @memberOf angular_module.ActivityComponent
  * @description this function will get all activities
	*/
  getActivities() {
    this.$http.get('/api/activities')
      .then(response => {
        this.activities = response.data;
      });
  }


  /**
	* @function search
  * @memberOf angular_module.ActivityComponent
  * @param {Object} params - params data
  * @description this function will search activities
	*/
  search(params) {
    this.$http.get('/api/activities', { params: params })
      .then(response => {
        this.activities = response.data;
      });
  }

  /**
	* @function saveActivity
  * @memberOf angular_module.ActivityComponent
  * @param {Object} activity - activity data
  * @description this function calls the add activity api
	*/
  saveActivity(activity) {
    if(activity.interview_questions==undefined){
      activity.interview_questions='';
    }
    return this.$http.post('/api/activities', activity).then(() => this.getActivities());
  }

  /**
	* @function updateActivity
  * @memberOf angular_module.ActivityComponent
  * @param {Object} activity - activity data
  * @description this function calls the update activity api
	*/
  updateActivity(activity) {
    return this.$http.put(`/api/activities/${activity._id}`, activity);
  }

  /**
	* @function cloneActivity
  * @memberOf angular_module.ActivityComponent
  * @param {Object} activity - activity data
  * @description this function will clone activity
	*/
  cloneActivity(activity) {
    let cloneActivity = activity;
    let activityCloned = {
      genre: typeof cloneActivity.genre == 'undefined' ? '' : cloneActivity.genre,
      sub_activity_id: typeof cloneActivity.sub_activity_id == 'undefined' ? '' : cloneActivity.sub_activity_id,
      domains: typeof cloneActivity.domains == 'undefined' ? '' : cloneActivity.domains,
      explaination: typeof cloneActivity.explaination == 'undefined' ? '' : cloneActivity.explaination,
      interview_questions: typeof cloneActivity.interview_questions == 'undefined' ? '' : cloneActivity.interview_questions,
      priorities: cloneActivity.priorities,
      report_ai_statements: typeof cloneActivity.report_ai_statements == 'undefined' ? '' : cloneActivity.report_ai_statements,
      active: typeof cloneActivity.active == 'undefined' ? false : cloneActivity.active
    }
    activityCloned.activityList = this.activityList;
    activityCloned.subtypeList = this.subtypeList;
    this.openModal(activityCloned, 'clone');
  }

  /**
	* @function openModal
  * @memberOf angular_module.ActivityComponent
  * @param {Object} activity - activity data
  * @param {String} task - task type
  * @description this function will open modal
	*/
  openModal(activity, task) {
    var activityCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_activity.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.activity = activity;
        this.domains = activityCtrl.domains;
        this.activityList = activityCtrl.activityList;
        this.subtypeList= activityCtrl.subtypeList;
          if (!activity._id && task!=='clone') {
            this.activity.priorities = activityCtrl.mapToArray(activityCtrl.attrs);
            this.activity.activityList = activityCtrl.activityList;
            this.activity.subtypeList = activityCtrl.subtypeList;
          } else {
            let keys = Object.keys(activityCtrl.attrs);
            this.activity.priorities.forEach(priority => {
              let index = keys.indexOf(priority.name);
              if (index > -1) {
                keys.splice(index, 1);
              }
            });
            keys.forEach(k => {
              this.activity.priorities.push(activityCtrl.attrs[k]);
            });
          }

        this.commitActivity = function(l) {
          var promise;
          if (l._id) {
            promise = activityCtrl.updateActivity(l);
          } else {
            promise = activityCtrl.saveActivity(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });

  }

  /**
	* @function addActivity
  * @memberOf angular_module.ActivityComponent
  * @fires openModal
  * @description this function will open modal for add activity
	*/
  addActivity() {
    this.openModal({}, 'add');
  }

    /**
	* @function editActivity
  * @memberOf angular_module.ActivityComponent
  * @fires openModal
  * @param {Object} activity - activity data
  * @description this function will open modal for edit activity
	*/
  editActivity(activity) {
    activity.activityList = this.activityList;
    activity.subtypeList = this.subtypeList;
   if(activity.interview_questions==undefined){
      activity.interview_questions='';
    }
    this.openModal(activity, 'edit');
  }
}

export default angular.module('leafApp.activity', [uiRouter])
  .config(routes)
  .component('activity', {
    template: require('./activity.html').default,
    controller: ActivityComponent,
    controllerAs: 'activityCtrl'
  })
  .name;
