'use strict';
import angular from 'angular';
const _ = require('lodash');
import uiRouter from 'angular-ui-router';

import routes from './score.routes';
/**
  * @class angular_module.scoreComponent
  */
export class scoreComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$uibModal = $uibModal;
          this.pageSize=50;
          this.showAll=false;
	        this.scores=[];
          this.scoreOriginal = [];

          this.hideToggleButtonOption=false;
          this.deletescore = Modal.confirm.delete(score => {
               $http.delete(`/api/scores/${score._id}`).then(() => this.getscores(50));
          });
     }
     respondWithResult(res, statusCode) {
          statusCode = statusCode || 200;
          return function(entity) {
               if(entity) {
                    return res.status(statusCode).json(entity);
               }
               return null;
          };
     }

     /**
	* @function getscores
     * @memberOf angular_module.scoreComponent
     * @param {Number}listitem - listitem count
     * @description this function calls scores api to get scores.
	*/
     getscores(listitem) {
        this.$http.get('/api/scores/'+listitem)
        .then(response => {
            this.refresh=false;
             this.scores = _.concat(this.scores,response.data);
             this.scoreOriginal = this.scores.slice();
//alert(response.data.length);
             if(response.data.length>=50){
              //this.pageSize=this.scores.length;
              this.hideToggleButtonOption=true;
             }else{
              this.hideToggleButtonOption=false;
             }
        });
   }




   /**
	* @function $onInit
     * @memberOf angular_module.scoreComponent
     * @fires getscores
     * @description this function intiates the score component.
	*/
     $onInit() {
          this.getscores(50);
     }

      /**
	* @function processScore
     * @memberOf angular_module.scoreComponent
     * @param {String}scoreid - score id
     * @param {String}score - score
     * @description this function is used to process score.
	*/
     processScore(scoreid,score) {
          this.$http.get('/api/v1/processall')
          .then( response =>{
               this.success="All Scores Successfully Processed";
          });
     }

     searchEmail(emailId){
    //  console.log(email);
    //  this.scores =  this.scoreOriginal.filter(el => el.email.includes(emailId));
    this.hideToggleButtonOption=false;
    if(!emailId){
      this.scores = [];
      this.pageSize= 50;
      // this.refresh=true;
      this.getscores(50);
      return;
    }
    this.$http.get('/api/scores?email='+emailId).then(response => {

      this.pageSize = response.data.length;
      this.scores = response.data;
    })
    }

      /**
	* @function reprocessUserScore
     * @memberOf angular_module.scoreComponent
     * @param {String} email - email address
     * @description this function is used to reprocess user score.
	*/
     reprocessUserScore(email, assessment_id) {
        this.$http.get(`/api/v1/reprocess/${email}/${assessment_id}`)
        .then( response =>{
             this.success="Scores Successfully Processed for "+email;
        });
   }

    /**
	* @function showToggle
     * @memberOf angular_module.scoreComponent
     * @fires getscores
     * @description this function is used to showToggle.
	*/
    showToggle(){
        this.refresh=true;

       this.pageSize+=50;
	//alert(this.pageSize);
	this.getscores(this.pageSize);
    }
}

export default angular.module('leafApp.score', [uiRouter])
.config(routes)
.component('score', {
     template: require('./score.html').default,
     controller: scoreComponent,
     controllerAs: 'scoreCtrl'
})
.name;
