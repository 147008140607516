'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('listitem', {
      url: '/listitem/:id',
      template: '<listitem></listitem>'
    });
}
