'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './suborgcompetency.routes';
/**
  * @class angular_module.SubOrgCompetencyComponent
  */
export class SubOrgCompetencyComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,CompanyService, OrgCompetencyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.OrgCompetencyService = OrgCompetencyService;
    this.$location=$location;

    this.deleteSuborgcompetency= Modal.confirm.delete(suborgcompetency => {
      $http.delete(`/api/suborgcompetencies/${suborgcompetency._id}`).then(() => this.getSuborgcompetencies());
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @fires getSuborgcompetencies
  * @description this function intiates the SubOrgCompetency Component.
	*/
  $onInit() {
    this.$http.get('/api/psychos')
    .then(response => {
      this.attrs = this.arrayToMap(response.data);
    });
    this.getSuborgcompetencies();
  }
  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }
  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  /**
	* @function getSuborgcompetencies
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @description this function calls suborgcompetencies api to get suborgcompetencies data.
	*/
  getSuborgcompetencies() {
    var company_id, orgcompetency_id;
    this.OrgCompetencyService.getOrgcompetencyData(function(res){
      orgcompetency_id = res._id;
      company_id = res.company_id;
    });
    if(company_id==""||orgcompetency_id==""){
      this.$location.path("/company");
    }
    this.$http.get(`/api/suborgcompetencies/findAll/${orgcompetency_id}`)
    .then(response => {
      this.suborgcompetencies = response.data;
    });
  }

  /**
	* @function addSuborgcompetency
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @description this function is used to add sub org competency.
	*/
  addSuborgcompetency() {
    this.openModal({});
  }

  /**
	* @function saveSuborgcompetency
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @param {Object} suborgcompetency - suborgcompetency data
  * @description this function calls suborgcompetencies api to add suborgcompetency.
	*/
  saveSuborgcompetency(suborgcompetency){
    var company_id, orgcompetency_id;
    this.OrgCompetencyService.getOrgcompetencyData(function(res){
      orgcompetency_id = res._id;
      company_id = res.company_id;
    });
    suborgcompetency.company_id=company_id;
    suborgcompetency.orgcompetency_id=orgcompetency_id;
   if(suborgcompetency.interview_questions==undefined){
      suborgcompetency.interview_questions='';
    }
    return this.$http.post('/api/suborgcompetencies', suborgcompetency).then(() => this.getSuborgcompetencies());
  }


  /**
	* @function updateSuborgcompetency
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @param {Object} suborgcompetency - suborgcompetency data
  * @description this function calls suborgcompetencies api to update suborgcompetency.
	*/
  updateSuborgcompetency(suborgcompetency){
    return this.$http.put(`/api/suborgcompetencies/${suborgcompetency._id}`, suborgcompetency);
  }

  /**
	* @function editSuborgcompetency
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @param {Object} suborgcompetency - suborgcompetency data
  * @description this function is used to edit sub org competency.
	*/
  editSuborgcompetency(suborgcompetency) {
   if(suborgcompetency.interview_questions==undefined){
      suborgcompetency.interview_questions='';
    }

    this.openModal(suborgcompetency);
  }

  /**
	* @function openModal
  * @memberOf angular_module.SubOrgCompetencyComponent
  * @param {Object} suborgcompetency - suborgcompetency data
  * @description this function open modal for adding or editing suborgcompetency.
	*/
  openModal(suborgcompetency) {
    var suborgcompetenciesCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_suborgcompetency.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.suborgcompetency = suborgcompetency;

        if (!suborgcompetency._id) {
          this.suborgcompetency.priorities = suborgcompetenciesCtrl.mapToArray(suborgcompetenciesCtrl.attrs);
        } else {
          let keys = Object.keys(suborgcompetenciesCtrl.attrs);
          this.suborgcompetency.priorities.forEach(priority => {
            let index = keys.indexOf(priority.name);
            if (index > -1) {
              keys.splice(index, 1);
            }
          });
          keys.forEach(k => {
            this.suborgcompetency.priorities.push(suborgcompetenciesCtrl.attrs[k]);
          });
        }


        this.commitSubOrgcompetency = function(l) {
          var promise;
          if (l._id) {
            promise = suborgcompetenciesCtrl.updateSuborgcompetency(l);
          } else {
            promise = suborgcompetenciesCtrl.saveSuborgcompetency(l);
          }

          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

}
export default angular.module('leafApp.suborgcompetency', [uiRouter])
.config(routes)
.component('suborgcompetency', {
  template: require('./suborgcompetency.html').default,
  controller: SubOrgCompetencyComponent,
  controllerAs: 'suborgcompetenciesCtrl'
})
.name;
