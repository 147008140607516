'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './bhired_benchmark.routes';
/**
  * @class angular_module.bhired_benchmarkComponent
  */
export class bhired_benchmarkComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$scope = $scope;
          this.$uibModal = $uibModal;

          this.deleteAttributeBenchmark= Modal.confirm.delete(benchmark => {
               $http.delete(`/bhired_api/competency/${benchmark._id}`).then(() => this.getAttributeBenchmarks());
          });
     }

     /**
	* @function $onInit
     * @memberOf angular_module.bhired_benchmarkComponent
     * @fires getAttributeBenchmarks
     * @fires getPsychoAttributes
     * @description this function intiates the bhiredcompetency component.
	*/
     $onInit() {
          this.getAttributeBenchmarks();
          this.getPsychoAttributes();
     }
     arrayToMap(data) {
          let map = {};
          data.forEach(d => {
               map[d.name] = d;
          });
          return map;
     }
     mapToArray(map) {
          return Object.keys(map).map(d => map[d]);
     }
     
     /**
	* @function getAttributeBenchmarks
     * @memberOf angular_module.bhired_benchmarkComponent
     * @description this function calls bhired competency api to get attribute benchmarks.
	*/
     getAttributeBenchmarks(){
          this.$http.get('/bhired_api/competency/')
          .then(response => {
               this.benchmarks=JSON.parse(angular.toJson(response.data));     
          });
     }

     /**
	* @function saveAttributeBenchmark
     * @memberOf angular_module.bhired_benchmarkComponent
     * @param {Object}benchmark - benchmark data
     * @description this function calls bhired competency api to add attribute benchmarks.
	*/
     saveAttributeBenchmark(benchmark) {
          return this.$http.post('/bhired_api/competency/', benchmark).then(() => this.getAttributeBenchmarks());
     }

     /**
	* @function updateAttributeBenchmark
     * @memberOf angular_module.bhired_benchmarkComponent
     * @param {Object}benchmark - benchmark data
     * @description this function calls bhired competency api to update attribute benchmarks.
	*/
     updateAttributeBenchmark(benchmark) {
          return this.$http.put(`/bhired_api/competency/${benchmark._id}`, benchmark).then(() => this.getAttributeBenchmarks());
     }

     /**
	* @function getPsychoAttributes
     * @memberOf angular_module.bhired_benchmarkComponent
     * @description this function is used to generate 100 attributes.
	*/
     getPsychoAttributes(){
          //0 to 100 attributes  
          this.attributes = [];
          for(var i = 0 ; i <= 100; i++){
               let attribute = {};
               attribute['name'] = i;
               attribute['value'] = '';
               this.attributes.push(attribute);
          }
          // this.$http.get('/api/psychos')
          // .then(response => {
          //      this.attributes = response.data;
          // });
     }

     /**
	* @function addAttributeBenchmark
     * @memberOf angular_module.bhired_benchmarkComponent
     * @description this function is used to add attribute benchmark.
	*/
     addAttributeBenchmark() {
       this.openModal({},'new');
     }

     /**
	* @function editAttributeBenchmark
     * @memberOf angular_module.bhired_benchmarkComponent
     * @param {Object}benchmark - benchmark data
     * @description this function is used to edit attribute benchmark.
	*/
     editAttributeBenchmark(benchmark) {
       benchmark=JSON.parse(angular.toJson(benchmark));
       
       this.openModal(benchmark,'edit');
     }

     /**
	* @function cloneAttributeBenchmark
     * @memberOf angular_module.bhired_benchmarkComponent
     * @param {Object}benchmark - benchmark data
     * @description this function is used to clone attribute benchmark.
	*/
     cloneAttributeBenchmark(benchmark) {
          let clonedBenchmark = {};
          benchmark=JSON.parse(angular.toJson(benchmark));
          delete benchmark._id;
          clonedBenchmark = typeof benchmark == undefined ? {} : benchmark;
       this.openModal(clonedBenchmark,'clone');
     }

     /**
	* @function openModal
     * @memberOf angular_module.bhired_benchmarkComponent
     * @param {Object}benchmark - benchmark data
     * @param {String}task - task name
     * @description this function opens modal for add,edit or clone bhired benchmarks.
	*/
     openModal(benchmark, task) {
       var bhired_benchmarkCtrl = this;
       var modalInstance = this.$uibModal.open({
         animation: true,
         templateUrl: 'add_attribute.html',
         size: 'md',
         controller: function ($uibModalInstance) {
           'ngInject';
           this.benchmark = benchmark;
           this.attributeData = bhired_benchmarkCtrl.attributes;

           if(task == 'new') {
                this.benchmark.attributes = [];
                this.attributeData.forEach(data => {
                     let attribute ={}
                     attribute["attribute_id"] = data._id;
                     attribute["attribute_name"] = data.name;
                     this.benchmark.attributes.push(attribute);
                });
           }

           this.commitAttrBenchmarks = function(l) {
             if(l._id)
               var promise= bhired_benchmarkCtrl.updateAttributeBenchmark(l);
             else {
               
               var promise= bhired_benchmarkCtrl.saveAttributeBenchmark(l);
             }
             promise.then(() => {
               $uibModalInstance.dismiss('cancel');
             }, (err) => {
               this.error = err.statusText;
             });
           }
         },
         controllerAs: '$ctrl'
       });
     }

}

export default angular.module('leafApp.bhired_benchmark', [uiRouter])
.config(routes)
.component('bbenchmarktab', {
     template: require('./bhired_benchmark.html').default,
     controller: bhired_benchmarkComponent,
     controllerAs: 'bhired_benchmarkCtrl'
})
.name;
