'use strict';
import angular from 'angular';
import uiRouter from 'angular-ui-router';

import routes from './list.routes';

 /**
  * @class angular_module.ListComponent
  */
export class ListComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteList = Modal.confirm.delete(list => {
      $http.delete(`/api/lists/${list._id}`).then(() => this.$onInit());
    });

    $scope.$on('$destroy', function() {
      // 
    });
  }

  /**
	* @function $onInit
  * @memberOf angular_module.ListComponent
  * @description this function intiates the list component
	*/
  $onInit() {
    this.$http.get('/api/lists')
      .then(response => {
        this.lists = response.data;
      });
  }

  /**
	* @function saveList
  * @memberOf angular_module.ListComponent
  * @param {Object} list - List data
  * @description this function calls the add list api
	*/
  saveList(list) {
    return this.$http.post('/api/lists', list).then(() => this.$onInit());
  }

  /**
	* @function updateList
  * @memberOf angular_module.ListComponent
  * @param {Object} list - List data
  * @description this function calls the update list api
	*/
  updateList(list) {
    return this.$http.put(`/api/lists/${list._id}`, list);
  }

  /**
	* @function openModal
  * @memberOf angular_module.ListComponent
  * @param {Object} list - List data
  * @description this function will open modal
	*/
  openModal(list) {
    var listCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_list.html',
      size: 'md',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.lists = angular.copy(listCtrl.lists);
        this.list = list;

        this.commitList = function(l) {
          var promise;
          if (l._id) {
            promise = listCtrl.updateList(l);
          } else {
            promise = listCtrl.saveList(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        }
      },
      controllerAs: '$ctrl'
    });
  }

  /**
	* @function addList
  * @memberOf angular_module.ListComponent
  * @fires openModal
  * @description this function will open modal for add list
	*/
  addList() {
    this.openModal({});
  }

  /**
	* @function editList
  * @memberOf angular_module.ListComponent
  * @fires openModal
  * @description this function will open modal for edit list
	*/
  editList(list) {
    this.openModal(list);
  }
}

export default angular.module('leafApp.list', [uiRouter])
  .config(routes)
  .component('list', {
    template: require('./list.html').default,
    controller: ListComponent,
    controllerAs: 'listCtrl'
  })
  .name;
