'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './successfactortagging.routes';

export class SuccessFactorTaggingComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal,SuccessFactorService,$location) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.SuccessFactorService=SuccessFactorService;
    this.$location=$location;
  }

  arrayToMap(data) {
    let map = {};
    data.forEach(d => {
      map[d.name] = d;
    });
    return map;
  }

  mapToArray(map) {
    return Object.keys(map).map(d => map[d]);
  }

  getFactor(success_factor_id) {
    var thisVar=this;
    this.$http.get(`/api/success_factors/${success_factor_id}`)
      .then(response => {
        this.successfactor = response.data[0]?response.data[0]:response.data;
        if(!this.successfactor.priorities || this.successfactor.priorities.length==0){
           this.setPrioritiesValue(); 
        }
      });
  }
  setPrioritiesValue(){
    var prioritiesData=this.attrs;
    this.successfactor.priorities=new Array();
    var prioritiesDataArray=[];
    Object.keys(prioritiesData).forEach(function(index,value){
            //
            var data={};
            data['name']=prioritiesData[index].name;
            data['genre']=prioritiesData[index].genre;
            data['value']="";
            data['attribute_id']='ObjectId("'+prioritiesData[index]._id+'")';
            prioritiesDataArray.push(data);
    });
    this.successfactor.priorities=prioritiesDataArray;
  }

  $onInit() {
    this.allowoperation=false;
    var refreshPage=false;
    this.$http.get('/api/psychos')
      .then(response => {
        this.attrs = this.arrayToMap(response.data);
        var success_factor_id;
        this.SuccessFactorService.getSuccessFactorData(function(res){
          success_factor_id=res._id;
          refreshPage=res.reload;
        });
        if(refreshPage){
          this.$location.path('/success_factors');
        }
        if(success_factor_id==undefined||success_factor_id==""){
          this.allowoperation=true;
            this.successfactor={};
            this.setPrioritiesValue();
        }
        else {
          this.getFactor(success_factor_id);
        }
    });
  }
  commitFactor(l){
    if (l._id!=undefined) {
     this.updateFactor(l);
    } else {
      this.saveFactor(l);
    }
  }
  saveFactor(successfactor) {
    return this.$http.post('/api/success_factors', successfactor).then(() => {
      this.success=' successfully updated';
    });
  }

  updateFactor(successfactor) {
    return this.$http.put(`/api/success_factors/${successfactor._id}`, successfactor).then((result)=>{
      this.success=' successfully updated';
    });
  }
}


export default angular.module('leafMainApp.success_factor_tagging', [uiRouter])
  .config(routes)
  .component('successFactorTagging', {
    template: require('./successfactortagging.html').default,
    controller: SuccessFactorTaggingComponent,
    controllerAs: 'successFactorTaggingCtrl'
  })
  .name;
