'use strict';
import angular from 'angular';
const _ = require('lodash');
import uiRouter from 'angular-ui-router';

import routes from './keva.routes';

export class kevaComponent {
     /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal) {
          this.$http = $http;
          this.$uibModal = $uibModal;
     }
     
     $onInit() {
          
          // this.getcandidates(50);
     }
}

export default angular.module('leafApp.keva', [uiRouter])
     .config(routes)
     .component('kevatab', {
          template: require('./keva.html').default,
          controller: kevaComponent,
          controllerAs: 'kevaCtrl'
     })
     .name;