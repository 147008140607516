'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './verdict.routes';
/** @type {import('tailwindcss').Config} */

class VerdictComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;
    this.input1;
    this.input2;
    this.input3;
    this.input4;
    this.success;
  }

  $onInit() {
    this.getCompany();
  }

  getCompany() {
    var company_id;
    const cb = function(res) {
      company_id = res._id;
      this.company_name = res.company_name;
    };

    this.CompanyService.getCompanyData(cb.bind(this));
    if (company_id == '') {
      this.$location.path('/company');
    }
    this.$http.get(`/api/company/${company_id}`).then((response) => {
      if (response && response.data && response.data.customVerditBoundaries) {
        this.input1 = response.data.customVerditBoundaries[0].maxValue;
        this.input2 = response.data.customVerditBoundaries[1].maxValue;
        this.input3 = response.data.customVerditBoundaries[2].maxValue;
        this.input4 = response.data.customVerditBoundaries[3].maxValue;
      }
      this.company = response.data;
    });
  }
  submit() {
    this.company.customVerditBoundaries[0].maxValue = Number(this.input1);
    this.company.customVerditBoundaries[1].maxValue = Number(this.input2);
    this.company.customVerditBoundaries[2].maxValue = Number(this.input3);
    this.company.customVerditBoundaries[3].maxValue = Number(this.input4);
    this.updateCompany();
  }

  updateCompany() {
    this.success = '';
    const res = this.$http.put(`/api/company/${this.company._id}`, this.company);
    res.then((data) => {
      if (data.data) {
        this.success = 'Verdict boundary values are successfully updated';
      }
    });
  }
}

export default angular
  .module('leafApp.verdict', [uiRouter])
  .config(routes)
  .component('verdict', {
    template: require('./verdict.html').default,
    controller: VerdictComponent,
    controllerAs: 'verdictCtrl',
  }).name;
