'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('tvsreportspec', {
      url: '/tvsreportspec',
      template: '<tvsreportspectab></tvsreportspectab>'
    });
}
