'use strict';
import angular from 'angular';

import uiRouter from 'angular-ui-router';

import routes from './learning_development.routes';
/**
  * @class angular_module.learning_developmentComponent
  */
export class learning_developmentComponent {
  /*@ngInject*/
     constructor($http, $scope, $uibModal, Modal, $location) {
          this.message = 'Hello';
          this.$http = $http;
          this.$scope = $scope;
          this.$uibModal = $uibModal;
          this.$location=$location;
          this.deleteLdData= Modal.confirm.delete(report => {
            $http.delete(`/api/learning_development/${report._id}`).then(() => this.getLdData());
          });
     }


     /**
	* @function $onInit
  * @memberOf angular_module.learning_developmentComponent
  * @fires getLdData
  * @description this function intiates the learning_development component
	*/
     $onInit() {
       this.getLdData();     
     }

     /**
    * @function getLdData
    * @memberOf angular_module.learning_developmentComponent
    * @description this function calls learning_development api to get L&D attributes.
    */
     getLdData() {
      this.$http.get('/api/learning_development').then(response => {
        this.LdDatas = response.data;   
   });
     }

     /**
    * @function addNew
    * @memberOf angular_module.learning_developmentComponent
    * @fires openModal
    * @description this function open modal for adding L&D attribute.
    */
     addNew() {
       this.openModal({})
     }

     /**
	* @function saveReport
  * @memberOf angular_module.learning_developmentComponent
  * @param {Object}company - attribute data
  * @description this function calls learning_development api to add L&D attribute
	*/
     saveReport(company){
       return this.$http.post('/api/learning_development', company).then((response) => this.getLdData());
 }

 /**
	* @function updateAttr
  * @memberOf angular_module.learning_developmentComponent
  * @param {Object}attr - attr data
  * @description this function calls learning_development api to update L&D attribute
	*/
 updateAttr(attr) {
       return this.$http.put(`/api/learning_development/${attr._id}`, attr);
 }
/**
	* @function editLdData
  * @memberOf angular_module.learning_developmentComponent
  * @fires openModal
  * @param {Object} LdData - LdData data
  * @description this function open modal for editing L&D attributes
	*/
 editLdData(LdData) {
  this.openModal(LdData);
}
/**
	* @function openModal
  * @memberOf angular_module.learning_developmentComponent
  * @param {Object} ld - ld data
  * @description this function will open modal for adding or editing L&D attribute.
	*/
     openModal(ld) {
      var learning_developmentCtrl = this;
      var modalInstance = this.$uibModal.open({
        animation: true,
        templateUrl: 'add_New.html',
        size: 'md',
        controller: function ($uibModalInstance) {
          'ngInject';
          this.Ld = ld;
          this.commitldData = function(ModalData) {
            var promise;
            if (ModalData._id) {
              promise = learning_developmentCtrl.updateAttr(ModalData);
            } else {
              
              promise = learning_developmentCtrl.saveReport(ModalData);
            }
            promise.then(() => {
              $uibModalInstance.dismiss('cancel');
            }, (err) => {
              this.error = err.statusText;
            });       
          }
        },
        controllerAs: '$ctrl'
      });
    }

}

export default angular.module('leafApp.learning_development', [uiRouter])
  .config(routes)
  .component('ld', {
    template: require('./learning_development.html').default,
    controller: learning_developmentComponent,
    controllerAs: 'learning_developmentCtrl'
  })
  .name;
