'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('companydepartments', {
    url: '/companydepartments',
    template: '<companydepartments></companydepartments>'
  });
}
