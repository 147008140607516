'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('psycho', {
      url: '/psycho',
      template: '<psycho></psycho>'
    });
}
