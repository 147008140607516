'use strict';

import uiRouter from 'angular-ui-router';

import angular from 'angular';
import routes from './jrt.routes';

export class JrtComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal) {
    this.$http = $http;
    this.$uibModal = $uibModal;

    this.deleteJrt = Modal.confirm.delete(jrt => {
      $http.delete(`/api/jrts/${jrt._id}`).then(() => this.search(this.params));
    });

    $scope.$on('$destroy', function() {
      //
    });
  }

  $onInit() {
    // get domains and sectors
    this.$http.get('/api/lists/sector/name')
      .then(response => {
        this.sectors = response.data;
      });
    this.$http.get('/api/lists/domain/name')
      .then(response => {
        this.domains = response.data;
      });
    this.$http.get('/api/activities')
      .then(response => {
        this.activities = response.data;
      });
  }

  search(params) {
    this.$http.get('/api/jrts', { params: params })
      .then(response => {
        this.jrts = response.data;
      });
  }

  saveJrt(jrt) {
    return this.$http.post('/api/jrts', jrt).then(() => this.search(this.params));
  }

  updateJrt(jrt) {
    return this.$http.put(`/api/jrts/${jrt._id}`, jrt);
  }

  openModal(jrt) {
    var jrtCtrl = this;
    var modalInstance = this.$uibModal.open({
      animation: true,
      templateUrl: 'add_jrt.html',
      size: 'lg',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.jrts = angular.copy(jrtCtrl.jrts);
        this.jrt = jrt;

        this.sectors = jrtCtrl.sectors;
        this.domains = jrtCtrl.domains;
        this.activities = jrtCtrl.activities;

        this.commitJrt = function(l) {
          var promise;
          if (l._id) {
            promise = jrtCtrl.updateJrt(l);
          } else {
            promise = jrtCtrl.saveJrt(l);
          }
          promise.then(() => {
            $uibModalInstance.dismiss('cancel');
          }, (err) => {
            this.error = err.statusText;
          });
        };

        this.addItem = function(key, item) {
          if (!this[item]) {
            return;
          }
          this.jrt[key] = this.jrt[key] || [];
          var index = this[item].eindex;
          if (index || index === 0) {
            this.jrt[key][index] = angular.copy(this[item]);
          } else {
            this.jrt[key].push(this[item]);
          }
          this[item] = undefined;
        };

        this.deleteItem = function(key, index) {
          this.jrt[key].splice(index, 1);
        };

        this.viewItem = function(key, index) {
          this.jrs = this.jrt[key][index];
          this.jrs.eindex = index;
        };

        // this.addSubItem = function(identifier, key, item) {
        //   if (!this[item]) {
        //     return;
        //   }
        //   this[identifier] = this[identifier] || {};
        //   this[identifier][key] = this[identifier][key] || [];
        //   this[identifier][key].push(this[item]);
        //   this[item] = undefined;
        // };

        // this.deleteSubItem = function(identifier, key, index) {
        //   this[identifier][key].splice(index, 1);
        // };
      },
      controllerAs: '$ctrl'
    });
  }

  addJrt() {
    this.openModal({});
  }

  editJrt(jrt) {
    this.openModal(jrt);
  }

  cloneJrt(jrt) {
    let clonedJrt = angular.copy(jrt);
    delete clonedJrt.__v;
    delete clonedJrt._id;
    delete clonedJrt.created_at;
    delete clonedJrt.updated_at;
    clonedJrt.specifications.map(spc => {
      delete spc._id;
    });
    this.openModal(clonedJrt);
  }
}

export default angular.module('leafApp.jrt', [uiRouter])
  .config(routes)
  .component('jrt', {
    template: require('./jrt.html').default,
    controller: JrtComponent,
    controllerAs: 'jrtCtrl'
  })
  .name;
