'use strict';

export default function($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gquestions', {
      url: '/gamedata/questions',
      template: '<gquestions></gquestions>'
    });
}
